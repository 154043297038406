import { Button, Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import userApi from '../../api/users';
import TitlePrimary from '../../components/TitlePrimary';
import { usePrimaryColor, useSecondColor, useThirdColor } from '../../redux/settings/selectors';

function Subscribe({ next, previous }) {
  const { t } = useTranslation();

  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const greyColor = useThirdColor();

  const [loading, setLoading] = useState(false);

  const setSubscription = () => {
    setLoading(true);
    userApi
      .setPayment(0)
      .then(() => next())
      .catch(() => setLoading(false));
  };

  const BlockPrice = ({ type }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <strong style={{ marginBottom: 10 }}>{t(`SUBSCRIBE_TYPE_1_${type}`)}</strong>
      <div style={{ display: 'flex', color: primaryColor }}>
        <strong style={{ fontSize: 35, lineHeight: '40px' }}>{t(`SUBSCRIBE_TYPE_1_${type}_PRICE`)}</strong>
        <div>{t('BY_MONTH')}</div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 200px - 64px - 48px)',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: 30 }}>
        <TitlePrimary title={t('SUBSCRIBE_TITLE')} />
      </div>
      <div
        style={{ marginBottom: 30, textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: t('SUBSCRIBE_DESCRIPTION') }}
      />

      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', flexShrink: 1 }}>
        <div style={{ backgroundColor: 'white', borderRadius: 5, width: '60%', marginRight: 20 }}>
          <div
            style={{
              backgroundColor: primaryColor,
              textAlign: 'center',
              padding: 10,
              color: 'white',
              borderRadius: '5px 5px 0px 0px',
              fontSize: '1.2em',
            }}
          >
            {t('SUBSCRIBE_TYPE_1')}
          </div>
          <div style={{ padding: 16 }}>
            <div style={{ display: 'flex', height: 100 }}>
              <BlockPrice type={1} />
              <Divider type="vertical" style={{ height: 100, borderLeftWidth: 2 }} />
              <BlockPrice type={2} />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center', color: greyColor, marginTop: 16 }}
              dangerouslySetInnerHTML={{ __html: t('SUBSCRIBE_TYPE_1_HTML') }}
            />
          </div>
        </div>

        <div style={{ backgroundColor: 'white', borderRadius: 5, width: 'calc(40% - 20px)' }}>
          <div
            style={{
              backgroundColor: secondColor,
              textAlign: 'center',
              padding: 10,
              color: 'white',
              borderRadius: '5px 5px 0px 0px',
              fontSize: '1.2em',
            }}
          >
            {t('SUBSCRIBE_TYPE_2')}
          </div>
          <div style={{ padding: 16 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 100,
              }}
            >
              <strong style={{ marginBottom: 10 }}>{t(`SUBSCRIBE_TYPE_2_1`)}</strong>
              <strong style={{ fontSize: 35, lineHeight: '40px', color: primaryColor }}>
                {t(`SUBSCRIBE_TYPE_2_1_PRICE`)}
              </strong>
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center', color: greyColor, marginTop: 16 }}
              dangerouslySetInnerHTML={{ __html: t('SUBSCRIBE_TYPE_2_HTML') }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
        <Button onClick={previous}>{t('COMMON_PREVIOUS')}</Button>
        <Button type="primary" onClick={setSubscription} loading={loading}>
          {t('COMMON_NEXT')}
        </Button>
      </div>
    </div>
  );
}

export default Subscribe;
