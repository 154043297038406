import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import OperationsComment from '../../components/v2/Comment';
import OperationDate from '../../components/Date';
import Name from '../components/Name';
import ShareTypes from '../components/ShareTypes';
import OperationsShareholders6 from '../components/Shareholders';
import FormContext from '../../components/Form/context';
import MaxShares from '../components/MaxShares';
import { useOperationChild } from '../../../../../redux/operation/selectors';

// Promesse d'achat de titres (put option)
function OperationsCreate6x1x7() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();
  const beneficiaries = useShareholdersLocal('>=');
  const holders = useShareholdersLocal('>');

  return (
    <>
      <Name />
      <ShareTypes />

      <OperationsShareholders6 userType="holder" shareholders={holders} />
      <OperationsShareholders6 userType="beneficiary" shareholders={beneficiaries} canCreate />

      <OperationDate />
      <Tooltip title={t('OPERATIONS_DATE_END_PUT_OPTION_TOOLTIP')}>
        <Form.Item label={t('OPERATIONS_DATE_END_PUT_OPTION')} name="date" initialValue={moment()}>
          <DatePicker style={{ width: '100%', minWidth: 150 }} format="DD/MM/YYYY" />
        </Form.Item>
      </Tooltip>

      <MaxShares required />

      <Tooltip title={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE_TOOLTIP`)}>
        <Form.Item
          label={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`)}
          name={['options', `OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`]}
          preserve={false}
        >
          <InputNumber />
        </Form.Item>
      </Tooltip>

      <Form.Item name={['shares']} initialValue={1} hidden>
        <InputNumber />
      </Form.Item>

      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x1x7;
