import request from '../services/request';

const getUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'localhost':
      return 'http://127.0.0.1:3001/api';
    default:
      if (window?.location?.hostname?.includes('dev')) return '';
      return 'https://msg.digistre.com/api';
  }
};

const url = getUrl();

const post = (data, conv) => request(`/message${conv ? `/${conv}` : ''}`, 'POST', data, false, url);
const get = (conv) => request(`/message${conv ? `/${conv}` : ''}`, 'GET', null, false, url);
const setRead = (id) => request(`/message/${id}/read`, 'PUT', null, false, url);
const getUnread = () => request(`/message/unread`, 'GET', null, false, url);

export default {
  post,
  get,
  setRead,
  getUnread,
};
