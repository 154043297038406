import { Form, InputNumber } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import OperationsComment from '../../components/v2/Comment';
import FormContext from '../../components/Form/context';
import Shares from '../components/Shares';
import OperationsAccounts6 from '../components/Accounts';
import OperationsAccounts from '../../components/v2/Accounts';
import OperationDate from '../../components/Date';

const holdersShareType = 6;

// Exercice AGA
function OperationsCreate6x3x4({ computeRatio }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();
  const holders = useShareholdersLocal('>', null, null, holdersShareType);
  const debtorHolders = useShareholdersLocal('>', null, null, '1|2|3');

  const [optionType, setOptionType] = useState();
  const [shares, setShares] = useState(0);
  const [options, setOptions] = useState();

  return (
    <>
      <OperationsAccounts6
        userType="holder"
        shareholders={holders}
        setOptionType={setOptionType}
        setOptions={setOptions}
      />
      <Shares onChange={setShares} />

      {optionType === 'OPTION_TYPE_1' && (
        <>
          <Title level={5}>{t('OPTION_TYPE_1')}</Title>
          <Tooltip title={t(`OPERATIONS_${id}_${shareType}_OPTION_TYPE_1_TOOLTIP`)}>
            <Form.Item label={t(`OPERATIONS_${id}_${shareType}_OPTION_TYPE_1`)} preserve={false}>
              <InputNumber value={Math.floor(shares * computeRatio(options))} disabled />
            </Form.Item>
          </Tooltip>
        </>
      )}

      {optionType === 'OPTION_TYPE_2' && (
        <>
          <Title level={5}>{t('OPTION_TYPE_2')}</Title>
          <OperationsAccounts userType="debtorHolder" shareholders={debtorHolders} />
          <Tooltip title={t(`OPERATIONS_${id}_${shareType}_OPTION_TYPE_2_TOOLTIP`)}>
            <Form.Item label={t(`OPERATIONS_${id}_${shareType}_OPTION_TYPE_2`)} preserve={false}>
              <InputNumber value={Math.floor(shares * computeRatio(options))} disabled />
            </Form.Item>
          </Tooltip>
        </>
      )}

      <OperationDate />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x3x4;
