import React, { useState } from 'react';
import OperationsComment from '../components/Comment';
import OperationsDate from '../components/Date';
import ShareholdersCompute from '../components/Shareholders/Compute';
import ShareTypes from '../components/ShareTypes';

// Réduction de capital
function OperationsCreate1x3() {
  const [shareType, setShareType] = useState(null);

  return (
    <>
      <OperationsDate />
      <ShareTypes onChange={setShareType} />
      {shareType && <ShareholdersCompute shareType={shareType} mode="SUBSTRACTION" />}
      <OperationsComment />
    </>
  );
}

export default OperationsCreate1x3;
