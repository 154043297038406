import { Form, InputNumber } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import OperationsComment from '../../components/v2/Comment';
import FormContext from '../../components/Form/context';
import OperationsShareholders from '../../components/v2/Shareholders';
import CompanyAccounts from '../../components/v2/CompanyAccounts';
import OperationDate from '../../components/Date';
import Shares from '../components/Shares';
import computeRatio from '../computeRatio';

// Attribution
function OperationsCreate6x2x1() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();
  const beneficiaries = useShareholdersLocal('>=');
  const holders = useShareholdersLocal('>', null, null, '4|5|6|7|8|9|10', 'company');

  const [options, setOptions] = useState({});
  const [shares, setShares] = useState(0);

  return (
    <>
      <CompanyAccounts userType="holder" companyAccounts={holders[0]} setOptions={setOptions} />
      <Shares onChange={setShares} />
      <Tooltip title={t(`OPERATIONS_${id}_${shareType}_MAX_SHARES_TOOLTIP`)}>
        <Form.Item label={t(`OPERATIONS_${id}_${shareType}_MAX_SHARES`)} preserve={false}>
          <InputNumber value={Math.floor(shares * computeRatio(options))} disabled />
        </Form.Item>
      </Tooltip>
      <OperationDate />

      <OperationsShareholders userType="beneficiary" shareholders={beneficiaries} canCreate />

      <Tooltip title={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE_TOOLTIP`)}>
        <Form.Item
          label={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`)}
          name={['options', `OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`]}
          preserve={false}
        >
          <InputNumber />
        </Form.Item>
      </Tooltip>

      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x2x1;
