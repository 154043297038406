/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';

export const usePrimaryColor = () => useSelector((state) => state.settings.styles.primaryColor);
export const useSecondColor = () => useSelector((state) => state.settings.styles.secondColor);
export const useThirdColor = () => useSelector((state) => state.settings.styles.thirdColor);
export const useFourthColor = () => useSelector((state) => state.settings.styles.fourthColor);
export const useGreenColor = () => '#52c41a';
export const useRedColor = () => 'red';
export const useLogo = () => useSelector((state) => state.settings.logo);
