import React, { useState } from 'react';
import { Button, Input, Divider, Table, notification, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { sql } from '../../api/admin';

const call = async ({ select, log, isLimit }) => {
  try {
    return await sql(`${select} ${isLimit ? 'limit 100' : ''}`, log.login + log.password);
  } catch (e) {
    notification.error({ description: e || 'Erreur' });
    return null;
  }
};

const DynamicTable = ({ data, onClick, clickKey }) => {
  if (!data?.length) return <div />;
  const columns = Object.keys(data[0]).map((key) => ({
    title: key,
    dataIndex: key,
    key,
    render:
      onClick &&
      clickKey === key &&
      ((text) => (
        <button type="button" onClick={() => onClick(text)}>
          {text}
        </button>
      )),
  }));
  return <Table dataSource={data} columns={columns} />;
};

export default () => {
  const history = useHistory();
  const [logged, setLogged] = useState(false);
  const [log, setLog] = useState({ login: '', password: '' });
  const [select, setSelect] = useState('');
  const [data, setData] = useState([]);
  const [isLimit, setIsLimit] = useState(true);

  return (
    <div>
      {logged ? (
        <div>
          <Button onClick={() => history.push('/admin/translations')}>Translations</Button>
          <Divider />
          LIMITER A 100 : <Switch defaultChecked value={isLimit} onChange={() => setIsLimit(!isLimit)} />
          <DynamicTable
            onClick={setSelect}
            clickKey="Query"
            data={[
              {
                Description:
                  'Le nom prenom des utilisateurs inscrits (cad ayant un compte actif), leur mail, leur date d’inscription, le nombre de sociétés ouvertes',
                Query:
                  'select u.firstname, u.lastname, u.email, u.createdat, count(p) as companies from basic.user u full join basic.permission p on p.userid=u.id where u.accountactive=true group by u.firstname, u.lastname, u,email, u.createdat',
              },
              {
                Description:
                  'Le nom des sociétés ouvertes, leur date d’ouverture, le nom prénom de l’admin qui tient le registre, le nombre de lignes ancrées totales',
                Query:
                  'select c.name, c.createdat, u.firstname, u.lastname, count(r) as operations from basic.company c inner join basic.user u on u.id in (select userid from basic.permission where companyid=c.id and type=1) full join basic.register r on r.companyid=c.id where r.isdraft=false group by c.name, c.createdat, u.firstname, u.lastname',
              },
              {
                Description:
                  'Le nom des sociétés ouvertes, le compteur du nombre d’op ancrées/ op dispo, le type de formule actuelle, la date d’ouverture de la société, leur date d’achat de la formule en cours ou date de début de la formule en cours.',
                Query:
                  "SELECT c.name, COUNT(CASE WHEN r.isdraft = TRUE THEN 1 ELSE NULL END) AS draft, COUNT(r) AS total, payment.type AS carnetType, payment.paymentDate AS carnetPaymentDate, c.payedat AS custodyPaymentDate, c.createdat FROM basic.company c FULL JOIN basic.register r ON r.companyid = c.id LEFT JOIN LATERAL (SELECT (payment->>'type') AS type, (payment->>'paymentDate') AS paymentDate FROM jsonb_array_elements(c.anchorlimit) AS payment ORDER BY (payment->>'paymentDate')::timestamp DESC LIMIT 1) AS payment ON true GROUP BY c.name, c.createdat, payment.type, payment.paymentDate, c.payedat",
              },
              {
                Description:
                  'Le nom des sociétés ouvertes, la taille de stockage prise par des docs uploadé sur tous les registres.',
                Query: '',
              },
            ]}
          />
          <Divider />
          <Input placeholder="SELECT ..." onChange={(e) => setSelect(e.target.value)} value={select} />
          <Button onClick={() => call({ select, log, isLimit }).then((x) => setData(x))}>
            Envoyer la commande au serveur
          </Button>
          <DynamicTable data={data} />
        </div>
      ) : (
        <div>
          <Input placeholder="login" onChange={(e) => setLog({ ...log, login: e.target.value })} />
          <Input placeholder="password" onChange={(e) => setLog({ ...log, password: e.target.value })} />
          <Button
            onClick={async () => {
              const r = await call({ select: 'select 1', log });
              if (r) setLogged(true);
            }}
          >
            Se connecter
          </Button>
        </div>
      )}
    </div>
  );
};
