import React from 'react';
import { Typography, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
import { requestFile } from '../../services/request';
import { useCompany } from '../../redux/company/selectors';
import dateToString from './utils/dateToString';

const Conv = ({ data }) => {
  const { t } = useTranslation();
  const company = useCompany();

  return (
    <div>
      <div className="space-between">
        <Typography.Title>{data.title}</Typography.Title>
        <Typography.Text italic type="secondary">
          {dateToString(new Date(data.date))}
        </Typography.Text>
      </div>
      <Typography.Paragraph>{data.content}</Typography.Paragraph>
      {data.fileid && (
        <Button onClick={() => requestFile(`/company/${company.id}/attachment/${data.fileid}`, data.filename)}>
          {t(`MESSAGE_ATTACHED_FILE`)}
        </Button>
      )}
      <Divider />
    </div>
  );
};

export default Conv;
