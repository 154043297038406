import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Divider, notification } from 'antd';
import { DownloadOutlined, BackwardOutlined } from '@ant-design/icons';
import { useCompany } from '../../redux/company/selectors';
import { requestFile } from '../../services/request';
import { postDecision, deleteDecision } from '../../api/decision';
import companyApi from '../../api/company';
import Subscribe from '../../components/Payment/Subscribe';

const DecisionAnchorModal = ({ registerId, anchorValues, hide }) => {
  const { t } = useTranslation();

  const company = useCompany();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscribeCompany, setSubscribeCompany] = useState(false);

  // eslint-disable-next-line consistent-return
  const draft = async (action) => {
    setButtonLoading(true);
    try {
      if (action === 'anchor') {
        const plan = await companyApi.planStats(company.id);
        if (!plan.isAnchorAllowed) {
          setButtonLoading(false);
          return setSubscribeCompany(company);
        }
        await postDecision(company.id, {
          ...anchorValues,
          comment: anchorValues.comment || anchorValues.description,
          date: ((d) => {
            // temporary work-around (reverse timezone back to UTC)
            const dd = new Date(d.slice(0, 10));
            dd.setMinutes(dd.getMinutes() + dd.getTimezoneOffset() - new Date().getTimezoneOffset());
            return dd.toISOString();
          })(anchorValues.date),
          isdraft: null,
          isDraft: false,
          registerId,
          fileId: anchorValues.file.uuid,
          fileName: anchorValues.file.name,
        });
      }
      await deleteDecision(company.id, anchorValues.id);
    } catch (e) {
      notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
    }
    setButtonLoading(false);
    hide();
  };

  return (
    <Modal
      title={t('DECISION_ANCHOR_MODAL')}
      visible={anchorValues}
      onCancel={hide}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => hide()} icon={<BackwardOutlined />}>
            {t('COMMON_BACK')}
          </Button>
          <Button onClick={() => draft('delete')} loading={buttonLoading} type="primary">
            {t('DRAFT_DELETE')}
          </Button>
          <Button onClick={() => draft('anchor')} loading={buttonLoading} type="primary">
            {t('DRAFT_ANCHOR')}
          </Button>
        </div>
      }
      width="35vw"
    >
      {t('DECISION_ANCHOR_MODAL_TEXT')}
      <Divider />
      <Button
        onClick={() => requestFile(`/company/${company.id}/act/${anchorValues.file.uuid}`, anchorValues.file.name)}
        icon={<DownloadOutlined />}
        type="text"
      >
        {t('DOWNLOAD')}
      </Button>
      <Subscribe refresh={() => {}} company={subscribeCompany} resetCompany={setSubscribeCompany} singleTimeFormula />
    </Modal>
  );
};
export default DecisionAnchorModal;
