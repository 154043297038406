import { Card, Table, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import UserProfile from './UserProfile';

const { TabPane } = Tabs;

// const styles = {
//   box: {
//     container: (thirdColor, last) => ({
//       borderRadius: 5,
//       border: `1px solid ${thirdColor}`,
//       marginBottom: last ? 0 : 16,
//     }),
//     title: (secondColor) => ({
//       backgroundColor: secondColor,
//       color: 'white',
//       textAlign: 'center',
//       fontSize: 18,
//       padding: '10px 0',
//       borderRadius: '5px 5px 0 0',
//       margin: '0 -1px',
//     }),
//     list: { padding: '20px 10px 0 10px', display: 'flex', justifyContent: 'center' },
//     li: { marginBottom: 10 },
//   },
// };

// function SubscriptionStatus() {
//   const { t } = useTranslation();

//   return (
//     <div style={{ background: '#58CE96', color: 'white', padding: '10px 20px', borderRadius: 5, display: 'flex' }}>
//       <CheckCircleFilled style={{ fontSize: 20, marginRight: 10 }} />
//       <Text style={{ marginRight: 5, color: 'white' }}>{`${t('CURRENT_FORMULA')} :`}</Text>
//       <Text style={{ color: 'white' }} strong>
//         {t('CURRENT_FORMULA_1')}
//       </Text>
//     </div>
//   );
// }

const keys = {
  1: '/profile',
  2: '/profile/invoices',
  3: '/profile/subscriptions',
};

const handler = {
  toKey: (p) => Object.fromEntries(Object.entries(keys).map((a) => a.reverse()))[p],
  toPathname: (k) => keys[k],
};

function Profile() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  // const thirdColor = useThirdColor();
  // const primaryColor = usePrimaryColor();

  const [activeKey, setActiveKey] = useState(handler.toKey(pathname));

  useEffect(() => {
    setActiveKey(handler.toKey(pathname));
  }, [pathname]);

  return (
    <Card title={t('YOUR_PROFILE')}>
      <Tabs activeKey={activeKey} onChange={(a) => history.push(handler.toPathname(a))}>
        <TabPane tab={t('PERSONAL_INFORMATIONS')} key="1">
          <UserProfile />
        </TabPane>
        <TabPane tab={t('INVOICES')} key="2">
          <Table
            columns={[
              { title: t('COMPANY_NAME') },
              { title: t('INVOICE_REFERENCE') },
              { title: t('INVOICE_DATE') },
              { title: t('ACTIONS') },
            ]}
          />
        </TabPane>
        {/* <TabPane tab={t('SUBSCRIPTIONS')} key="3">
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <SubscriptionStatus />
              <Button>{t('UNSUBSCRIBE')}</Button>
            </Col>
            <Col span={16}>
              <div style={styles.box.container(thirdColor)}>
                <div style={styles.box.title(primaryColor)}>{t('RIGHTS_TYPE_1')}</div>
                <div style={styles.box.list}>
                  <ul>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_1')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_2')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_3')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_4')}</li>
                    <li>{t('RIGHTS_TYPE_1_5')}</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div style={styles.box.container(thirdColor)}>
                <div style={styles.box.title(primaryColor)}>{t('RIGHTS_TYPE_1')}</div>
                <div style={styles.box.list}>
                  <ul>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_1')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_2')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_3')}</li>
                    <li style={styles.box.li}>{t('RIGHTS_TYPE_1_4')}</li>
                    <li>{t('RIGHTS_TYPE_1_5')}</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </TabPane> */}
      </Tabs>
    </Card>
  );
}

export default Profile;
