import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import companyApi from '../../api/company';
import OperationType from '../../components/OperationType';
import { useCompany } from '../../redux/company/selectors';
import { requestFile } from '../../services/request';

const { Panel } = Collapse;

function LegalActs() {
  const { t } = useTranslation();
  const company = useCompany();

  const [legalActs, setLegalActs] = useState([]);
  const [legalBlank, setLegalBlank] = useState([]);

  useEffect(() => {
    Promise.all([
      companyApi
        .getLegalActs(company.id)
        .then((x) => setLegalActs(x.sort((a, b) => new Date(b.date) - new Date(a.date))))
        .catch(() => setLegalActs([])),
      companyApi
        .getLegalBlank(company.id)
        .then((data) => {
          if (!data.rest) return [];
          return data.rest.reduce((prev, curr) => ({ ...prev, [curr.type]: [...(prev[curr.type] || []), curr] }), {});
        })
        .then((x) => setLegalBlank(x.sort((a, b) => new Date(b.date) - new Date(a.date))))
        .catch(() => setLegalBlank([])),
    ]);
  }, [company.id]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title={t('LEGAL_ACTS')}>
          <Table
            columns={[
              { title: t('OPERATION_ID'), dataIndex: 'operationnumber' },
              { title: t('LEGAL_ACTS'), dataIndex: 'name' },
              { title: t('OPERATION_DATE'), dataIndex: 'date', render: (date) => new Date(date).toLocaleDateString() },
              {
                title: t('OPERATION_TYPE'),
                dataIndex: 'operationtype',
                render: (type) => <OperationType type={type} />,
              },
              {
                title: t('ACTIONS'),
                render: (operation) => (
                  <Button
                    onClick={() => requestFile(`/company/${company.id}/act/${operation.id}`, operation.name)}
                    icon={<DownloadOutlined />}
                    type="text"
                  >
                    {t('DOWNLOAD')}
                  </Button>
                ),
              },
            ]}
            dataSource={legalActs}
          />
        </Card>
      </Col>

      <Col span={24}>
        <Card title={t('BLANK_ACTS_TITLE')}>
          {Object.entries(legalBlank).map(([operationId, operations]) => (
            <Collapse
              defaultActiveKey={[1]}
              expandIconPosition="right"
              style={{ backgroundColor: 'white', borderWidth: 0 }}
              key={Math.random()}
            >
              <Panel
                header={<div style={{ fontSize: 16, fontWeight: 500 }}>{t(`OPERATIONS_${operationId}_LABEL`)}</div>}
                key={1}
              >
                <Table
                  dataSource={operations}
                  columns={[
                    { title: t('DOCUMENT_NAME'), dataIndex: 'name' },
                    {
                      title: t('VERSION_DATE'),
                      dataIndex: 'date',
                      render: (date) => new Date(date).toLocaleDateString(),
                    },
                    {
                      title: t('ACTIONS'),
                      render: (operation) => (
                        <Button
                          onClick={() => requestFile(`/company/${company.id}/act/${operation.id}`, operation.name)}
                          icon={<DownloadOutlined />}
                          type="text"
                        >
                          {t('DOWNLOAD')}
                        </Button>
                      ),
                    },
                  ]}
                />
              </Panel>
            </Collapse>
          ))}
        </Card>
      </Col>
    </Row>
  );
}

export default LegalActs;
