/* eslint-disable react/no-array-index-key */
import { Alert, Button, Col, Divider, Form, Row, Tooltip, Upload, notification } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import operationsApi from '../../../../../api/operation';
import { useCompany } from '../../../../../redux/company/selectors';
import { setOperationData, setOperationCancel } from '../../../../../redux/operation/actions';
import { useOperationChild, useOperationData } from '../../../../../redux/operation/selectors';
import { useSecondColor } from '../../../../../redux/settings/selectors';
import FormContext from './context';
import disabledShareTypes from '../../disabledShareTypes';
import onFileUpload from '../../../../../services/onFileUpload';

const styles = { divider: { borderTopWidth: 2 }, actions: { display: 'flex', justifyContent: 'space-between' } };

const unavailable = disabledShareTypes();

function OperationsCreateForm({ children, setFile }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refRow = useRef();
  const refCol = useRef();
  const [form] = useForm();

  const company = useCompany();
  const currentOperation = useOperationChild();
  const secondColor = useSecondColor();
  const data = useOperationData();

  const [widthTooltip, setWidthTooltip] = useState(0);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(false);

  const available = !unavailable.includes(currentOperation.shareType);

  useEffect(() => {
    const resize = () => {
      if (!refRow.current || !refCol.current) return;
      const widthRow = refRow.current.offsetWidth;
      const widthCol = refCol.current.offsetWidth;
      setWidthTooltip(widthRow - widthCol);
    };

    resize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [refRow, refCol]);

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  const OperationToolTip = useCallback(
    ({ children: c, title, placement }) => (
      <Tooltip
        placement={widthTooltip ? placement || 'rightTop' : 'topLeft'}
        title={
          <div
            onClick={(e) => {
              const targetLink = e.target.closest('a');
              if (!targetLink) return;
              e.preventDefault();
              history.push(e.target.pathname);
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        }
        color={secondColor}
        overlayStyle={{ minWidth: widthTooltip }}
      >
        {c}
      </Tooltip>
    ),
    [widthTooltip, secondColor, history]
  );

  const onFinish = async (values) => {
    setError(null);

    const { total, mode } = values;
    if (total && !['MULTIPLICATION', 'DIVISION'].includes(mode)) {
      const sumShares = values.v?.reduce((prev, curr) => (curr && !curr.ignore ? prev + curr.shares : prev), 0);
      if (total !== sumShares) return setError(t('OPERATIONS_TOTAL_NOT_EQUAL_TO_SHARES'));
    }
    setNextDisabled(true);

    let description = null;
    if (!values.v) {
      description = await operationsApi
        .verify(company.id, currentOperation.id, {
          ...values,
          date: values.date.format('YYYY-MM-DD'),
          options: JSON.stringify(values.options),
          shareType: values.shareType || currentOperation.shareType,
        })
        .catch(() => null);
    }

    return dispatch(setOperationData(values, description));
  };

  return (
    <>
      <Row ref={refRow}>
        <Col ref={refCol} xs={24} sm={24} md={24} lg={24} xl={16} xxl={14}>
          <Form
            form={form}
            labelCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 16, xxl: 10 }}
            initialValues={data}
            onFinishFailed={(v) => {
              // temporary fix
              if (v.errorFields.length === 0) onFinish(v.values);
            }}
            onFinish={onFinish}
            onValuesChange={(_, values) => {
              setFormValues(values);
            }}
            scrollToFirstError
          >
            <FormContext.Provider value={{ Tooltip: OperationToolTip, form, widthTooltip, formValues }}>
              {children}
            </FormContext.Provider>
          </Form>
        </Col>
      </Row>

      {error && <Alert message={error} type="error" showIcon />}
      <Upload maxCount={1} customRequest={(o) => onFileUpload(o, setFile, notification, t)}>
        {available && (
          <OperationToolTip title={t(`TOOLTIP_UPLOAD_DOCUMENT_${currentOperation.id}`)}>
            <Button shape="round" type="dashed" size="large" icon={<CloudUploadOutlined />}>
              {t(`UPLOAD_DOCUMENT`)}
            </Button>
          </OperationToolTip>
        )}
      </Upload>

      <Divider style={styles.divider} />
      <div style={styles.actions}>
        <Button onClick={() => dispatch(setOperationCancel())}>{t('COMMON_PREVIOUS')}</Button>
        {available && (
          <Button type="primary" htmlType="submit" onClick={() => form.submit()} disabled={nextDisabled}>
            {t('COMMON_NEXT')}
          </Button>
        )}
      </div>
    </>
  );
}

export default OperationsCreateForm;
