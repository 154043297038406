/* eslint-disable no-param-reassign */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Modal, notification, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import newsApi from '../../api/news';
import TitlePrimary from '../../components/TitlePrimary';
import { useCompany } from '../../redux/company/selectors';
import imageDefault from './news.png';

const { TextArea } = Input;

function NewsCreate({ refresh }) {
  const { t } = useTranslation();
  const [form] = useForm();

  const company = useCompany();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState(null);
  const [modal, setModal] = useState(false);

  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
    setModal(false);
    form.resetFields();
  };

  const add = async () => {
    try {
      setLoading(true);
      if (news.fileId?.file) {
        const { file } = await newsApi.uploadFile(company.id, news.fileId.file);
        news.fileId = file;
      }

      await newsApi.create(company.id, news);
      await refresh();
      setLoading(false);
      notification.success({ description: t('RIGHTS_HOLDERS_ADD_SUCCESS') });
      hide();
    } catch (e) {
      setLoading(false);
      notification.error({ description: t('RIGHTS_HOLDERS_ADD_ERROR') });
    }
  };

  const footer = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={() => setModal(false)}>{t('COMMON_CANCEL')}</Button>
      <Button type="primary" onClick={add} loading={loading}>
        {t('YES_I_CONFIRM')}
      </Button>
    </div>
  );

  return (
    <>
      <Modal title={news?.title} visible={modal} footer={footer()} onCancel={() => setModal(false)} width={800}>
        <div style={{ display: 'flex' }}>
          {news?.fileId?.file ? (
            <img alt="news" src={URL.createObjectURL(news.fileId.file)} style={{ maxWidth: 200, marginRight: 20 }} />
          ) : (
            <img alt="news" src={imageDefault} style={{ maxWidth: 200, marginRight: 20 }} />
          )}
          <div>{news?.description}</div>
        </div>
      </Modal>

      <Button type="text" icon={<PlusOutlined />} onClick={show}>
        {t('NEWS_ADD')}
      </Button>

      <Drawer
        title={<TitlePrimary title={t('NEWS_ADD')} />}
        placement="right"
        width={500}
        onClose={hide}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <div className="dg-flex dg-justify-between">
              <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>
              <Button type="primary" onClick={() => form.submit()} loading={loading}>
                {t('CREATE')}
              </Button>
            </div>
          </div>
        }
        closable
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            setNews(values);
            setModal(true);
          }}
        >
          <Form.Item
            label={t('NEWS_NAME')}
            name="title"
            rules={[{ required: true, message: t('NEWS_NAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('NEWS_NAME_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item
            label={t('NEWS_DESCRIPTION')}
            name="description"
            rules={[{ required: true, message: t('NEWS_DESCRIPTION_MESSAGE_REQUIRED') }]}
          >
            <TextArea rows={6} placeholder={t('NEWS_DESCRIPTION_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item name="fileId">
            <Upload beforeUpload={() => false} maxCount={1}>
              <Button type="dashed" icon={<PlusOutlined />}>
                {t('NEWS_ADD_PHOTO')}
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default NewsCreate;
