import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutAuthentication from '../../components/layout/Authentication';
import { usePrimaryColor } from '../../redux/settings/selectors';
import RegisterForm from './Form';

const { Title } = Typography;

const styles = {
  right: { container: { height: '100%' } },
  title: { textAlign: 'center', marginBottom: '10%' },
};

function Register() {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  return (
    <LayoutAuthentication
      leftImageUrl={`${process.env.PUBLIC_URL}/assets/images/inscription.svg`}
      leftImageCss={{ width: '90%' }}
    >
      <Row align="middle" style={styles.right.container}>
        <Col xs={{ span: 20, offset: 2 }}>
          <div style={styles.title}>
            <Title level={1} style={{ color: primaryColor }}>
              {t('REGISTER_TITLE')}
            </Title>
            <Title level={5}>{t('REGISTER_DESCRIPTION')}</Title>
          </div>
          <RegisterForm />
        </Col>
      </Row>
    </LayoutAuthentication>
  );
}

export default Register;
