import React from 'react';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import ShareTypes from '../components/ShareTypes';
import OperationsCreateShareholdersCreation from '../components/Shareholders/Creation';
import OperationsComment from '../components/Comment';
import OperationsDate from '../components/Date';

// Constitution de la société (souscription initiale)
function OperationsCreate1x1() {
  const { t } = useTranslation();

  return (
    <>
      <ShareTypes />
      <OperationsDate />
      <Form.Item name={['common', 'accountState']} initialValue={1} hidden>
        <InputNumber />
      </Form.Item>
      <OperationsCreateShareholdersCreation />
      <Form.Item label={t('OPERATION_NOMINAL_VALUE')} name="nominalValue">
        <InputNumber />
      </Form.Item>
      <OperationsComment />
    </>
  );
}

export default OperationsCreate1x1;
