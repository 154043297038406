import request from '../services/request';

const getProfile = (companyId, shareholderId) =>
  request(`/indivision/${shareholderId}/indivisaires?companyId=${companyId}`);
const getAccounts = (companyId, shareholderId) =>
  request(`/company/${companyId}/shareholders?shareholder=${shareholderId}&operations=all`);
const getStats = (companyId, shareholderId) => request(`/company/${companyId}/shareholder/${shareholderId}/diagram`);

const shareholderApi = {
  getProfile,
  getAccounts,
  getStats,
};

export default shareholderApi;
