import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Typography, Form, Input, notification, Alert } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../api/permission';
import TitlePrimary from '../../../components/TitlePrimary';
import { useCompany } from '../../../redux/company/selectors';

const { Title } = Typography;

function RightsHoldersTransfer({ refresh, admin }) {
  const { t } = useTranslation();
  const [form] = useForm();

  const company = useCompany();

  const [visible, setVisible] = useState(false);
  const [{ loading, error }, setState] = useState({ loading: false, error: null });

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const send = async (values) => {
    try {
      if (values.email === admin.email) {
        // eslint-disable-next-line no-throw-literal
        throw { message: 'CANNOT_TRANSFER_TO_ADMIN' };
      }
      setState({ loading: true, error: null });
      await permissionApi.sendInvitationShareholder(company.id, 2, values, 'transfer');
      setState({ loading: false, error: null });
      // @ts-ignore
      refresh();
      notification.success({ description: t('RIGHTS_HOLDERS_TRANSFER_SUCCESS') });
      form.resetFields();
      hide();
    } catch (e) {
      setState({ loading: false, error: e.message });
    }
  };

  return (
    <>
      <Button type="text" icon={<PlusOutlined />} onClick={show}>
        {t('RIGHTS_HOLDERS_TRANSFER_BUTTON')}
      </Button>

      <Drawer
        title={<TitlePrimary title={t('RIGHTS_HOLDERS_TRANSFER_TITLE')} />}
        placement="right"
        width={600}
        onClose={hide}
        visible={visible}
        footer={
          <div className="dg-flex dg-justify-between">
            <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>
            <Button type="primary" onClick={() => form.submit()} loading={loading}>
              {t('YES_I_CONFIRM')}
            </Button>
          </div>
        }
        closable
      >
        <Title level={5}>
          <div>{t('RIGHTS_HOLDERS_TRANSFER_QUESTION_1')}</div>
          <div>{t('RIGHTS_HOLDERS_TRANSFER_QUESTION_2')}</div>
        </Title>

        <Form form={form} layout="vertical" onFinish={send}>
          <Form.Item>{t('RIGHTS_HOLDERS_TRANSFER_DESCRIPTION')}</Form.Item>

          <Form.Item
            label={t('USER_LASTNAME')}
            name="lastname"
            rules={[{ required: true, message: t('USER_LASTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_LASTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item
            label={t('USER_FIRSTNAME')}
            name="firstname"
            rules={[{ required: true, message: t('USER_FIRSTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_FIRSTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item
            label={t('USER_EMAIL')}
            name="email"
            rules={[
              { required: true, message: t('USER_EMAIL_MESSAGE_REQUIRED') },
              { type: 'email', message: t('COMMON_EMAIL_BAD_FORMAT') },
            ]}
          >
            <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert message={t(error)} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </>
  );
}

export default RightsHoldersTransfer;
