import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountsListTable from './Table';

function AccountsList() {
  const { t } = useTranslation();

  return (
    <>
      <Card title={t('SHAREHOLDERS_ACCOUNTS')}>
        <AccountsListTable />
      </Card>
    </>
  );
}

export default AccountsList;
