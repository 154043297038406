import styleApi from '../../api/style';

export const SET_PRIMARY_COLOR = 'SET_PRIMARY_COLOR';
export const SET_SECOND_COLOR = 'SET_SECOND_COLOR';
export const SET_THIRD_COLOR = 'SET_THIRD_COLOR';
export const SET_FOURTH_COLOR = 'SET_FOURTH_COLOR';
export const SET_LOGO = 'SET_LOGO';

export const setPrimaryColor = (color) => ({ type: SET_PRIMARY_COLOR, color });
export const setSecondColor = (color) => ({ type: SET_SECOND_COLOR, color });
export const setThirdColor = (color) => ({ type: SET_THIRD_COLOR, color });
export const setFourthColor = (color) => ({ type: SET_FOURTH_COLOR, color });
export const setLogo = (logo) => ({ type: SET_LOGO, logo });

export const fetchStyle = (domain) => async (dispatch) => {
  const style = await styleApi.getStyle(domain);
  if (!style.length) return;
  const maxDate = new Date(Math.max(...style.map((x) => new Date(x.date)))).toISOString();
  const lastStyle = style.find((x) => x.date === maxDate);
  if (lastStyle.colors.primary) dispatch(setPrimaryColor(lastStyle.colors.primary));
  if (lastStyle.colors.second) dispatch(setSecondColor(lastStyle.colors.second));
  if (lastStyle.colors.third) dispatch(setThirdColor(lastStyle.colors.third));
  if (lastStyle.title) document.title = lastStyle.title;
  if (lastStyle.icoid) {
    const icoid = await styleApi.getLogo(lastStyle.icoid);
    const favicon = document.getElementById('favicon');
    favicon.href = icoid;
  }
  const logo = await styleApi.getLogo(lastStyle.logoid);
  dispatch(setLogo(logo));
};
