import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { writeFileXLSX, utils } from 'xlsx';
import { requestFile } from '../services/request';

const toArray = ({ data, columns }) => [columns.map((column) => column.title || column), ...data];

const downloadXSLX = ({ data, columns, name }) => {
  const output = toArray({ data, columns });

  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet(output);
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  writeFileXLSX(workbook, `${name}`);
};

const downloadCSV = ({ data, columns, name }) => {
  const output = toArray({ data, columns });

  const csvContent = `data:text/csv;charset=utf-8,${output.map((x) => x.join(',')).join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

const formats = {
  csv: downloadCSV,
  xlsx: downloadXSLX,
  pdf: ({ route, name }) => requestFile(route, name),
};

const FileSelector = ({ isVisible, setIsVisible, route, name, data, columns, isPDFUnavailable }) => {
  const { t } = useTranslation();

  const [selectedFormat, setSelectedFormat] = useState(Object.keys(formats)[0]); // Default format

  const handleExportClick = () => {
    formats[selectedFormat]({
      data: data.map((x) => x.map((v) => String(v).replace(/\n/g, '|'))),
      columns,
      route,
      name: `${name}.${selectedFormat}`,
    });
    setIsVisible(false);
  };

  return (
    <Modal
      title={t('FILE_SELECTOR_TITLE')}
      visible={isVisible}
      onOk={handleExportClick}
      onCancel={() => setIsVisible(false)}
    >
      <Radio.Group onChange={(e) => setSelectedFormat(e.target.value)} value={selectedFormat}>
        <Radio value={Object.keys(formats)[0]}>CSV</Radio>
        <Radio value={Object.keys(formats)[1]}>XLSX</Radio>
        {!isPDFUnavailable && <Radio value={Object.keys(formats)[2]}>PDF</Radio>}
      </Radio.Group>
    </Modal>
  );
};

export default FileSelector;
