import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table, Radio, Divider, notification } from 'antd';
import { useCompany } from '../redux/company/selectors';
import permissionApi from '../api/permission';

const RowMiddle = ({ children }) => (
  <Row align="middle" justify="center">
    {children}
  </Row>
);

const ChoiceButton = ({ action, choices, defaultValue }) => (
  <RowMiddle>
    <Radio.Group defaultValue={defaultValue} style={{ margin: 12 }} onChange={(x) => action(x.target.value)}>
      {choices.map((x) => (
        <Radio.Button value={x.value}>{x.label}</Radio.Button>
      ))}
    </Radio.Group>
  </RowMiddle>
);

export default ({ users, setUsers, selectedUsers, setSelectedUsers, isDraft, setIsDraft }) => {
  const { t } = useTranslation();
  const company = useCompany();
  const [isNotified, setIsNotified] = useState(false);

  const fetchUsers = useCallback(() => {
    permissionApi
      .getPermissionHolders(company.id)
      .then((x) => x.filter((o) => o.accountactive === true))
      .then(setUsers)
      .catch((e) => {
        setUsers([]);
        notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
      });
  }, [company, t, setUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (isNotified === false) setSelectedUsers([]);
  }, [isNotified, setSelectedUsers]);

  return (
    <Col>
      <RowMiddle>{t('DECISION_MODAL_TEXT')}</RowMiddle>
      <ChoiceButton
        defaultValue={isDraft}
        action={setIsDraft}
        choices={[
          { value: true, label: t('DECISION_DRAFT') },
          { value: false, label: t('DECISION_BLOCK') },
        ].filter((x) => x)}
      />
      {isDraft ? (
        <div>
          <RowMiddle>{t('DECISION_DRAFT_TEXT')}</RowMiddle>
          <Divider />
          <RowMiddle>{t('DECISION_DRAFT_TEXT_NOTIFICATION')}</RowMiddle>
          <ChoiceButton
            defaultValue={isNotified}
            action={setIsNotified}
            choices={[
              { value: true, label: t('YES') },
              { value: false, label: t('NO') },
            ]}
          />
          {isNotified ? (
            <Table
              dataSource={users.map((x) => ({ ...x, key: x.userid }))}
              columns={[
                {
                  title: t('FIRSTNAME_AND_NAME'),
                  render: (user) => `${user.firstname || ''} ${user.lastname || ''}`,
                },
                { title: t('USER_EMAIL'), dataIndex: 'email' },
              ]}
              rowSelection={{
                selectedRowKeys: selectedUsers,
                onChange: setSelectedUsers,
              }}
            />
          ) : (
            <div />
          )}
        </div>
      ) : (
        <RowMiddle>{t('DECISION_BLOCK_TEXT')}</RowMiddle>
      )}
    </Col>
  );
};
