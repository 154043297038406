import { Row, Col, Typography, Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const styles = {
  container: {
    padding: 16,
    marginBottom: 16,
    borderRadius: 5,
    background: '#F4F5F7',
  },
};

function ResumeDescriptionV2({ description }) {
  const { t } = useTranslation();

  const comment = description.find((d) => d && (d.label === 'COMMENT' || d.label === 'OPERATIONS_COMMENT'));

  return (
    <div style={styles.container}>
      <Row gutter={[16, 16]}>
        {description.map(({ label, value }) => {
          if (label === 'COMMENT' || label === 'OPERATIONS_COMMENT') return null;
          return (
            <React.Fragment key={label}>
              <Col span={6}>{t(label)}</Col>
              <Col span={6}>
                <Text strong>{label === 'OPERATIONS_DATE' ? new Date(value).toLocaleDateString() : t(value)}</Text>
              </Col>
            </React.Fragment>
          );
        })}

        {comment && (
          <>
            <Divider style={{ margin: 0 }} />

            <Col span={24}>
              {`${t(comment.label)} : `}
              <span style={{ fontStyle: 'italic' }}>{comment.value}</span>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default ResumeDescriptionV2;
