import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Table, Button } from 'antd';
import { PlusOutlined, FormOutlined, BarsOutlined, BuildOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import RegisterAddModal from './RegisterAddModal';
import { getDecisionRegisters, getStats } from '../../api/decision';
import { useCompany } from '../../redux/company/selectors';
import Stats from '../../components/Stats';

const DecisionRegister = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const company = useCompany();

  const [stats, setStats] = useState({});

  useEffect(() => {
    getStats(company.id)
      .then(setStats)
      .catch(() => setStats({}));
  }, [company.id]);

  const [isVisible, setIsVisible] = useState(false);
  const [registers, setRegisters] = useState([]);

  useEffect(() => {
    getDecisionRegisters(company.id).then(setRegisters);
  }, [company, isVisible]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Stats
          stats={[
            { value: registers.length, label: t('DECISION_REGISTERS_COUNT'), icon: BarsOutlined },
            { value: stats.total, label: t('DECISIONS_COUNT'), icon: FormOutlined },
            {
              value: Number(stats.total) - Number(stats.draft),
              label: t('DECISIONS_ANCHOR_COUNT'),
              icon: BuildOutlined,
            },
          ]}
        />
        <Card
          title={t('DECISION_REGISTER')}
          extra={
            <Button type="primary" onClick={() => setIsVisible(true)} icon={<PlusOutlined />}>
              {t('ADD_REGISTER')}
            </Button>
          }
        >
          <Table
            columns={[
              { title: t('DECISION_REGISTER_TYPE'), dataIndex: 'type', render: (x) => t(`REGISTER_TYPE_${x}`) },
              { title: t('DECISION_PV_NUMBER'), dataIndex: 'size' },
              {
                title: t('DECISION_DATE'),
                dataIndex: 'createdat',
                render: (date) => new Date(date).toLocaleDateString(),
              },
            ]}
            dataSource={registers}
            rowClassName="dg-cursor"
            onRow={(r) => ({
              onClick: () => history.push(`/documentation/decision-register/${r.id}`, { type: r.type }),
            })}
          />
        </Card>
        <RegisterAddModal isVisible={isVisible} hide={() => setIsVisible(false)} />
      </Col>
    </Row>
  );
};

export default DecisionRegister;
