import { DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer, Typography, Form, Input, notification, Alert } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitlePrimary from '../../../components/TitlePrimary';
import { useCompany } from '../../../redux/company/selectors';
import permissionApi from '../../../api/permission';

const { Title } = Typography;

function Delete({ refresh, user }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const company = useCompany();

  const [visible, setVisible] = useState(false);
  const [{ loading, error }, setState] = useState({ loading: false, error: null });

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const deleteUser = async (values) => {
    try {
      if (values.confirmEmail === user.email) {
        setState({ loading: true, error: null });
        await permissionApi.deleteUser(company.id, user.userid);
        setState({ loading: false, error: null });
        refresh();
        notification.success({ description: t('USER_DELETED_SUCCESS') });
        form.resetFields();
        hide();
      } else {
        // eslint-disable-next-line no-throw-literal
        throw { message: 'EMAIL_CONFIRMATION_FAILED' };
      }
    } catch (e) {
      setState({ loading: false, error: e?.message });
    }
  };

  return (
    <>
      <Button type="text" icon={<DeleteOutlined />} onClick={show}>
        {t('DELETE_USER_BUTTON')}
      </Button>

      <Drawer
        title={<TitlePrimary title={t('DELETE_USER_TITLE')} />}
        placement="right"
        width={600}
        onClose={hide}
        visible={visible}
        footer={
          <div className="dg-flex dg-justify-between">
            <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>
            <Button type="danger" onClick={() => form.submit()} loading={loading}>
              {t('DELETE_USER_CONFIRM')}
            </Button>
          </div>
        }
        closable
      >
        <Title level={5}>{t('DELETE_USER_CONFIRMATION')}</Title>

        <Form form={form} layout="vertical" onFinish={deleteUser}>
          <Form.Item
            label={t('USER_EMAIL_CONFIRM')}
            name="confirmEmail"
            rules={[
              { required: true, message: t('EMAIL_CONFIRMATION_REQUIRED') },
              {
                type: 'email',
                message: t('COMMON_EMAIL_BAD_FORMAT'),
              },
            ]}
          >
            <Input placeholder={t('EMAIL_CONFIRMATION_PLACEHOLDER')} />
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert message={t(error)} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </>
  );
}

export default Delete;
