import React from 'react';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';
import OperationsAccounts from '../components/v2/Accounts';
import useShareholders from '../../../../hooks/useShareholders';

// Extinction du nantissement
function OperationsCreate3x2() {
  const holders = useShareholders('>', 2);

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />
      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate3x2;
