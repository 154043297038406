import { Form, DatePicker } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from './Form/context';
import { useOperationChild } from '../../../../redux/operation/selectors';

const style = { width: '100%', minWidth: 150 };

function OperationsDate() {
  const { t } = useTranslation();

  const { Tooltip } = useContext(FormContext);

  return (
    <Tooltip title={t(`OPERATIONS_${useOperationChild().id}_DATE_TOOLTIP`)}>
      <Form.Item
        label={t('OPERATIONS_DATE')}
        name="date"
        rules={[{ required: true, message: t('OPERATIONS_DATE_MESSAGE_REQUIRED') }]}
      >
        <DatePicker style={style} format="DD/MM/YYYY" />
      </Form.Item>
    </Tooltip>
  );
}

export default OperationsDate;
