import { PlusOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Form, Input, Collapse, Space, Button, InputNumber, Checkbox, Row, Col, Alert } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useShareholdersLocal from '../../../../../../hooks/useShareholdersLocal';
import { openModalCreateShareholder } from '../../../../../../redux/operation/actions';
import FormContext from '../../Form/context';
import OperationsShares from '../../Shares';
import ShareholderCommon from '../Common';

const { Panel } = Collapse;

function ShareholdersCompute({ mode = 'ADD', shareType }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);
  const [holders, setHolders] = useState([]);

  const shareholders = useShareholdersLocal('>=', 1, null, shareType || null);

  useEffect(() => {
    setHolders(
      (mode === 'ADD'
        ? shareholders
        : shareholders.map((x) => ({ ...x, accounts: x.accounts?.filter((a) => a.balance > 0) }))
      ).filter((x) => x.accounts.length)
    );
  }, [shareholders, mode]);

  if (holders.length === 0)
    return (
      <Form.Item>
        <Alert message={t('SHARE_TYPE_EMPTY_SHAREHOLDERS')} type="warning" showIcon />
      </Form.Item>
    );

  return (
    <>
      <Form.Item name={['mode']} initialValue={mode} hidden>
        <Input />
      </Form.Item>

      <Tooltip title={t(`OPERATIONS_TOTAL_${mode}_TOOLTIP`)}>
        <Form.Item
          label={t(`OPERATIONS_TOTAL_${mode}`)}
          name="total"
          rules={[{ required: true, message: t(`OPERATIONS_TOTAL_${mode}_REQUIRED`) }]}
        >
          <InputNumber />
        </Form.Item>
      </Tooltip>

      <Form.Item label={t('OPERATION_NOMINAL_VALUE')} name="nominalValue">
        <InputNumber />
      </Form.Item>

      <Form.Item shouldUpdate={() => true}>
        {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
          const values = getFieldsValue();

          return (
            <Collapse
              key={holders.length}
              defaultActiveKey={holders.map(({ id }) => `shareholder-${id}`)}
              bordered={false}
              expandIconPosition="right"
            >
              {holders.map((shareholder) => (
                <Panel
                  header={
                    <Space>
                      {/* <Button
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => dispatch(removeShareholderSelected(shareholder.id))}
                        danger
                      /> */}
                      {shareholder.name}
                    </Space>
                  }
                  key={`shareholder-${shareholder.id}`}
                  forceRender
                >
                  {shareholder.accounts.map((account) => {
                    const id = account.accountNumber;

                    const shares = getFieldValue(['v', id, 'shares']) || 0;
                    const isMinus = ['SUBSTRACTION', 'DIVISION'].includes(mode);
                    const symbol = isMinus ? '-' : '+';
                    const sum = isMinus
                      ? Number(account.balance) - Number(shares)
                      : Number(shares) + Number(account.balance);

                    const CheckboxIgnore = ({ value }) => (
                      <Checkbox
                        checked={!value}
                        onChange={(e) => {
                          setFieldsValue({
                            ...values,
                            v: values.v.map((m, index) =>
                              index === id
                                ? { ...m, ignore: !e.target.checked, shares: !e.target.checked ? 0 : m.shares }
                                : m
                            ),
                          });
                        }}
                      >
                        {t(`ACCOUNT_TYPE_${account.accountType}`)}
                      </Checkbox>
                    );

                    return (
                      <Tooltip title={t(`OPERATIONS_COMPUTE_${mode}_ACCOUNT_TOOLTIP`)} key={`account-${id}`}>
                        <div style={{ marginRight: -16 }}>
                          <Row gutter={[8, 8]}>
                            <Col span={10} />
                            <Col span={7}>{t(`OPERATIONS_SHARES_${symbol}`)}</Col>
                            <Col span={7}>{t('OPERATIONS_SHARES_TOTAL')}</Col>

                            <Col span={10}>
                              <Space size="large">
                                <Form.Item name={['v', id, 'ignore']} initialValue={account.balance === 0} noStyle>
                                  <CheckboxIgnore />
                                </Form.Item>
                                {account.balance}
                              </Space>
                            </Col>

                            <Col span={7}>
                              <OperationsShares mode={mode} index={id} />
                            </Col>
                            <Col span={7}>
                              <Form.Item>
                                <InputNumber value={sum} disabled />
                              </Form.Item>
                            </Col>
                          </Row>

                          <ShareholderCommon
                            shareholder={shareholder}
                            account={account}
                            symbol={['SUBSTRACTION', 'DIVISION'].includes(mode) ? '-' : '+'}
                          />

                          <Form.Item name={['v', id, 'balance']} initialValue={account.balance} hidden>
                            <Input />
                          </Form.Item>
                        </div>
                      </Tooltip>
                    );
                  })}
                </Panel>
              ))}
            </Collapse>
          );
        }}
      </Form.Item>
      <Form.Item>
        <Space>
          {mode === 'ADD' && (
            <Tooltip title={t(`OPERATIONS_SHAREHOLDERS_CREATE_TOOLTIP`)}>
              <Button type="dashed" icon={<PlusOutlined />} onClick={() => dispatch(openModalCreateShareholder())}>
                {t('OPERATIONS_SHAREHOLDERS_CREATE')}
              </Button>
            </Tooltip>
          )}
          <Form.Item shouldUpdate={() => true} noStyle>
            {({ setFieldsValue, getFieldsValue, getFieldValue }) => (
              <Button
                type="primary"
                icon={<CalculatorOutlined />}
                onClick={() => {
                  const values = getFieldsValue();

                  let v = values;
                  const balanceTotal = values.v.reduce(
                    (prev, curr) => (curr && !curr.ignore ? prev + curr.balance : prev),
                    0
                  );

                  if (mode === 'MULTIPLICATION') {
                    v = {
                      ...values,
                      mode,
                      v: values.v.map((m) => (m && !m.ignore ? { ...m, shares: m.balance * (values.total - 1) } : m)),
                    };
                  } else if (mode === 'DIVISION') {
                    v = {
                      ...values,
                      mode,
                      v: values.v.map((m) =>
                        m && !m.ignore ? { ...m, shares: m.balance - m.balance / values.total } : m
                      ),
                    };
                  } else if (mode === 'SUBSTRACTION') {
                    v = {
                      ...values,
                      v: values.v.map((m) =>
                        m && !m.ignore
                          ? {
                              ...m,
                              shares: m.balance && balanceTotal ? (m.balance / balanceTotal) * values.total : 0,
                            }
                          : m
                      ),
                    };
                  } else if (mode === 'ADD') {
                    v = {
                      ...values,
                      v: values.v.map((m) =>
                        m && !m.ignore
                          ? { ...m, shares: m.balance && balanceTotal ? (m.balance / balanceTotal) * values.total : 0 }
                          : m
                      ),
                    };
                  }

                  setFieldsValue(v);
                }}
                disabled={!getFieldValue('total')}
              >
                {t('UNDIVIDED_CALCUL_EMISSION')}
              </Button>
            )}
          </Form.Item>
        </Space>
      </Form.Item>

      <Form.Item>
        <Alert message={t('OPERATIONS_SHAREHOLDERS_COMPUTE_WARNING')} type="warning" showIcon />
      </Form.Item>
    </>
  );
}

export default ShareholdersCompute;
