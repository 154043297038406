import { Button, Divider, Radio } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setOperationChild, setOperationNextStep } from '../../../redux/operation/actions';
import { useOperationChild, useOperationParent } from '../../../redux/operation/selectors';

const styles = {
  radio: { display: 'block', height: '30px', lineHeight: '30px' },
  divider: { borderTopWidth: 2 },
  actions: { display: 'flex', justifyContent: 'space-between' },
};

function OperationsCreateSelect() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const parent = useOperationParent();
  const child = useOperationChild();

  return (
    <div key={`OPERATIONS_PARENT_${parent.id}`}>
      <Title level={5}>{t('OPERATIONS_SELECT')}</Title>
      <Radio.Group onChange={(e) => dispatch(setOperationChild(e.target.value))} value={child} size="large">
        {parent.children.map((c) => (
          <Radio key={`OPERATIONS_${c.id}`} value={c} style={styles.radio}>
            {t(`OPERATIONS_${c.id}_LABEL`)}
          </Radio>
        ))}
      </Radio.Group>
      <Divider style={styles.divider} />
      <div style={styles.actions}>
        <div />
        <Button type="primary" disabled={!child} onClick={() => dispatch(setOperationNextStep())}>
          {t('COMMON_NEXT')}
        </Button>
      </div>
    </div>
  );
}

export default OperationsCreateSelect;
