import React, { useState } from 'react';
import { Row, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { postDecisionRegister } from '../../api/decision';
import { useCompany } from '../../redux/company/selectors';

const RegisterAddModal = ({ isVisible, hide }) => {
  const { t } = useTranslation();
  const [register, setRegister] = useState(1);
  const company = useCompany();

  return (
    <Modal
      title={t('REGISTER_ADD_MODAL')}
      visible={isVisible}
      onCancel={hide}
      okText={t('VALIDATE')}
      onOk={() => postDecisionRegister(company.id, { type: register }).then(hide)}
    >
      <div style={{ textAlign: 'center' }}>{t('REGISTER_ADD_MODAL_TEXT')}</div>
      <br />
      <Row justify="center">
        <Select
          style={{ width: '70%' }}
          onSelect={setRegister}
          options={Array.from(Array(8)).map((_, i) => ({ label: t(`REGISTER_TYPE_${i + 1}`), value: i + 1 }))}
          defaultValue={1}
        />
      </Row>
    </Modal>
  );
};
export default RegisterAddModal;
