import { Form, Radio } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleIndivision from './MultipleIndivision';
import UniqueIndivision from './UniqueIndivision';

function LegalForm1() {
  const { t } = useTranslation();
  const [indivision, setIndivision] = useState(false);

  return (
    <>
      <Form.Item label={t('OPERATIONS_SHAREHOLDER_CREATE_INDIVISION')} labelCol={{ span: 24 }}>
        <Radio.Group
          options={[
            { label: t('COMMON_YES'), value: true },
            { label: t('COMMON_NO'), value: false },
          ]}
          value={indivision}
          onChange={(e) => setIndivision(e.target.value)}
        />
      </Form.Item>
      {indivision === false && <UniqueIndivision />}
      {indivision === true && <MultipleIndivision />}
    </>
  );
}

export default LegalForm1;
