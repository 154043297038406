import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import CustomItems from '../../../components/CustomItems';
import { updateIndivision } from '../../../api/indivision';

function EditCustomItems({ style, validate, onChange }) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { shareholderId } = useParams();
  const [customFields, setCustomFields] = useState(state?.indivisionCustoms);
  const firstUpdate = useRef(true);

  const update = () =>
    customFields &&
    updateIndivision({ id: Number(shareholderId), customFields })
      .then(() => notification.success({ description: t('CUSTOM_UPDATE_SUCCESS') }))
      .catch((e) => {
        setCustomFields(state?.indivisionCustoms); // reset to previous values
        notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
      });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);

  return (
    <div style={style}>
      {state && (
        <CustomItems
          form={{
            setFieldsValue: (v) => {
              setCustomFields([...Object.entries(v)[0][1]]);
              onChange?.(v);
            },
          }}
          defaultValues={state?.indivisionCustoms}
        />
      )}
      {validate === undefined && (
        <Button type="primary" onClick={update}>
          {t('EDIT_CUSTOM_ITEMS')}
        </Button>
      )}
    </div>
  );
}

export default EditCustomItems;
