import request from '../services/request';

const uploadFile = async (companyId, file, opId, type) => {
  const formData = new FormData();
  formData.append('file', file);
  return request(`/company/${companyId}/${type || 'act'}/${opId || ''}`, 'POST', formData, true);
};

const create = (id, operation, isDraft) => request(`/operation/${id}?isDraft=${!!isDraft}`, 'POST', operation);
const createV2 = (companyId, operationId, body, isDraft) =>
  request(`/company/${companyId}/operation/${operationId}?isDraft=${!!isDraft}`, 'POST', body);
const verify = (companyId, operationId, body) =>
  request(`/company/${companyId}/operation/${operationId}/verify`, 'POST', body);
const getAll = (userId) => request(`/user/${userId}/operations`);
const getDraftOperations = (companyId) => request(`/company/${companyId}/operations`);
const getResume = (companyId, operationId, isDraft) =>
  request(`/company/${companyId}/operation/${operationId}${isDraft ? '?isDraft=true' : ''}`);

const operationsApi = {
  uploadFile,
  create,
  createV2,
  getAll,
  verify,
  getResume,
  getDraftOperations,
};

export default operationsApi;
