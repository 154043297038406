import queryString from 'query-string';
import request from '../services/request';

const uploadFile = async (companyId, file, type) => {
  const formData = new FormData();
  formData.append('file', file);
  return request(`/company/${companyId}/file/${type}`, 'POST', formData, true);
};

const createCompany = (company) => request('/company/create', 'POST', company);
const updateCompany = (companyId, company) => request(`/company/${companyId}/update`, 'POST', company);
export const getCompanies = (userId) => request(`/user/${userId}/companies`);
export const getUsers = (companyId) => request(`/company/${companyId}/users`);
export const getShareholders = (companyId, queries) =>
  request(`/company/${companyId}/shareholders?${queryString.stringify(queries)}`);
export const getCompany = (companyId) => request(`/company/${companyId}/info`);
const removeCompany = (companyId) => request(`/company/${companyId}`, 'DELETE');
const createShareholder = (values) => request(`/company/shareholder/create`, 'POST', values);
const getStatsByCompanyId = (companyId) => request(`/company/${companyId}/stats`);
const getStats = (userId) => request(`/user/${userId}/companies/stats`);
const getShareholdersStatsByCompanyId = (companyId) => request(`/company/${companyId}/shareholders/stats`);
const getStatsPie = (companyId) => request(`/company/${companyId}/stats?diagram=circular`);
const getLegalBlank = (companyId) => request(`/company/${companyId}/public/list`);
const getLegalActs = (companyId) => request(`/company/${companyId}/act/list`);
const notify = (companyId, body, t) => request(`/company/${companyId}/notify${t ? `?type=${t}` : ''}`, 'POST', body);

const anchorDraft = (companyId, orderNumber) => request(`/company/${companyId}/draft?id=${orderNumber}`, 'POST');
const deleteDraft = (companyId, orderNumber) => request(`/company/${companyId}/draft?id=${orderNumber}`, 'DELETE');

const paymentIntent = (companyId, type, body) =>
  request(`/company/${companyId}/payment/intent?subscriptionType=${type}`, 'POST', body);
const paymentConfirm = (companyId, body) => request(`/company/${companyId}/payment/confirm`, 'POST', body);
const paymentInvoice = (companyId, type, body) =>
  request(`/company/${companyId}/payment/invoice?subscriptionType=${type}`, 'POST', body);

const planStats = (companyId) => request(`/company/${companyId}/plan/stats`, 'GET');

const companyApi = {
  createShareholder,
  createCompany,
  updateCompany,
  removeCompany,
  getShareholders,
  getStatsByCompanyId,
  getShareholdersStatsByCompanyId,
  getStats,
  getStatsPie,
  getLegalActs,
  getLegalBlank,
  notify,
  anchorDraft,
  deleteDraft,
  uploadFile,
  paymentIntent,
  paymentConfirm,
  paymentInvoice,
  planStats,
};

export default companyApi;
