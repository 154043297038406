import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Drawer, Form, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpsertCompanyBody from '../../../components/UpsertCompany';

function UpsertCompany({ refresh, mode }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const consts =
    mode === 'update'
      ? {
          icon: <EditOutlined />,
          label: t('EDIT_COMPANY'),
        }
      : {
          icon: <PlusOutlined />,
          label: t('ADD_COMPANY'),
        };

  const [visible, setVisible] = useState(false);

  const hide = (e) => {
    e?.stopPropagation();
    setVisible(false);
    form.resetFields();
  };

  const show = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      <Button className="react-tour-2" type="primary" icon={consts.icon} onClick={show}>
        {consts.label}
      </Button>

      <Drawer title={consts.label} placement="right" width={720} onClose={hide} visible={visible} closable>
        <UpsertCompanyBody refresh={refresh} mode={mode} hide={hide} />
      </Drawer>
    </>
  );
}

export default UpsertCompany;
