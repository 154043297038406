import { Card } from 'antd';
import React from 'react';

function ShareholderDetailsCardCustom({ title, children }) {
  return (
    <Card style={{ flex: 1, margin: '0 8px', marginBottom: 8 }} title={title}>
      {children}
    </Card>
  );
}

export default ShareholderDetailsCardCustom;
