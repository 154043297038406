import React, { useState, useEffect } from 'react';
import { Card, Button, Input, Select, Upload, notification } from 'antd';
import { BackwardOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './index.css';
import store from '../../redux/store';
import { getUsers } from '../../api/company';
import operationsApi from '../../api/operation';
import onFileUpload from '../../services/onFileUpload';

const Response = ({ validate, back, defaultRecipient, defaultContent, defaultTitle }) => {
  const { t } = useTranslation();

  const { Option } = Select;
  const companyId = store.getState().company.id;

  const [recipients, setRecipients] = useState([]);
  const [msg, setMsg] = useState(
    defaultRecipient
      ? { recipients: [defaultRecipient[0]] }
      : {
          content: defaultContent,
          title: defaultTitle,
        }
  );
  const [file, setFile] = useState(null);

  useEffect(() => {
    getUsers(companyId).then((x) =>
      setRecipients(
        x
          .sort((a, b) => (a.lastname > b.lastname ? 1 : -1))
          .map((v) => (
            <Option key={v.id} label={`${v.lastname} ${v.firstname}`}>{`${v.lastname} ${v.firstname}`}</Option>
          ))
      )
    );
  }, [companyId]);

  return (
    <div>
      <Card type="inner" className="header">
        <div className="space-between">
          <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>{t('MESSAGE_NEW')}</div>
          <Button icon={<BackwardOutlined />} onClick={back}>
            {t('MESSAGE_BACK_BUTTON')}
          </Button>
        </div>
      </Card>
      <Card>
        <Select
          optionFilterProp="label"
          className="margin-bottom"
          size="large"
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={t('MESSAGE_PLACEHOLDER_RECIPIENTS')}
          defaultValue={defaultRecipient ? [defaultRecipient[1]] : []}
          onChange={(e) => setMsg({ ...msg, recipients: e })}
        >
          {recipients}
        </Select>
        <Input
          defaultValue={defaultTitle}
          onChange={(e) => setMsg({ ...msg, title: e.target.value })}
          className="margin-bottom"
          size="large"
          placeholder={t('MESSAGE_PLACEHOLDER_TITLE')}
        />
        <textarea
          defaultValue={defaultContent}
          onChange={(e) => setMsg({ ...msg, content: e.target.value })}
          className="message-entry margin-bottom"
        />
        <div className="space-between">
          <Button size="large" onClick={back}>
            {t('MESSAGE_CANCEL')}
          </Button>
          <Upload maxCount={1} customRequest={(o) => onFileUpload(o, setFile, notification, t)}>
            <Button shape="round" type="dashed" size="large" icon={<CloudUploadOutlined />}>
              {t(`UPLOAD_DOCUMENT`)}
            </Button>
          </Upload>
          <Button
            size="large"
            type="primary"
            onClick={async () => {
              try {
                let fileId;
                if (file)
                  fileId = await operationsApi.uploadFile(companyId, file, null, 'attachment').then((x) => x.file);
                if (!msg.title || !msg.content || !msg.recipients[0]) {
                  const e = { message: 'MISSING_DATA' };
                  throw e;
                }
                validate({ ...msg, fileId, filename: file?.name, onSuccess: () => setMsg({}) });
              } catch (e) {
                notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
              }
            }}
          >
            {t('MESSAGE_VALIDATE')}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Response;
