import request from '../services/request';

export const getUserCompanies = () => request('/user/companies');
export const createUser = (user) => request('/user/create', 'POST', user);
export const updateUser = (user, id) => request(Number(id) ? `/user/${id}/update` : `/user/update`, 'POST', user);
const getNotifications = () => request('/user/notif?limit=3');
const getMe = () => request('/user/current');
const setPayment = (i) => request('/user/payment', 'POST', { subscribed: i });
const getTutorialUser = () => request('/user/tutorial');

const userApi = {
  getNotifications,
  getMe,
  setPayment,
  getTutorialUser,
};

export default userApi;
