import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../Form/context';
import ShareholderCreate from '../../ShareholderCreate';

function OperationsShareholders({ userType, shareholders, canCreate = false }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);
  const USER_TYPE = userType.toUpperCase();

  return (
    <>
      <Tooltip title={t(`OPERATION_${useOperationChild().id}_${USER_TYPE}_TOOLTIP`)}>
        <Form.Item
          name={`${userType}Id`}
          label={t(`OPERATION_${USER_TYPE}`)}
          rules={[{ required: true, message: t(`OPERATION_${USER_TYPE}_MESSAGE_REQUIRED`) }]}
          preserve={false}
        >
          <Select
            options={shareholders.map((s) => ({ label: s.name, value: s.id }))}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
          />
        </Form.Item>
      </Tooltip>
      {canCreate && <ShareholderCreate label={t('CREATE_BENEFICIARY')} />}
    </>
  );
}

export default OperationsShareholders;
