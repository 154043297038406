import React, { useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import ControlledInput from './ControlledInput';

const CustomItems = ({ form, defaultValues }) => {
  const { t } = useTranslation();
  const [inputPairs, setInputPairs] = useState(defaultValues || []);

  const onInputChange = (index, key, newValue) =>
    setInputPairs((x) => {
      const newInputPairs = [...x];
      newInputPairs[index][key] = newValue;
      return newInputPairs;
    });

  return (
    <>
      <ControlledInput
        name="customFields"
        value={useMemo(() => inputPairs.filter((x) => x.title.length && x.value.length), [inputPairs])}
        form={form}
      />
      <div style={{ marginBottom: '10px' }}>
        <Button
          icon={<PlusOutlined />}
          type="secondary"
          onClick={() => setInputPairs([...inputPairs, { title: '', value: '' }])}
          style={{ marginRight: '10px' }}
        >
          {t('ADD_PAIR')}
        </Button>
        {inputPairs[0] && (
          <Button
            icon={<MinusOutlined />}
            type="secondary"
            onClick={() => inputPairs.length && setInputPairs((x) => x.slice(0, -1))}
            style={{ marginRight: '10px' }}
          >
            {t('REMOVE_LAST_PAIR')}
          </Button>
        )}
        {inputPairs.map((pair, index) => (
          <div style={{ marginTop: '10px', display: 'flex', alignItems: 'flex-start' }}>
            <Input
              placeholder={t('PAIR_TITLE_PLACEHOLDER')}
              value={pair.title}
              onChange={(e) => onInputChange(index, 'title', e.target.value)}
              style={{ marginBottom: '10px', width: '35%' }}
            />
            <Input.TextArea
              autoSize
              placeholder={t('PAIR_VALUE_PLACEHOLDER')}
              value={pair.value}
              onChange={(e) => onInputChange(index, 'value', e.target.value)}
              style={{ marginLeft: '1%', marginBottom: '10px', width: '64%' }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomItems;
