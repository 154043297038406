import { Button, Divider } from 'antd';
import {
  CheckCircleFilled,
  UserOutlined,
  GiftOutlined,
  EnvironmentOutlined,
  CloseCircleFilled,
  SafetyCertificateOutlined,
  BankOutlined,
  AuditOutlined,
  BookOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import shareholderApi from '../../../api/shareholder';
import ShareholderDetailsCardContent from './component/CardContent';
import ShareholderDetailsCardCustom from './component/CardCustom';
import { useSecondColor } from '../../../redux/settings/selectors';
import usePermission from '../../../hooks/usePermission';

const styles = {
  status: { flex: 1, color: 'white', padding: '10px 20px', borderRadius: 5, display: 'flex' },
};

const userPrefix = 'user';

const computeName = (x) =>
  (x.userData && `${x.userData.userlastname} ${x.userData.userfirstname}`) || x.companyData.name;

function AccountStatus({ active }) {
  const { t } = useTranslation();

  if (active)
    return (
      <div style={{ background: '#58CE96', ...styles.status }}>
        <CheckCircleFilled style={{ fontSize: 20, marginRight: 10 }} />
        <div>{t('USER_ACTIVE')}</div>
      </div>
    );

  return (
    <div style={{ background: '#f03434', ...styles.status }}>
      <CloseCircleFilled style={{ fontSize: 20, marginRight: 10 }} />
      <div>{t('USER_NOT_ACTIVE')}</div>
    </div>
  );
}

function ShareholderDetailsUserProfile({ company }) {
  const history = useHistory();
  const { shareholderId } = useParams();
  const { t } = useTranslation();
  const secondColor = useSecondColor();
  const type = usePermission(company?.id);

  const [{ userData, companyData, ownerid }, setProfile] = useState({});
  const [indivisionData, setIndivision] = useState(null);
  const [indivisionCustoms, setIndivisionCustoms] = useState(null);

  useEffect(() => {
    shareholderApi
      .getProfile(company.id, shareholderId)
      .then((x) => {
        if (x.indivisaires.length === 1 && computeName(x.indivisaires[0]) === x.indivision.name)
          setProfile(x.indivisaires[0]);
        else setIndivision(x.indivision);
        if (x.indivision?.customfields) setIndivisionCustoms(x.indivision?.customfields);
      })
      .catch(() => setProfile({}));
  }, [company, shareholderId]);

  return (
    <ShareholderDetailsCardCustom
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>{t('SHAREHOLDER_IDENTITY')}</div>
          {(type === 1 || type === 2) && (
            <Button
              size="small"
              style={{ color: secondColor, borderColor: secondColor }}
              onClick={() =>
                history.push({
                  pathname: `/shareholder/${shareholderId}/profile`,
                  state: {
                    [(userData && 'user') || (companyData && 'company') || (indivisionData && 'indivision')]:
                      Object.fromEntries(
                        Object.entries(userData || companyData || indivisionData).map((x) => [
                          x[0].startsWith(userPrefix) ? x[0].substring(userPrefix.length) : x[0],
                          x[1],
                        ])
                      ),
                    indivisionCustoms,
                    id: ownerid,
                  },
                })
              }
            >
              {t('MODIFY_USER')}
            </Button>
          )}
        </div>
      }
    >
      {userData && (
        <>
          <ShareholderDetailsCardContent
            icon={UserOutlined}
            label={t('SHAREHOLDER')}
            value={`${userData.userfirstname} ${userData.userlastname}`}
          />
          <ShareholderDetailsCardContent
            icon={GiftOutlined}
            label={t('BIRTHDAY_DATE')}
            value={userData.birthdate && new Date(userData.birthdate).toLocaleDateString()}
          />
          <ShareholderDetailsCardContent
            icon={EnvironmentOutlined}
            label={t('BIRTHDAY_CITY')}
            value={userData.birthcity}
          />
          {userData.customfields?.map((x) => (
            <ShareholderDetailsCardContent icon={IdcardOutlined} label={x.title} value={x.value} />
          ))}
          <AccountStatus active={userData.accountactive} />
        </>
      )}
      {companyData &&
        (() => {
          const cd = !Object.keys(companyData).length ? company : companyData;
          return (
            <>
              <ShareholderDetailsCardContent icon={BankOutlined} label={t('COMPANY_NAME')} value={`${cd.name}`} />
              <ShareholderDetailsCardContent
                icon={SafetyCertificateOutlined}
                label={t('COMPANY_SIREN')}
                value={`${cd.siren}`}
              />
            </>
          );
        })()}
      {indivisionData && (
        <>
          {!indivisionData && <Divider />}
          <ShareholderDetailsCardContent
            icon={AuditOutlined}
            label={t('INDIVISION_NAME')}
            value={`${indivisionData.name}`}
          />
          <ShareholderDetailsCardContent
            icon={BookOutlined}
            label={t('SHAREHOLDER_QUALITY')}
            value={`${indivisionData.quality || t('SHAREHOLDER_QUALITY_0')}`}
          />
        </>
      )}
      {/* <IndivisionCustomItems style={{ marginTop: '20px' }} indivisionCustoms={indivisionCustoms} /> */}
    </ShareholderDetailsCardCustom>
  );
}

export default ShareholderDetailsUserProfile;
