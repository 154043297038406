import { Layout } from 'antd';
import React, { useState } from 'react';
import LayoutMainHeader from './Header';
import LayoutMainSider from './Sider';

const { Content } = Layout;

const styles = {
  container: { minHeight: '100vh' },
};

function LayoutMain({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [lock, setLock] = useState(false);

  return (
    <Layout style={styles.container}>
      <LayoutMainHeader lock={lock} setLock={setLock} collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <LayoutMainSider lock={lock} collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          <Content style={{ padding: 24, margin: 0 }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default LayoutMain;
