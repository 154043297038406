import companyApi, { getShareholders } from '../../api/company';

export const SET_OPERATION_PARENT = 'SET_OPERATION_PARENT';
export const SET_OPERATION_CHILD = 'SET_OPERATION_CHILD';
export const OPEN_MODAL_CREATE_SHAREHOLDER = 'OPEN_MODAL_CREATE_SHAREHOLDER';
export const CLOSE_MODAL_CREATE_SHAREHOLDER = 'CLOSE_MODAL_CREATE_SHAREHOLDER';
export const SET_OPERATION_SHAREHOLDERS = 'SET_OPERATION_SHAREHOLDERS';
export const ADD_OPERATION_SHAREHOLDER = 'ADD_OPERATION_SHAREHOLDER';
export const SET_OPERATION_DATA = 'SET_OPERATION_DATA';
export const SET_OPERATION_CANCEL = 'SET_OPERATION_CANCEL';
export const SET_OPERATION_NEXT_STEP = 'SET_OPERATION_NEXT_STEP';
export const SET_OPERATION_PREVIOUS_STEP = 'SET_OPERATION_PREVIOUS_STEP';
export const ADD_SHAREHOLDER_SELECTED = 'ADD_SHAREHOLDER_SELECTED';
export const REMOVE_SHAREHOLDER_SELECTED = 'REMOVE_SHAREHOLDER_SELECTED';
export const CREATE_AND_SELECT_SHAREHOLDER = 'CREATE_AND_SELECT_SHAREHOLDER';
export const SET_OPERATION_SHAREHOLDERS_V2 = 'SET_OPERATION_SHAREHOLDERS_V2';
export const SET_OPERATIONS_LIST = 'SET_OPERATIONS_LIST';

export const setOperationParent = (operationParent) => ({ type: SET_OPERATION_PARENT, operationParent });
export const setOperationChild = (operationChild) => ({ type: SET_OPERATION_CHILD, operationChild });
export const openModalCreateShareholder = () => ({ type: OPEN_MODAL_CREATE_SHAREHOLDER });
export const closeModalCreateShareholder = () => ({ type: CLOSE_MODAL_CREATE_SHAREHOLDER });
export const setOperationShareholders = (shareholders) => ({ type: SET_OPERATION_SHAREHOLDERS, shareholders });
export const setOperationsList = (operationsList) => ({ type: SET_OPERATIONS_LIST, operationsList });

export const setOperationShareholdersV2 = (shareholders, queries) => ({
  type: SET_OPERATION_SHAREHOLDERS_V2,
  shareholders,
  queries,
});
export const addOperationShareholder = (shareholder) => ({ type: ADD_OPERATION_SHAREHOLDER, shareholder });
export const setOperationData = (data, description) => ({ type: SET_OPERATION_DATA, data, description });
export const setOperationCancel = () => ({ type: SET_OPERATION_CANCEL });
export const setOperationPreviousStep = () => ({ type: SET_OPERATION_PREVIOUS_STEP });
export const setOperationNextStep = () => ({ type: SET_OPERATION_NEXT_STEP });
export const addShareholderSelected = (shareholderId) => ({ type: ADD_SHAREHOLDER_SELECTED, shareholderId });
export const removeShareholderSelected = (shareholderId) => ({ type: REMOVE_SHAREHOLDER_SELECTED, shareholderId });
const createAndSelectShareholder = (shareholder) => ({ type: CREATE_AND_SELECT_SHAREHOLDER, shareholder });

export const fetchShareholders = () => async (dispatch, getState) => {
  try {
    const companyId = getState().company.id;
    if (!companyId) return;
    const shareholders = await getShareholders(companyId);
    dispatch(setOperationShareholders(shareholders));
  } catch (err) {
    dispatch(setOperationShareholders([]));
  }
};

export const createShareholder = (values, type) => async (dispatch, getState) => {
  const companyId = getState().company.id;
  if (!companyId) return;

  const shareholderCreated = await companyApi.createShareholder({
    companyId,
    quality: values.type,
    name: values.name || undefined,
    shareholders: values.indivisions
      ? values.indivisions.map((i) => ({ type, values: { id: i.id }, quota: Number(i.quota) }))
      : [{ values, type, quota: 100 }],
  });

  const shareholders = await getShareholders(companyId);
  const find = shareholders.find((s) => Number(s.id) === Number(shareholderCreated.indivisionid));
  if (!find) return;

  dispatch(createAndSelectShareholder(find));
  await dispatch(fetchShareholdersV2(getState()?.operations?.queries || {}));
};

export const fetchShareholdersV2 = (queries) => async (dispatch, getState) => {
  try {
    const companyId = getState().company?.id;
    if (!companyId) return;

    const shareholders = await getShareholders(companyId, queries);

    dispatch(setOperationShareholdersV2(shareholders, queries));
  } catch (err) {
    dispatch(setOperationShareholdersV2([], queries));
  }
};
