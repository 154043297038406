import React from 'react';
import useShareholdersLocal from '../../../../hooks/useShareholdersLocal';
import OperationsAccounts from '../components/v2/Accounts';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';
import OperationsShareholders from '../components/v2/Shareholders';

// Reconstitution de pleine propriété
function OperationsCreate5x2() {
  const holdersUsfruct = useShareholdersLocal('>', null, 2);
  const holdersBareOwnership = useShareholdersLocal('>', null, 3);
  const beneficiaries = useShareholdersLocal('>=', null, 1);

  return (
    <>
      <OperationsAccounts userType="holderUsfruct" shareholders={holdersUsfruct} />
      <OperationsAccounts userType="holderBareOwnership" shareholders={holdersBareOwnership} />
      <OperationsShareholders userType="beneficiary" shareholders={beneficiaries} canCreate />
      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate5x2;
