import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import App from './App';
import Loading from './components/Loading';
import store from './redux/store';

import './styles/main.less';
import './services/i18n';

TagManager.initialize({
  gtmId: 'GTM-KSF9C6T',
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
