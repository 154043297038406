import request from '../services/request';
import isValidId from '../containers/BlockExplorer/utils/isValidId';

export const getBlock = (id) => request(`/blockchain/block${isValidId(id) ? `/${id}` : ''}`);

const blockchainApi = {
  getBlock,
};

export default blockchainApi;
