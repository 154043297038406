import React from 'react';
import useShareholdersLocal from '../../../../hooks/useShareholdersLocal';
import OperationsAccounts from '../components/v2/Accounts';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';

// Réalisation de nantissement
function OperationsCreate3x3() {
  const holders = useShareholdersLocal('>', 2);
  const beneficiaries = useShareholdersLocal('>=');

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />
      <OperationsAccounts userType="beneficiary" shareholders={beneficiaries} canCreate />
      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate3x3;
