import React, { useState } from 'react';
import { Card } from 'antd';
import './index.css';
import hexToRGBA from '../../services/hexToRGBA';
import recipientOrSender from './utils/recipientOrSender';
import { useFourthColor } from '../../redux/settings/selectors';

const SingleMessage = ({ userId, data, convId, setConvId, onClick }) => {
  const color = useFourthColor();
  const [isReadLocal, setIsReadLocal] = useState(false);

  const isRead = isReadLocal || data.isread;

  return (
    <Card.Grid
      onClick={() => {
        setConvId(data.conversationid);
        setIsReadLocal(true);
        onClick();
      }}
      style={{
        width: '100%',
        cursor: data.conversationid !== convId && 'pointer',
        backgroundColor: data.conversationid === convId && hexToRGBA(color),
      }}
      hoverable={data.conversationid !== convId}
    >
      <Card.Meta
        description={<div style={{ fontWeight: !isRead && '900' }}>{data.title}</div>}
        title={
          <div style={{ color: isRead && 'grey', fontWeight: !isRead && '900' }}>{recipientOrSender(userId, data)}</div>
        }
      />
    </Card.Grid>
  );
};

export default SingleMessage;
