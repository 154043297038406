import { SET_LOGO, SET_PRIMARY_COLOR, SET_SECOND_COLOR, SET_THIRD_COLOR, SET_FOURTH_COLOR } from './actions';

const initState = {
  isLoading: false,
  styles: { primaryColor: '#FF4C4F', secondColor: '#242351', thirdColor: '#B2BEC3', fourthColor: '#F4F7FE' },
  logo: '/assets/images/logo.svg', // `${process.env.PUBLIC_URL}/assets/images/login.svg`
};

const settings = (state = initState, action) => {
  const vars = {
    '@primary-color': state.styles.primaryColor,
    '@second-color': state.styles.secondColor,
    '@third-color': state.styles.thirdColor,
    '@fourth-color': state.styles.fourthColor,
  };
  switch (action.type) {
    case SET_LOGO: {
      return { ...state, logo: action.logo };
    }
    case SET_PRIMARY_COLOR: {
      window.less.modifyVars({ ...vars, '@primary-color': action.color });
      return { ...state, styles: { ...state.styles, primaryColor: action.color } };
    }
    case SET_SECOND_COLOR: {
      window.less.modifyVars({ ...vars, '@second-color': action.color });
      return { ...state, styles: { ...state.styles, secondColor: action.color } };
    }
    case SET_THIRD_COLOR: {
      window.less.modifyVars({ ...vars, '@third-color': action.color });
      return { ...state, styles: { ...state.styles, thirdColor: action.color } };
    }
    case SET_FOURTH_COLOR: {
      window.less.modifyVars({ ...vars, '@fourth-color': action.color });
      return { ...state, styles: { ...state.styles, fourthColor: action.color } };
    }
    default:
      return state;
  }
};

export default settings;
