import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from './Form/context';
import { useOperationChild } from '../../../../redux/operation/selectors';

function OperationsComment() {
  const { t } = useTranslation();

  const { Tooltip } = useContext(FormContext);

  return (
    <Tooltip title={t(`OPERATIONS_${useOperationChild().id}_COMMENT_TOOLTIP`)}>
      <Form.Item
        label={t('OPERATIONS_COMMENT')}
        name="comments"
        rules={[{ max: 1020, message: t('OPERATIONS_COMMENT_MAXLENGTH') }]}
      >
        <TextArea placeholder={t('OPERATIONS_COMMENT_PLACEHOLDER')} />
      </Form.Item>
    </Tooltip>
  );
}

export default OperationsComment;
