import { Card, Table } from 'antd';
import { UpSquareOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../api/permission';
import { useCompany } from '../../../redux/company/selectors';
import usePermission from '../../../hooks/usePermission';
import RightsHoldersAdd from './Add';
import RightsHoldersTransfer from './Transfer';
import Delete from './Delete';

function RightsHolders() {
  const { t } = useTranslation();
  const company = useCompany();
  const type = usePermission(company?.id);

  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const fetchUsers = useCallback(() => {
    permissionApi
      .getPermissionHolders(company.id)
      .then((x) => x.filter((o) => o.accountactive === true))
      .then(setUsers)
      .catch(() => setUsers([]));
  }, [company]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, refresh]);

  return (
    <Card title={t('RIGHTS_HOLDERS_TITLE')} extra={<RightsHoldersAdd refresh={fetchUsers} />}>
      <Table
        dataSource={users}
        columns={[
          {
            title: t('CAN_READ'),
            render: (state) => (
              <span>
                <UpSquareOutlined
                  style={{
                    color: state.readRight === 'USER_PERMISSION_1' ? 'blue' : 'orange',
                    fontSize: 20,
                    marginRight: 10,
                  }}
                />
                {t(state.readRight)}
              </span>
            ),
          },
          { title: t('FIRSTNAME_AND_NAME'), render: (user) => `${user.firstname || ''} ${user.lastname || ''}` },
          { title: t('COMPANY_AND_JOB'), render: (user) => `${user.companyname || ''} - ${user.profession || ''}` },
          { title: t('USER_EMAIL'), dataIndex: 'email' },
          type === 1
            ? {
                title: t('ACTIONS'),
                render: (user) => (
                  <>
                    {user.readRight === 'USER_PERMISSION_1' && (
                      <RightsHoldersTransfer
                        admin={users.find((x) => x.readRight === 'USER_PERMISSION_1')}
                        refresh={() => setRefresh(!refresh)}
                      />
                    )}

                    {user.readRight !== 'USER_PERMISSION_1' && (
                      <Delete user={user} refresh={() => setRefresh(!refresh)} />
                    )}
                  </>
                ),
              }
            : {},
        ]}
      />
    </Card>
  );
}

export default RightsHolders;
