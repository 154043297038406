import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { openModalCreateShareholder } from '../../../redux/operation/actions';

const ShareholderCreate = ({ label }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Form.Item
      label={
        <Button type="dashed" icon={<PlusOutlined />} onClick={() => dispatch(openModalCreateShareholder())}>
          {label || t('OPERATIONS_SHAREHOLDERS_CREATE')}
        </Button>
      }
      colon={false}
    >
      <div />
    </Form.Item>
  );
};

export default ShareholderCreate;
