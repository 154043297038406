import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'fr',
    backend: {
      loadPath: `https://digistre-front-translation.s3.eu-west-3.amazonaws.com/{{lng}}/translations.json?date=${new Date().getTime()}`,
    },
  });

export default i18n;
