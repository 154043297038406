import { SET_USER } from './actions';

const initState = null;

const user = (state = initState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};

export default user;
