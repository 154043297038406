/* eslint-disable react/no-array-index-key */
import { DeleteOutlined, PercentageOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../../../../../../api/company';
import { useCompany } from '../../../../../../../redux/company/selectors';
import { closeModalCreateShareholder, createShareholder } from '../../../../../../../redux/operation/actions';
import ShareholderQuality from '../../Quality';

function MultipleIndivision() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const company = useCompany();
  const [users, setUsers] = useState([]);

  getUsers(company.id).then(setUsers);

  const [loading, setLoading] = useState(false);
  const [indivisions, setIndivisions] = useState([{}]);
  const [error, setError] = useState(null);

  const addIndivision = () => {
    setIndivisions((s) => [...s, {}]);
  };

  const removeIndivision = (index) => {
    setIndivisions((s) => s.filter((_, i) => i !== index));
  };

  const onFinish = async (values) => {
    setLoading(true);
    setError(null);

    try {
      await dispatch(createShareholder(values, 1));
      form.resetFields();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item label={t('INDIVISION_NAME')} name="name">
        <Input placeholder={t('INDIVISION_NAME_PLACEHOLDER')} />
      </Form.Item>

      <ShareholderQuality form={form} />

      <Divider />

      {indivisions.map((indivision, index) => (
        <div key={`indivision-create-${index}`}>
          <div className="dg-flex dg-justify-between" style={{ marginBottom: 16 }}>
            <Typography.Title level={5}>{`${t('UNDIVIDED')} ${index + 1}`}</Typography.Title>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => removeIndivision(index)}
              disabled={indivisions.length < 2}
              danger
            />
          </div>
          <Form.Item name={['indivisions', index, 'id']}>
            <Select
              placeholder={t('UNDIVIDED_PLACEHOLDER')}
              options={users.map((s) => ({ label: `${s.lastname} ${s.firstname}`, value: s.id }))}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label={t('UNDIVIDED_PART')} name={['indivisions', index, 'quota']}>
            <Input addonAfter={<PercentageOutlined />} />
          </Form.Item>

          <Divider />
        </div>
      ))}

      <Button type="primary" onClick={addIndivision}>
        {t('UNDIVIDED_ADD')}
      </Button>

      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon />
        </Form.Item>
      )}

      <Divider />

      <div className="dg-flex dg-justify-between">
        <Button onClick={() => dispatch(closeModalCreateShareholder())}>{t('COMMON_CANCEL')}</Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('UNDIVIDED_CREATE')}
        </Button>
      </div>
    </Form>
  );
}

export default MultipleIndivision;
