import { DatePicker, Form, InputNumber, Radio } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import OperationsComment from '../../components/v2/Comment';
import FormContext from '../../components/Form/context';
import OperationsShareholders from '../../components/v2/Shareholders';
import CompanyAccounts from '../../components/v2/CompanyAccounts';
import OperationDate from '../../components/Date';
import MaxShares from '../components/MaxShares';
import Name from '../components/Name';
import OptionTypes from '../components/OptionTypes';
import Shares from '../components/Shares';
import ShareTypes from '../components/ShareTypes';

// Attribution gratuite d'actions
function OperationsCreate6x1x4() {
  const { t } = useTranslation();
  const { Tooltip, form } = useContext(FormContext);

  const { id, shareType } = useOperationChild();
  const beneficiaries = useShareholdersLocal('>=');
  const holders = useShareholdersLocal('>', null, null, '1|2|3', 'company');

  return (
    <>
      <Name />
      <ShareTypes />
      <OperationDate />
      <OptionTypes />
      {(() => form?.getFieldsValue()?.options?.OPTION_TYPE === 'OPTION_TYPE_2')() && (
        <CompanyAccounts userType="holder" companyAccounts={holders[0]} customName="COMPANY_ACCOUNT" option />
      )}
      <Shares />
      <MaxShares required />

      <Title level={5}>{t('OPERATION_ATTRIBUTION_RADIO_TOOLTIP_TITLE')}</Title>
      <Tooltip title={t(`OPERATION_ATTRIBUTION_RADIO_${shareType}_TOOLTIP`)}>
        <Form.Item
          label={t('OPERATION_ATTRIBUTION_RADIO')}
          name="attribution"
          rules={[{ required: true, message: t('OPERATION_ATTRIBUTION_RADIO_REQUIRED') }]}
        >
          <Radio.Group
            options={[
              { label: t('COMMON_YES'), value: true },
              { label: t('COMMON_NO'), value: false },
            ]}
          />
        </Form.Item>
      </Tooltip>

      <Form.Item shouldUpdate={(prev, curr) => prev.attribution !== curr.attribution} noStyle>
        {({ getFieldValue }) =>
          getFieldValue('attribution') ? (
            <>
              <Tooltip title={t(`OPERATIONS_${id}_${shareType}_ATTRIBUTION_DATE`)}>
                <Form.Item
                  name="date"
                  label={t('OPERATION_ATTRIBUTION_DATE')}
                  rules={[{ required: true, message: t('OPERATION_ATTRIBUTION_DATE_REQUIRED') }]}
                  initialValue={moment()}
                  preserve={false}
                >
                  <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                </Form.Item>
              </Tooltip>

              <OperationsShareholders userType="beneficiary" shareholders={beneficiaries} canCreate />

              <Tooltip title={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE_TOOLTIP`)}>
                <Form.Item
                  label={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`)}
                  name={['options', `OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`]}
                  preserve={false}
                >
                  <InputNumber />
                </Form.Item>
              </Tooltip>
            </>
          ) : null
        }
      </Form.Item>

      <Title level={5}>{t(`OPERATIONS_${id}_${shareType}_DATE_1_TITLE`)}</Title>
      <Tooltip title={t(`OPERATIONS_${id}_${shareType}_DATE_1_TOOLTIP`)}>
        <Form.Item
          label={t(`OPERATIONS_${id}_${shareType}_DATE_1`)}
          name={['options', `OPERATIONS_${id}_${shareType}_DATE_1`]}
        >
          <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
        </Form.Item>
      </Tooltip>

      <Title level={5}>{t(`OPERATIONS_${id}_${shareType}_DATE_2_TITLE`)}</Title>
      <Tooltip title={t(`OPERATIONS_${id}_${shareType}_DATE_2_TOOLTIP`)}>
        <Form.Item
          label={t(`OPERATIONS_${id}_${shareType}_DATE_2`)}
          name={['options', `OPERATIONS_${id}_${shareType}_DATE_2`]}
        >
          <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
        </Form.Item>
      </Tooltip>

      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x1x4;
