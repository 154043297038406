import { getToken } from './token';
import setCompanyName from './setCompanyName';

const getUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'localhost':
      return 'http://127.0.0.1:8080/api';
    case 'development':
      if (!window?.location?.hostname?.includes('dev')) return 'https://api.digistre.com/api';
      return 'https://api.dev.digistre.com/api';
    default:
      return 'https://api.digistre.com/api';
  }
};

export const url = getUrl();

const request = async (path, method = 'GET', body, isFile, customUrl, noAuth, customHeaders) => {
  let headers = noAuth ? {} : { Authorization: `Bearer ${getToken()}` };
  if (customHeaders) headers = { ...headers, ...customHeaders };

  if (!isFile) headers = { ...headers, 'Content-Type': 'application/json', Accept: 'application/json' };

  const response = await fetch(`${customUrl || url}${path}`, {
    method,
    headers,
    body: isFile ? body : body && JSON.stringify(body),
  });
  const json = await response.json().catch(() => null);
  if (!response.ok) throw json;
  return setCompanyName(json.data || json);
};

function download(blob, name) {
  const blobUrl = window.URL.createObjectURL(blob);
  // window.open(blobUrl, `_blank`, 'noopener noreferrer');
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = blobUrl;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(blobUrl);
}

export const requestFile = async (path, name) => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };

  const response = await fetch(`${url}${path}`, {
    method: 'GET',
    headers,
  });

  const blob = await response.blob();
  return download(blob, name);
};

export const requestBlob = async (path, noAuth) => {
  const headers = noAuth ? {} : { Authorization: `Bearer ${getToken()}` };

  const response = await fetch(`${url}${path}`, {
    method: 'GET',
    headers,
  });

  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};

export default request;
