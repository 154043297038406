import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';

function ShareTypes() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();

  const shareTypes = [
    { label: t('SHARE_TYPE_1'), value: 1 },
    { label: t('SHARE_TYPE_2'), value: 2 },
  ];

  return (
    <Tooltip title={t(`OPERATION_${id}_${shareType}_SHARE_TYPE_TOOLTIP`)}>
      <Form.Item
        label={t(`SHARE_TYPE${id === 15 ? '_EM' : ''}`)}
        name={['options', 'SHARE_TYPE']}
        rules={[{ required: true, message: t('SHARE_TYPE_REQUIRED') }]}
      >
        <Select options={shareTypes} />
      </Form.Item>
    </Tooltip>
  );
}

export default ShareTypes;
