import { login } from '../../api/authentication';
import userApi from '../../api/users';
import { clearToken, setToken } from '../../services/token';

export const SET_USER = 'SET_USER';

const setUser = (user) => ({ type: SET_USER, payload: user });

export const signIn = (credentials) => async (dispatch) => {
  const { Authorization } = await login(credentials);
  const token = Authorization.split(' ')[1];
  setToken(token);
  return dispatch(fetchUser());
};

export const signOut = () => async (dispatch) => {
  clearToken();
  dispatch(setUser(null));
};

export const fetchUser = () => async (dispatch) => {
  try {
    const user = await userApi.getMe();
    return dispatch(setUser(user));
  } catch (err) {
    return dispatch(setUser(null));
  }
};
