import React from 'react';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import OperationsComment from '../../components/v2/Comment';
import Shares from '../components/Shares';
import OperationsAccounts6 from '../components/Accounts';
import OperationDate from '../../components/Date';

const holdersShareType = 9;

// Levée option de vente de promesse d'achat
function OperationsCreate6x3x7() {
  const beneficiaries = useShareholdersLocal('>', null, null, holdersShareType);
  const debtorHolders = useShareholdersLocal('>', null, null, '1|2|3');

  return (
    <>
      <OperationsAccounts6 userType="beneficiary" shareholders={beneficiaries} />

      <Shares />

      <OperationsAccounts6 userType="debtorHolder" shareholders={debtorHolders} />

      <OperationDate />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x3x7;
