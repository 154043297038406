/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import { BankOutlined, UserOutlined, EnvironmentOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Charts from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor } from '../../../redux/settings/selectors';
import ShareholderDetailsAccounts from './Accounts';
import ShareholderDetailsCardCustom from './component/CardCustom';
import ShareholderDetailsCardContent from './component/CardContent';
import ShareholderDetailsUserProfile from './UserProfile';
import shareholderApi from '../../../api/shareholder';

function BarCustom({ title, datasets, labels, color, id }) {
  return (
    <div>
      <div style={{ fontSize: '1.1em', marginBottom: 10 }}>{title}</div>
      <Charts
        options={{
          chart: {
            id,
            stacked: true,
            stackType: '100%',
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            categories: labels,
          },
          theme: {
            monochrome: {
              enabled: true,
              color,
              shadeTo: 'light',
              shadeIntensity: 0.7,
            },
          },
          tooltip: {
            x: {
              show: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
        }}
        series={datasets || []}
        type="bar"
        height={140}
      />
    </div>
  );
}

function ShareholderDetails() {
  const { t } = useTranslation();
  const { shareholderId } = useParams();

  const company = useCompany();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();

  const [stats1, setStats1] = useState();
  const [stats2, setStats2] = useState();

  useEffect(() => {
    shareholderApi.getStats(company.id, shareholderId).then(([a, b]) => {
      const s1 = a.fullyDiluted.map((f) => ({
        name: t(`SHARE_TYPE_${f.shareType}`),
        data: [Number(f.balance)],
      }));

      const s2 = Object.entries(b).map(([key, value]) => ({
        name: t(key),
        data: [value],
      }));

      setStats1(s1);
      setStats2(s2);
    });
  }, [company.id, primaryColor, shareholderId, t]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -8px 8px' }}>
          <ShareholderDetailsUserProfile company={company} />

          <ShareholderDetailsCardCustom title={t('COMPANY_IDENTITY')}>
            <ShareholderDetailsCardContent icon={BankOutlined} label={t('COMPANY_NAME')} value={company.name} />
            <ShareholderDetailsCardContent
              icon={SafetyCertificateOutlined}
              label={t('COMPANY_RCS')}
              value={company.rcs}
            />
            <ShareholderDetailsCardContent
              icon={EnvironmentOutlined}
              label={t('COMPANY_ADDRESS')}
              value={`${company.address}, ${company.postalcode} ${company.city}`}
            />
            <ShareholderDetailsCardContent
              icon={UserOutlined}
              label={t('COMPANY_USER_NAME')}
              value={company.othername || `${company.firstname} ${company.lastname}`}
              last
            />
          </ShareholderDetailsCardCustom>

          <ShareholderDetailsCardCustom title={t('SHARES_DISTRIBUTION')}>
            <BarCustom
              id={1}
              title={t('DISTRIBUTION_ACTIVES_FD')}
              datasets={stats1}
              labels={['']}
              color={primaryColor}
            />
            <BarCustom
              id={2}
              title={t('DISTRIBUTION_ACTIVES_ACCOUNT_TYPE')}
              datasets={stats2}
              labels={['']}
              color={secondColor}
            />
          </ShareholderDetailsCardCustom>
        </div>
      </div>

      <ShareholderDetailsAccounts />
    </>
  );
}

export default ShareholderDetails;
