import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Divider } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useLogo } from '../../redux/settings/selectors';

const Header = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const logo = useLogo();

  return (
    <Row>
      <Col span={8}>
        <img style={{ margin: '37px 0 0 37px' }} src={logo} alt="logo" width="210px" />
      </Col>
      <Col span={8}>
        <Row justify="center">
          <Button
            icon={<RollbackOutlined />}
            style={{ margin: '45px 0 0 0' }}
            type="dashed"
            size="large"
            onClick={() => history.push('/blockexplorer')}
          >
            {t(`BLOCK_EXPLORER_BACK`)}
          </Button>
        </Row>
      </Col>
      <Col span={8} />
      <Divider style={{ margin: '45px 0 0 0' }} />
    </Row>
  );
};

// back to main

export default Header;
