import { Alert, Button, Checkbox, Form, Input, Row, Typography, notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { signIn } from '../../redux/user/actions';

const { Text } = Typography;

function LoginForm() {
  const confirmedEmail = useRouteMatch('/login/confirmed/email');
  const confirmedPassword = useRouteMatch('/login/confirmed/password');

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [{ isLoading, error }, setState] = useState({ isLoading: false, error: null });

  const onFinish = async (values) => {
    setState({ isLoading: true, error: null });
    try {
      await dispatch(signIn(values));
    } catch (err) {
      if (err?.message !== 'LOGIN_ERROR') notification.error({ description: t(err?.message || 'UNKNOWN_ERROR') });
      setState({ isLoading: false, error: err?.message });
    }
  };

  return (
    <Form initialValues={{ remember: true }} size="large" layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t('USER_EMAIL')}
        name="email"
        rules={[{ type: 'email', required: true, message: t('USER_EMAIL_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={t('USER_PASSWORD')}
        name="password"
        rules={[{ required: true, message: t('USER_PASSWORD_MESSAGE_REQUIRED') }]}
      >
        <Input.Password placeholder={t('USER_PASSWORD_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item>
        <Row justify="space-between" align="middle">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>{t('LOGIN_STAY_CONNECTED')}</Checkbox>
          </Form.Item>

          <Button type="link" size="small" onClick={() => history.push('/password/forgot')}>
            {t('LOGIN_PASSWORD_FORGOT')}
          </Button>
        </Row>
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={t('LOGIN_ERROR')} type="error" showIcon />
        </Form.Item>
      )}

      {confirmedEmail && (
        <Form.Item>
          <Alert message={t('EMAIL_CONFIRMED')} type="success" showIcon />
        </Form.Item>
      )}

      {confirmedPassword && (
        <Form.Item>
          <Alert message={t('PASSWORD_CONFIRMED')} type="success" showIcon />
        </Form.Item>
      )}

      <Form.Item>
        <Row justify="center">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('LOGIN_BUTTON')}
          </Button>
        </Row>
      </Form.Item>

      {/* <Form.Item>
        <Row justify="center" align="middle">
          <Text>{t('LOGIN_NOT_REGISTER')}</Text>
          <Button type="link" size="small" onClick={() => history.push('/register')}>
            {t('LOGIN_REGISTER_MY_ACCOUNT')}
          </Button>
        </Row>
      </Form.Item> */}
    </Form>
  );
}

export default LoginForm;
