import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import { useLogo } from '../../redux/settings/selectors';

const styles = {
  main: {
    container: { height: '100vh' },
  },
  left: {
    container: { backgroundColor: '#F4F5F7' },
    flex: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      padding: '2%',
    },
    svg: {
      logo: { width: '30%' },
      login: { alignSelf: 'center' },
    },
  },
  right: {
    container: { marginTop: 24 },
  },
};

function LayoutAuthentication({ children, leftImageUrl, leftImageCss }) {
  const parentRef = useRef();
  const childRef = useRef();
  const logo = useLogo();

  const resize = useCallback((loop = false) => {
    if (!parentRef.current && !childRef.current) return;
    const { width } = parentRef.current.getBoundingClientRect();
    if (width > 0) childRef.current.style.width = `${width}px`;
    else if (loop) setTimeout(() => resize(true), 10);
  }, []);

  useEffect(() => {
    resize(true);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return (
    <Row style={styles.main.container}>
      <Col ref={parentRef} xs={0} xl={10} style={styles.left.container}>
        <div ref={childRef} style={styles.left.flex}>
          <img style={styles.left.svg.logo} src={logo} alt="digistre-logo" />
          <img style={{ ...styles.left.svg.login, leftImageCss }} src={leftImageUrl} alt="digistre-login" />
          <img style={{ ...styles.left.svg.logo, visibility: 'hidden' }} src={logo} alt="digistre-logo-hidden" />
        </div>
      </Col>
      <Col xs={24} xl={14} style={styles.right.container}>
        {children}
      </Col>
    </Row>
  );
}

export default LayoutAuthentication;
