import { Form, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';

function MaxShares({ required = false }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();

  return (
    <Tooltip title={t(`OPERATIONS_${id}_${shareType}_MAX_SHARES_TOOLTIP`)}>
      <Form.Item
        label={t(`OPERATIONS_${id}_${shareType}_MAX_SHARES`)}
        name={['options', `OPERATIONS_${id}_${shareType}_MAX_SHARES`]}
        rules={[{ required, message: t('OPERATIONS_MAX_SHARES_MESSAGE_REQUIRED') }]}
      >
        <InputNumber min={1} />
      </Form.Item>
    </Tooltip>
  );
}

export default MaxShares;
