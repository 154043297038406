import { Button, Modal, Skeleton, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BackwardOutlined } from '@ant-design/icons';
import { useCompany } from '../../../redux/company/selectors';
import usePermission from '../../../hooks/usePermission';
import companyApi from '../../../api/company';
import TitlePrimary from '../../../components/TitlePrimary';
import toBlockExplorer from '../../../components/toBlockExplorer';
import { requestFile } from '../../../services/request';
import ResumeDescriptionV2 from '../Create/Resume/DescriptionV2';
// eslint-disable-next-line import/no-cycle
import operations from '../../../redux/operation/operations';
import { setOperationChild, setOperationNextStep } from '../../../redux/operation/actions';
import Subscribe from '../../../components/Payment/Subscribe';

const operationTypeMap = Object.fromEntries(
  operations
    .flatMap((x) => x.children.map((c, i) => ({ ...c, p: x.id, i })))
    .map((x) => [x.id, { component: x.component, shareType: x.shareType, index: x.p, id: x.id }])
);

export default ({ selected, setSelected, loading, isDraft, refresh, isLowest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const company = useCompany();
  const permissionType = usePermission(company?.id);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscribeCompany, setSubscribeCompany] = useState(false);

  // eslint-disable-next-line consistent-return
  const draft = async (action) => {
    setButtonLoading(true);
    const plan = await companyApi.planStats(company.id);
    if (!plan.isAnchorAllowed) {
      setButtonLoading(false);
      return setSubscribeCompany(company);
    }
    try {
      await companyApi[action](selected.companyid, selected.ordernumber);
    } catch (e) {
      notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
    }
    setSelected(null);
    setButtonLoading(false);
    refresh();
  };

  const footer = isDraft ? (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={() => setSelected(null)} icon={<BackwardOutlined />}>
        {t('COMMON_BACK')}
      </Button>
      <Button
        onClick={() => {
          const o = operationTypeMap[selected.changenature];
          history.push(`/operations/${o.index}/create`);
          setTimeout(() => {
            dispatch(setOperationChild({ id: o.id, component: o.component, shareType: o.shareType }));
            dispatch(setOperationNextStep());
          }, 1);
        }}
        type="dashed"
      >
        {t('DRAFT_REDIRECT')}
      </Button>
      <Button onClick={() => draft('deleteDraft')} loading={buttonLoading} type="primary">
        {t('DRAFT_DELETE')}
      </Button>
      <Button onClick={() => draft('anchorDraft')} loading={buttonLoading} disabled={!isLowest} type="primary">
        {t('DRAFT_ANCHOR')}
      </Button>
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={() => setSelected(null)} icon={<BackwardOutlined />}>
        {t('COMMON_BACK')}
      </Button>
      <Button onClick={() => toBlockExplorer(selected)}>{t('BLOCK_EXPLORER_ACCESS')}</Button>
      <Button
        onClick={() => {
          if (permissionType === 1 || permissionType === 2)
            requestFile(
              `/company/${company.id}/extract/operation?opid=${selected.ordernumber}&op=${JSON.stringify(
                selected.resume
              )}`,
              'opération.pdf'
            );
          else
            history.push({
              pathname: '/message',
              state: { isFileRequest: true },
            });
        }}
        type="primary"
      >
        {t(permissionType === 1 || permissionType === 2 ? 'EXPORT' : 'EXPORT_REQUEST')}
      </Button>
    </div>
  );

  return (
    <Modal
      title={<TitlePrimary title={t(`OPERATIONS_${selected.changenature}_SHORT_LABEL`)} />}
      visible
      footer={footer}
      onCancel={() => setSelected(null)}
      width={800}
    >
      {loading ? (
        <Skeleton />
      ) : (
        Array.isArray(selected.resume) && selected.resume.map((d) => <ResumeDescriptionV2 description={d} />)
      )}
      <Subscribe refresh={() => {}} company={subscribeCompany} resetCompany={setSubscribeCompany} singleTimeFormula />
    </Modal>
  );
};
