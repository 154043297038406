import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Button, Modal, Upload, Form, DatePicker, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CloudUploadOutlined } from '@ant-design/icons';
import { postDecision } from '../../api/decision';
import { useCompany } from '../../redux/company/selectors';
import SelectIsDraft from '../../components/SelectIsDraft';
import companyApi from '../../api/company';
import onFileUpload from '../../services/onFileUpload';
import Subscribe from '../../components/Payment/Subscribe';

const DecisionAddModal = ({ registerId, isVisible, hide }) => {
  const { t } = useTranslation();

  const company = useCompany();
  const [values, setValues] = useState(null);
  const [users, setUsers] = useState([]);
  const [isDraft, setIsDraft] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscribeCompany, setSubscribeCompany] = useState(false);

  const onEnd = () => {
    hide();
    setValues(null);
  };

  return (
    <Modal
      title={t('DECISION_ADD_MODAL')}
      visible={isVisible}
      onCancel={onEnd}
      footer={
        values && (
          <Button
            type="primary"
            // eslint-disable-next-line consistent-return
            onClick={async () => {
              setLoading(true);
              if (!isDraft) {
                const plan = await companyApi.planStats(company.id);
                if (!plan.isAnchorAllowed) {
                  setLoading(false);
                  return setSubscribeCompany(company);
                }
              }
              if (isDraft && selectedUsers.length)
                companyApi.notify(
                  company.id,
                  selectedUsers.map((id) => ({ id, email: users.find((x) => x.userid === id).email })),
                  'decision'
                );
              try {
                const fileId = await companyApi.uploadFile(company.id, file, 'decision').then((x) => x.file);
                await postDecision(company.id, { ...values, isDraft, registerId, fileId, fileName: file.name }).then(
                  onEnd
                );
              } catch (e) {
                notification.error({ description: t(e?.message || 'FILE_TOO_LARGE') });
              }
              setLoading(false);
            }}
            loading={loading}
          >
            {t('VALIDATE')}
          </Button>
        )
      }
      width="35vw"
    >
      {!values ? (
        <div>
          <div style={{ textAlign: 'center' }}>{t('DECISION_ADD_MODAL_TEXT')}</div>
          <br />
          <Form onFinish={(v) => setValues(v)}>
            <Row justify="center">
              <Form.Item
                label={t('DECISION_DATE')}
                name="date"
                rules={[{ required: true, message: t('DECISION_DATE_MESSAGE_REQUIRED') }]}
              >
                <DatePicker style={{ width: '100%', minWidth: 150 }} format="DD/MM/YYYY" />
              </Form.Item>
            </Row>
            <Row justify="center">
              <Form.Item
                label={t('DECISION_COMMENT')}
                name="comment"
                rules={[
                  { max: 1020, message: t('DECISION_COMMENT_MAXLENGTH') },
                  { required: true, message: t('DECISION_COMMENT_REQUIRED') },
                ]}
              >
                <TextArea placeholder={t('DECISION_COMMENT_PLACEHOLDER')} />
              </Form.Item>
            </Row>
            <Row justify="center">
              <Form.Item
                label={t('DECISION_FILE')}
                name="file"
                rules={[{ required: true, message: t('DECISION_FILE_REQUIRED') }]}
              >
                <Upload maxCount={1} customRequest={(o) => onFileUpload(o, setFile, notification, t)}>
                  <Button size="middle" icon={<CloudUploadOutlined />}>
                    {t(`UPLOAD_DOCUMENT`)}
                  </Button>
                </Upload>
              </Form.Item>
            </Row>
            <Button type="primary" htmlType="submit">
              {t('COMMON_NEXT')}
            </Button>
          </Form>
        </div>
      ) : (
        <SelectIsDraft
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          users={users}
          setUsers={setUsers}
        />
      )}
      <Subscribe refresh={() => {}} company={subscribeCompany} resetCompany={setSubscribeCompany} singleTimeFormula />
    </Modal>
  );
};
export default DecisionAddModal;
