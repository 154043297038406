import React from 'react';
import { useHistory } from 'react-router-dom';

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

export default (props) => {
  const history = useHistory();

  return (
    <div
      onClick={(e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        history.push(e.target.pathname);
      }}
      dangerouslySetInnerHTML={{ __html: props }}
    />
  );
};
