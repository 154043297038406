import { TeamOutlined, DatabaseOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../api/permission';
import StatsBlockLine from '../../../components/stats/BlockLine';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor, useThirdColor } from '../../../redux/settings/selectors';
import RightsExternal from './External/External';
import RightsShareholders from './Shareholders/Shareholders';

function RightsUsers() {
  const { t } = useTranslation();

  const company = useCompany();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const thirdColor = useThirdColor();

  const [stats, setStats] = useState();

  useEffect(() => {
    permissionApi.getStats(company.id).then(setStats);
  }, [company.id]);

  return (
    <>
      <Col span={24}>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -8px 8px' }}>
          <StatsBlockLine
            backgroundColor={primaryColor}
            icon={TeamOutlined}
            label={t('SHAREHOLDERS_COUNT')}
            value={stats?.shareholdersTotal || 0}
          />

          <StatsBlockLine
            backgroundColor={secondColor}
            icon={DatabaseOutlined}
            label={t('RIGHTS_SHAREHOLDER_ACTIVES_TOTAL')}
            value={stats?.activeShareholderUsers || 0}
          />

          <StatsBlockLine
            backgroundColor={thirdColor}
            icon={UserSwitchOutlined}
            label={t('RIGHTS_USER_EXTERNES_TOTAL')}
            value={stats?.activeExternalUsers || 0}
          />
        </div>
      </Col>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <RightsShareholders />
        </Col>

        <Col span={24}>
          <RightsExternal />
        </Col>

        {/* <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} lg={12}>
              <StatsPie title={t('STATS_SHAREHOLDERS')} />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <StatsPie title={t('SHARES_DISTRIBUTION')} />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
}

export default RightsUsers;
