import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, BankOutlined, TeamOutlined } from '@ant-design/icons';
import companyApi from '../../../api/company';
import { useUserId } from '../../../redux/user/selectors';
import Stats from '../../../components/Stats';

function CompaniesStats() {
  const { t } = useTranslation();
  const userId = useUserId();

  const [stats, setStats] = useState({});

  useEffect(() => {
    companyApi
      .getStats(userId)
      .then(setStats)
      .catch(() => setStats({}));
  }, [userId]);

  if (JSON.stringify(stats) === '{}') return <div />;

  return (
    <Stats
      stats={[
        { value: stats.accountsActiveTotal, label: t('ACCOUNTS_ACTIVES_TOTAL'), icon: CheckCircleOutlined },
        { value: stats.companiesTotal, label: t('COMPANIES_FOLLOW_TOTAL'), icon: BankOutlined },
        { value: stats.shareholdersFollowedTotal, label: t('SHAREHOLDERS_FOLLOW_TOTAL'), icon: TeamOutlined },
      ]}
    />
  );
}

export default CompaniesStats;
