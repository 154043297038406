import { Card, notification, Row, Col, Button, Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { usePrimaryColor, useSecondColor } from '../../redux/settings/selectors';
import GenericModal from '../GenericModal';
import Payment from './Payment';
import companyApi from '../../api/company';

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV !== 'production'
    ? 'pk_test_51MlFrJIIPTtwTluAh2myzPhNGEnU1n02htQ8M4JGAwiIGkfZnxoqbufcxdf9vdmnQPIk7O5gvMjFp5blWjFZhfg400M8NYd80j'
    : 'pk_live_51MlFrJIIPTtwTluAmsj071zU8MwbepqO7nahb3QRtCYfcAjoSAF7SB1heP602n5jnaeGLu2Tu6cJvGwGgpViYISO006D9wvK8h'
);

const formula = ['start', 'corporate', 'custody'];

// modal is visible if company is not falsy (null, false, etc.)
// company should have 'id' and 'name' properties.
// resetCompany acts as making company falsy.

function Subscribe({ refresh, company, resetCompany, singleTimeFormula }) {
  const setVisible = resetCompany;
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(false);

  const validate = async (e, type) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const r = await companyApi.paymentIntent(company.id, type || 'custody');
      setPayment(r);
    } catch (err) {
      notification.error({ description: t(err?.message || 'UNKNOWN_ERROR') });
      setVisible(false);
      setLoading(false);
    }
    await refresh();
  };

  return (
    <GenericModal
      onFooterClick={!payment && !singleTimeFormula && validate}
      actionTitle={t('SUBSCRIBE')}
      mainTitle={`${t(`COMPANY_SUBSCRIBE${singleTimeFormula ? '_FORMULA' : ''}`)} "${company.name}"`}
      visible={!!company}
      setVisible={setVisible}
      loading={loading}
      width={singleTimeFormula ? '50vw' : '40vw'}
    >
      {payment ? (
        <Elements stripe={stripePromise}>
          <Payment
            clientSecret={payment.clientSecret}
            paymentIntentId={payment.paymentIntentId}
            reload={() => {
              refresh();
              setPayment(null);
              setVisible(false);
              setLoading(false);
            }}
            companyId={company.id}
            amount={Math.ceil(payment.amount / 100)}
            subscriptionType={payment.metadata.subscriptionType}
          />
        </Elements>
      ) : (
        <>
          {singleTimeFormula ? (
            <>
              <div style={{ textAlign: 'left', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_3')}</div>
              <div style={{ textAlign: 'left', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_4')}</div>
              <Row justify="space-around">
                <Col>
                  <Card style={{ borderColor: secondColor, borderWidth: '2px' }}>
                    <div style={{ textAlign: 'left', margin: '15px', color: primaryColor, fontSize: 'larger' }}>
                      {t('COMPANY_SUBSCRIBE_FORMULA_1')}
                    </div>
                    <div style={{ textAlign: 'left', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_5')}</div>
                    <div style={{ textAlign: 'center', margin: '15px', fontWeight: 'bold' }}>
                      {t('COMPANY_SUBSCRIBE_PRICE_FORMULA_1')}
                    </div>
                    <div style={{ textAlign: 'center', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_FORMULA_1')}</div>
                    <div
                      style={{
                        textAlign: 'left',
                        margin: '15px',
                        color: 'grey',
                        fontStyle: 'italic',
                        fontSize: 'smaller',
                      }}
                    >
                      {t('COMPANY_SUBSCRIBE_LONG_FORMULA_1')}
                    </div>
                    <Button block type="primary" onClick={(e) => validate(e, formula[0])}>
                      {t('PAY_NOW_FORMULA')}
                    </Button>
                  </Card>
                </Col>
                <Divider />
                <Col>
                  <Card style={{ borderColor: secondColor, borderWidth: '2px' }}>
                    <div style={{ textAlign: 'left', margin: '15px', color: primaryColor, fontSize: 'larger' }}>
                      {t('COMPANY_SUBSCRIBE_FORMULA_2')}
                    </div>
                    <div style={{ textAlign: 'left', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_5_BIS')}</div>
                    <div style={{ textAlign: 'center', margin: '15px', fontWeight: 'bold' }}>
                      {t('COMPANY_SUBSCRIBE_PRICE_FORMULA_2')}
                    </div>
                    <div style={{ textAlign: 'center', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_FORMULA_2')}</div>
                    <div
                      style={{
                        textAlign: 'left',
                        margin: '15px',
                        color: 'grey',
                        fontStyle: 'italic',
                        fontSize: 'smaller',
                      }}
                    >
                      {t('COMPANY_SUBSCRIBE_LONG_FORMULA_2')}
                    </div>
                    <Button block type="primary" onClick={(e) => validate(e, formula[1])}>
                      {t('PAY_NOW_FORMULA')}
                    </Button>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div style={{ textAlign: 'left', margin: '15px', color: primaryColor, fontSize: 'larger' }}>
                {t('COMPANY_SUBSCRIBE_TITLE')}
              </div>
              <div style={{ textAlign: 'left', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_1')}</div>
              <Card>
                <div style={{ textAlign: 'left', color: primaryColor, fontWeight: 'bold', fontSize: 'larger' }}>
                  {t('COMPANY_SUBSCRIBE_1')}
                </div>
                <div style={{ textAlign: 'left', color: 'grey', fontStyle: 'italic', fontSize: 'smaller' }}>
                  {t('COMPANY_SUBSCRIBE_LONG_DESC')}
                </div>
                <div style={{ textAlign: 'center', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_PRICE')}</div>
                <div style={{ textAlign: 'center', margin: '15px' }}>{t('COMPANY_SUBSCRIBE_DESC_2')}</div>
                <a target="_blank" rel="noopener noreferrer" href={t('COMPANY_SUBSCRIBE_LINK')}>
                  <div style={{ textAlign: 'center', margin: '15px' }}> {t('COMPANY_SUBSCRIBE_LINK_DESC')}</div>
                </a>
              </Card>
            </>
          )}
        </>
      )}
    </GenericModal>
  );
}

export default Subscribe;
