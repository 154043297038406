import { Divider, Drawer, Form, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModalCreateShareholder } from '../../../../../../redux/operation/actions';
import { useShowModalCreateShareholder } from '../../../../../../redux/operation/selectors';
import LegalForm1 from './LegalForm1';
import LegalForm2 from './LegalForm2';

function CreateShareHolder() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showModal = useShowModalCreateShareholder();

  const [legalForm, setLegalForm] = useState(1);

  return (
    <Drawer
      title={t('OPERATIONS_SHAREHOLDERS_CREATE')}
      placement="right"
      width={720}
      onClose={() => dispatch(closeModalCreateShareholder())}
      visible={showModal}
      closable
    >
      <Form.Item label={t('OPERATIONS_LEGAL_FORM')} labelCol={{ span: 24 }}>
        <Select
          options={[
            { label: t('OPERATIONS_LEGAL_FORM_1'), value: 1 },
            { label: t('OPERATIONS_LEGAL_FORM_2'), value: 2 },
          ]}
          value={legalForm}
          onChange={setLegalForm}
        />
      </Form.Item>

      <Divider />

      {legalForm === 1 && <LegalForm1 />}
      {legalForm === 2 && <LegalForm2 />}
    </Drawer>
  );
}

export default CreateShareHolder;
