import 'moment/locale/fr';
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfigProvider, Card } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { useTour } from '@reactour/tour';
import Login from './containers/Login';
import PasswordForgot from './containers/Password/Forgot';
import RegisterConfirmation from './containers/Register/Confirmation';
import PasswordConfirmation from './containers/Password/Confirmation';
import Register from './containers/Register';
import OperationsList from './containers/Operations/List';
import Translations from './containers/Admin/Translations';
import Admin from './containers/Admin';
import { fetchUser } from './redux/user/actions';
import { useUser } from './redux/user/selectors';
import Loading from './components/Loading';
import OperationsCreate from './containers/Operations/Create';
import LayoutMain from './components/layout/Main';
import CompaniesList from './containers/Companies/List';
import { useCompany } from './redux/company/selectors';
import { fetchSelectedCompany } from './redux/company/actions';
import { fetchStyle } from './redux/settings/actions';
import AccountsList from './containers/Accounts/List';
import CompanyDetails from './containers/Company/Details';
import ShareholderDetails from './containers/Shareholder/Details';
import RightsUsers from './containers/Rights/Users';
import RightsHolders from './containers/Rights/Holders';
import LegalActs from './containers/LegalActs';
import DecisionRegister from './containers/DecisionRegister';
import DecisionRegisterSingle from './containers/DecisionRegister/RegisterSingle';
import News from './containers/News';
import Profile from './containers/Profile';
import ShareholderProfile from './containers/Shareholder/ShareholderProfile';
import { clearToken, decodeToken, setToken } from './services/token';
import authenticationApi from './api/authentication';
import { getPermissionUser } from './api/permission';
import PasswordReset from './containers/Password/Reset';
import FirstConnection from './containers/FirstConnection';
import Message from './containers/Message';
import BlockExplorer from './containers/BlockExplorer';
import UserTour from './containers/UserTour';

const shouldReload = (pathname) => {
  const list = [
    '/',
    '/login',
    '/login/confirmed/email',
    '/login/confirmed/password',
    '/password/forgot',
    '/password/reset',
    '/password/confirmation',
    '/register/confirmation',
    '/register',
    '/blockexplorer',
  ];
  for (let i = list.length - 1; i >= 0; i -= 1) {
    if (pathname.includes(list[i])) return false;
  }
  return true;
};

function Router() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { setIsOpen } = useTour();

  const user = useUser();
  const company = useCompany();
  const companyId = localStorage.getItem('company');

  const [loading, setLoading] = useState(true);

  const init = useCallback(async () => {
    setLoading(true);

    if (window.location.host) await dispatch(fetchStyle(window.location.host));

    const passwordBase64 = new URLSearchParams(location.search).get('password');
    if (passwordBase64) {
      const token = decodeURIComponent(escape(window.atob(passwordBase64)));
      setToken(token);
      return history.push('/password/reset');
    }

    const invitationBase64 = new URLSearchParams(location.search).get('invitation');
    if (invitationBase64) {
      const token = decodeURIComponent(escape(window.atob(invitationBase64)));
      setToken(token);
      const { email, inactive } = decodeToken();

      if (inactive) {
        history.push('/password/reset');
      } else
        await authenticationApi
          .confirm({ email })
          .then(() => {
            clearToken();
            history.push('/login/confirmed/email');
          })
          .catch(() => null);
      return null;
    }
    await dispatch(fetchUser());

    if (companyId) await dispatch(fetchSelectedCompany(companyId));

    return setLoading(false);
  }, [dispatch, location.search, history, companyId]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (user && !user.subscribed) {
      getPermissionUser()
        .then((x) => {
          if (x.length === 0) {
            history.push('/');
            setIsOpen(true);
          }
        })
        .catch(() => {});
    }
  }, [history, user, setIsOpen]);

  useEffect(() => {
    if (!user && shouldReload(location.pathname)) history.push('/login');
  }, [history, user, location.pathname]);

  if (loading) return <Loading />;

  return (
    <Switch>
      {user && !location.pathname.includes('/blockexplorer') ? (
        <LayoutMain>
          <Switch>
            <Route path="/admin/translations">
              <Translations />
            </Route>

            <Route path="/admin">
              <Admin />
            </Route>

            <Route path="/subscribe">
              <FirstConnection />
            </Route>

            <Route path="/companies">
              <CompaniesList />
            </Route>
            <Route path="/company/:companyId">
              <CompanyDetails />
            </Route>

            {!company && <Redirect to="/companies" />}

            <Route path="/operations/:operationId/create">
              <OperationsCreate />
            </Route>
            <Route path="/operations">
              <OperationsList />
            </Route>

            <Route path="/accounts">
              <AccountsList />
            </Route>

            <Route path="/shareholder/:shareholderId/profile">
              <Card>
                <ShareholderProfile />
              </Card>
            </Route>
            <Route path="/shareholder/:shareholderId">
              <ShareholderDetails />
            </Route>

            <Route path="/rights/users">
              <RightsUsers />
            </Route>
            <Route path="/rights/holders">
              <RightsHolders />
            </Route>

            <Route path="/documentation/legal-acts">
              <LegalActs />
            </Route>
            <Route path="/documentation/news">
              <News />
            </Route>
            <Route path="/documentation/decision-register/:id">
              <DecisionRegisterSingle />
            </Route>
            <Route path="/documentation/decision-register">
              <DecisionRegister />
            </Route>

            <Route path="/profile">
              <Profile />
            </Route>

            <Route path="/message">
              <Message />
            </Route>

            <Redirect to={companyId ? `/company/${companyId}` : 'companies'} />
          </Switch>
        </LayoutMain>
      ) : (
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/password/forgot">
            <PasswordForgot />
          </Route>
          <Route path="/password/reset">
            <PasswordReset />
          </Route>
          <Route path="/password/confirmation">
            <PasswordConfirmation />
          </Route>
          {/* <Route path="/register/confirmation">
            <RegisterConfirmation />
          </Route> */}
          {/* <Route path="/register">
            <Register />
          </Route> */}
          <Route path="/blockexplorer">
            <BlockExplorer />
          </Route>

          <Redirect to="/login" />
        </Switch>
      )}
    </Switch>
  );
}

function App() {
  return (
    <ConfigProvider locale={frFR}>
      <BrowserRouter>
        <UserTour>
          <Router />
        </UserTour>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
