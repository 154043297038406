import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePrimaryColor } from '../redux/settings/selectors';

function GenericModal({ onFooterClick, actionTitle, mainTitle, visible, setVisible, loading, children, width }) {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  const hide = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const title = () => <div style={{ textAlign: 'center', color: primaryColor }}>{mainTitle}</div>;

  const footer = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>
      <Button type="primary" onClick={onFooterClick} loading={loading}>
        {actionTitle}
      </Button>
    </div>
  );

  return (
    <>
      <Modal title={title()} visible={visible} onCancel={hide} footer={onFooterClick && footer()} width={width}>
        {children}
      </Modal>
    </>
  );
}

export default GenericModal;
