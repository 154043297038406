import { Form, Input } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from '../Form/context';

const { TextArea } = Input;

function OperationsComment() {
  const { t } = useTranslation();

  const { Tooltip } = useContext(FormContext);

  return (
    <Tooltip title={t('OPERATIONS_COMMENT_TOOLTIP')}>
      <Form.Item
        label={t('OPERATIONS_COMMENT')}
        name="comment"
        rules={[{ max: 1020, message: t('OPERATIONS_COMMENT_MAXLENGTH') }]}
      >
        <TextArea placeholder={t('OPERATIONS_COMMENT_PLACEHOLDER')} />
      </Form.Item>
    </Tooltip>
  );
}

export default OperationsComment;
