import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutAuthentication from '../../../components/layout/Authentication';
import { useGreenColor } from '../../../redux/settings/selectors';

const { Title } = Typography;

const styles = {
  right: {
    container: { height: '100%' },
  },
  title: { textAlign: 'center', marginBottom: '10%' },
};

function PasswordConfirmation() {
  const { t } = useTranslation();
  const green = useGreenColor();

  return (
    <LayoutAuthentication
      leftImageUrl={`${process.env.PUBLIC_URL}/assets/images/password_forgot.svg`}
      leftImageCss={{ width: '70%' }}
    >
      <Row align="middle" style={styles.right.container}>
        <Col xs={{ span: 20, offset: 2 }}>
          <div style={styles.title}>
            <CheckCircleFilled style={{ fontSize: '3.5em', color: green, marginBottom: '4%' }} />
            <Title level={1} style={{ color: green }}>
              {t('CONFIRMATION_PASSWORD_FORGOT_TITLE')}
            </Title>
            <Title level={5}>{t('CONFIRMATION_PASSWORD_FORGOT_DESCRIPTION')}</Title>
          </div>
        </Col>
      </Row>
    </LayoutAuthentication>
  );
}

export default PasswordConfirmation;
