import request from '../services/request';

export const login = (credentials) => request('/auth/login', 'POST', credentials);
const signUp = (user) => request('/auth/direct/signup', 'POST', user);
const inactiveSignUp = (user) => request('/auth/inactive/signup', 'POST', user);
const confirm = (data) => request('/auth/direct/confirm', 'POST', data);
const passwordForgot = (data) => request('/password/forgot', 'POST', data);
const passwordReset = (data) => request('/password/reset', 'POST', data);

const authenticationApi = {
  login,
  signUp,
  inactiveSignUp,
  confirm,
  passwordForgot,
  passwordReset,
};

export default authenticationApi;
