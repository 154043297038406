import { Divider, Form, Input, Typography, Col, Row, Radio, Select, Alert, Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api/company';
import Countries from './Countries';
import companyTypes from '../services/companyTypes';
import { useCompany } from '../redux/company/selectors';

const { Title } = Typography;

const labels = {
  companypostalcode: 'postalcode',
  companycountry: 'country',
  companyaddress: 'address',
  companycity: 'city',
};

function UpsertCompany({ refresh, mode, otherCompany, hide }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const c = useCompany();
  const company =
    mode === 'update' &&
    Object.fromEntries(Object.entries(otherCompany || c || {}).map((x) => [labels[x[0]] || x[0], x[1]]));

  const label = mode === 'update' ? t('EDIT_COMPANY') : t('ADD_COMPANY');

  const [error, setError] = useState(null);
  const [legalForm, setLegalForm] = useState((mode === 'update' && (company?.othername ? '2' : '1')) || null);

  const onFinish = async (values) => {
    try {
      setError(null);
      if (hide) hide();
      await (mode === 'update' ? api.updateCompany(company.id, values) : api.createCompany(values));
      form.resetFields();
      await refresh();
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Title level={5} style={{ marginBottom: 10 }}>
        {t('THE_COMPANY')}
      </Title>

      <Form.Item
        label={t('COMPANY_TYPE_LABEL')}
        name="companyTypeDetail"
        rules={[{ required: mode === 'create', message: t('COMPANY_TYPE_LABEL_REQUIRED') }]}
      >
        <Select
          options={companyTypes.map((m) => ({ label: m, value: m }))}
          defaultValue={mode === 'update' ? company?.companytypedetail : null}
        />
      </Form.Item>

      <Form.Item
        label={t('COMPANY_NAME')}
        name="name"
        rules={[{ required: mode === 'create', message: t('COMPANY_NAME_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('COMPANY_NAME_PLACEHOLDER')} defaultValue={mode === 'update' ? company?.name : null} />
      </Form.Item>

      <Form.Item
        label={t('COMPANY_SIREN_OR_IMMATRICULATION')}
        name="siren"
        rules={[{ required: mode === 'create', message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('COMPANY_SIREN_PLACEHOLDER')} defaultValue={mode === 'update' ? company?.siren : null} />
      </Form.Item>

      <Form.Item
        label={t('COMPANY_RCS')}
        name="rcs"
        rules={[{ required: mode === 'create', message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('COMPANY_RCS_PLACEHOLDER')} defaultValue={mode === 'update' ? company?.rcs : null} />
      </Form.Item>

      <Form.Item label={t('COMPANY_APE')} name="ape">
        <Input placeholder={t('COMPANY_APE_PLACEHOLDER')} defaultValue={mode === 'update' ? company?.ape : null} />
      </Form.Item>

      <Form.Item
        label={t('COMPANY_ADDRESS')}
        name="address"
        rules={[{ required: mode === 'create', message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
      >
        <Input
          placeholder={t('COMPANY_ADDRESS_PLACEHOLDER')}
          defaultValue={mode === 'update' ? company?.address : null}
        />
      </Form.Item>

      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item
            label={t('COMPANY_ZIP')}
            name="postalCode"
            rules={[{ required: mode === 'create', message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
          >
            <Input
              placeholder={t('COMPANY_ZIP_PLACEHOLDER')}
              defaultValue={mode === 'update' ? company?.postalcode : null}
            />
          </Form.Item>
        </Col>

        <Col sm={{ span: 14, offset: 2 }}>
          <Form.Item
            label={t('COMPANY_CITY')}
            name="city"
            rules={[{ required: mode === 'create', message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
          >
            <Input
              placeholder={t('COMPANY_CITY_PLACEHOLDER')}
              defaultValue={mode === 'update' ? company?.city : null}
            />
          </Form.Item>
        </Col>
      </Row>

      <Countries defaultValue={mode === 'update' ? company?.country : null} />

      <Divider />

      <Title level={5} style={{ marginBottom: 10 }}>
        {t('COMPANY_USER_NAME')}
      </Title>

      <Radio.Group
        defaultValue={legalForm || '1'}
        style={{ marginBottom: 10 }}
        onChange={(x) => setLegalForm(x.target.value)}
      >
        <Radio.Button value="1">{t('OPERATIONS_LEGAL_FORM_1')}</Radio.Button>
        <Radio.Button value="2">{t('OPERATIONS_LEGAL_FORM_2')}</Radio.Button>
      </Radio.Group>

      {legalForm === '2' ? (
        <Form.Item
          label={t('COMPANY_NAME')}
          name="othername"
          rules={[{ required: mode === 'create', message: t('COMPANY_NAME_MESSAGE_REQUIRED') }]}
        >
          <Input
            placeholder={t('COMPANY_NAME_PLACEHOLDER')}
            defaultValue={mode === 'update' ? company?.othername : null}
          />
        </Form.Item>
      ) : (
        <div>
          <Form.Item
            label={t('USER_FIRSTNAME')}
            name="firstname"
            rules={[{ required: mode === 'create', message: t('USER_FIRSTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input
              placeholder={t('USER_FIRSTNAME_INPUT_PLACEHOLDER')}
              defaultValue={mode === 'update' ? company?.firstname : null}
            />
          </Form.Item>
          <Form.Item
            label={t('USER_LASTNAME')}
            name="lastname"
            rules={[{ required: mode === 'create', message: t('USER_LASTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input
              placeholder={t('USER_LASTNAME_INPUT_PLACEHOLDER')}
              defaultValue={mode === 'update' ? company?.lastname : null}
            />
          </Form.Item>
        </div>
      )}

      <Form.Item
        name="required"
        valuePropName="checked"
        rules={[{ required: true, message: t('COMPANY_CREATE_RADIO_1_MESSAGE_REQUIRED') }]}
      >
        <Radio.Group>
          <Radio value="1" style={{ marginBottom: 15 }}>
            {t('COMPANY_CREATE_RADIO_1')}
          </Radio>
          <Radio value="2">{t('COMPANY_CREATE_RADIO_2')}</Radio>
        </Radio.Group>
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon />
        </Form.Item>
      )}
      <div className="dg-flex dg-justify-between">
        {hide && <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>}
        <Button type="primary" htmlType="submit">
          {label}
        </Button>
      </div>
    </Form>
  );
}

export default UpsertCompany;
