/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';

const styles = {
  button: {
    width: 50,
    height: 20,
    borderRadius: 20,
    cursor: 'pointer',
    border: '2px solid black',
  },
};

function ColorPicker({ value, onChange }) {
  const [displayPicker, setDisplayPicker] = useState(false);

  return (
    <>
      <div style={{ backgroundColor: value, ...styles.button }} onClick={() => setDisplayPicker((p) => !p)} />
      {displayPicker && (
        <div style={{ backgroundColor: 'white', position: 'absolute', zIndex: 100 }}>
          <TwitterPicker color={value} onChangeComplete={(v) => onChange(v.hex)} />
        </div>
      )}
    </>
  );
}

export default ColorPicker;
