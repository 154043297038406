/* eslint-disable react/jsx-props-no-spreading */
import { Form, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from './Form/context';

function OperationsShares({ mode = null, index = null, operationId }) {
  const { t } = useTranslation();
  const { form, Tooltip } = useContext(FormContext);

  const symbol = form ? form.getFieldValue(['v', index, 'symbol']) || '+' : '+';
  const disabled = form ? form.getFieldValue(['v', index, 'ignore']) || false : false;
  const name = ['v', index, 'shares'];
  const rules = [
    { required: !disabled, message: t('OPERATIONS_SHARES_REQUIRED') },
    !disabled ? { type: 'number', min: 1, message: t('OPERATIONS_SHARES_MIN') } : null,
    !disabled
      ? {
          validator: (_, value) =>
            value % 1 === 0 ? Promise.resolve() : Promise.reject(t('OPERATIONS_SHARES_INTEGER')),
        }
      : null,
  ];
  const initialValue = 0;

  if (mode === 'COMMON') {
    return (
      <Tooltip title={t(`OPERATIONS_SHARES_TOOLTIP_${operationId}`)}>
        <Form.Item
          label={t(`OPERATIONS_SHARES_${operationId}`)}
          name={['common', 'shares']}
          initialValue={initialValue}
          rules={rules}
        >
          <InputNumber />
        </Form.Item>
      </Tooltip>
    );
  }

  if (['ADD', 'MULTIPLICATION', 'DIVISION', 'SUBSTRACTION'].includes(mode)) {
    return (
      <Form.Item name={name} initialValue={initialValue} rules={rules}>
        <InputNumber disabled={disabled} />
      </Form.Item>
    );
  }

  return (
    <Tooltip title={t(`OPERATIONS_SHARES_TOOLTIP_${symbol}`)}>
      <Form.Item label={t(`OPERATIONS_SHARES_${symbol}`)} name={name} initialValue={initialValue} rules={rules}>
        <InputNumber />
      </Form.Item>
    </Tooltip>
  );
}

export default OperationsShares;
