import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import store from '../redux/store';
import { getShareholders } from '../api/company';
import { useOperationShareholders } from '../redux/operation/selectors';

const useShareholdersLocal = (balance, accountState, accountType, shareType, shareholder) => {
  const { t } = useTranslation();
  const [shareholders, setShareholders] = useState([]);
  const shareholdersGlobal = useOperationShareholders();

  useEffect(() => {
    const companyId = store.getState().company.id;
    if (!companyId) return;

    (async (q) => {
      try {
        const r = await getShareholders(companyId, q);
        setShareholders(r);
      } catch (e) {
        notification.error({ description: t('SHAREHOLDERS_FETCH_ERROR') });
      }
    })({ balance, accountState, accountType, shareType, shareholder });
  }, [balance, accountState, accountType, shareType, shareholder, shareholdersGlobal, t]);

  return shareholders;
};

export default useShareholdersLocal;
