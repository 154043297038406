import { Col, Row, Input, Divider, Button, DatePicker, notification } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Countries from '../../components/Countries';
import { useUser } from '../../redux/user/selectors';
import { fetchUser } from '../../redux/user/actions';
import { updateUser } from '../../api/users';
import EditCustomItems from '../Shareholder/Details/EditCustomItems';

const labels = [
  'firstname',
  'lastname',
  'birthcity',
  'birthdate',
  'companyName',
  'profession',
  'address',
  'postalCode',
  'city',
  'country',
  'email',
  'password',
  'customFields',
];

const size = 'large';
const style = { margin: '5px 0 15px 0', width: '100%' };

const getLabel = (i) => labels[i].toLowerCase();

const findLabel = (s) => {
  for (let i = 0; i < labels.length; i += 1) if (labels[i].toLowerCase() === s.toLowerCase()) return labels[i];
  return s;
};

const CustomInput = ({ label, span, onChange, user }) => {
  const { t } = useTranslation();

  return (
    <Col span={span || 12}>
      <span>{t(`USER_${label.toUpperCase()}`)}</span>
      <Input
        type={label === 'password' ? 'password' : null}
        autocomplete="off"
        style={style}
        name={label}
        size={size}
        defaultValue={user && user[label]}
        onChange={(e) => {
          if (e.target.value === user[e.target.name]) e.target.value = '';
          onChange(e);
        }}
        allowClear
      />
    </Col>
  );
};

const UserProfile = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [values, setValues] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [validate, setValidate] = useState(false);
  const gutter = [16, 16];

  const onChange = (e) => {
    setDisabled(e.target.value === '');
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Row gutter={gutter}>
        <CustomInput user={state?.user || user} label={getLabel(0)} onChange={onChange} />
        <CustomInput user={state?.user || user} label={getLabel(1)} onChange={onChange} />
      </Row>
      <Row gutter={gutter}>
        <CustomInput user={state?.user || user} label={getLabel(2)} onChange={onChange} />
        <Col span="12">
          <span>{t(`USER_${getLabel(3).toUpperCase()}`)}</span>
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(_, d) => {
              setValues({
                ...values,
                [getLabel(3)]: d,
              });
            }}
            isForm={false}
            size={size}
            style={style}
            defaultValue={(() => {
              const d = (state?.user || user)[getLabel(3)];
              return d && moment(d, 'YYYY-MM-DD');
            })()}
          />
        </Col>
      </Row>
      <Row gutter={gutter}>
        <CustomInput user={state?.user || user} label={getLabel(4)} onChange={onChange} />
        <CustomInput user={state?.user || user} label={getLabel(5)} onChange={onChange} />
      </Row>
      <Row gutter={gutter}>
        <CustomInput user={state?.user || user} label={getLabel(6)} span="24" onChange={onChange} />
      </Row>
      <Row gutter={gutter}>
        <CustomInput user={state?.user || user} label={getLabel(7)} span="4" onChange={onChange} />
        <CustomInput user={state?.user || user} label={getLabel(8)} span="10" onChange={onChange} />
        <Col span="10">
          <span>{t(`USER_${getLabel(9).toUpperCase()}`)}</span>
          <Countries
            onChange={(v) => {
              setValues({
                ...values,
                [getLabel(9)]: v,
              });
            }}
            isForm={false}
            size={size}
            style={style}
            defaultValue={(state?.user || user)[getLabel(9)]}
          />
        </Col>
      </Row>
      <EditCustomItems style={{ marginTop: '20px' }} validate={validate} onChange={() => setDisabled(false)} />
      <Divider />
      <Button
        type="primary"
        disabled={disabled}
        onClick={async () => {
          setValidate(!validate);
          if (Object.keys(values).length === 0) return;
          try {
            await updateUser(
              Object.fromEntries(
                Object.entries(values)
                  .filter((x) => x[0] === labels[12] || x[1].length)
                  .map((x) => [findLabel(x[0]), x[1]])
              ),
              state?.id
            );
            notification.success({ description: t('USER_UPDATE_SUCCESS') });
            dispatch(fetchUser());
          } catch (e) {
            if (e.message) notification.error({ description: t(e.message.toUpperCase()) });
          }
        }}
      >
        {t('USER_VALIDATE')}
      </Button>
    </div>
  );
};

export default UserProfile;
