import React from 'react';
import './loading.css';
import { useSecondColor } from '../redux/settings/selectors';
import hexToRGBA from '../services/hexToRGBA';

const points = 8;

function Loading() {
  const background = useSecondColor();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="lds-roller">
        {new Array(points).fill(1).map((_, i) => (
          <div className="lds-roller-after" style={{ background: hexToRGBA(background, i / points) }} />
        ))}
      </div>
    </div>
  );
}

export default Loading;
