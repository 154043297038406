import { Collapse, Form, Table } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../Form/context';

const { Panel } = Collapse;

function OperationsAccounts({ userType, companyAccounts, customName, option, setOptions }) {
  const { t } = useTranslation();
  const { Tooltip, form } = useContext(FormContext);

  const operation = useOperationChild();

  const USER_TYPE = userType.toUpperCase();

  const columns = [
    { title: t('POOL_NAME'), dataIndex: 'poolName' },
    { title: t('SHARE_TYPE'), dataIndex: 'shareType', render: (shareType) => t(`SHARE_TYPE_${shareType}`) },
    { title: t('ACCOUNT_TYPE'), dataIndex: 'accountType', render: (accountType) => t(`ACCOUNT_TYPE_${accountType}`) },
    {
      title: t('ACCOUNT_STATE'),
      dataIndex: 'accountState',
      render: (accountType) => t(`ACCOUNT_STATE_${accountType}`),
    },
    { title: t('OPERATION_ACCOUNT_NUMBER'), dataIndex: 'accountNumber' },
    { title: t('ACCOUNT_BALANCE'), dataIndex: 'balance' },
  ].slice(customName ? 1 : 0);

  const Accounts = ({ onChange, value }) => {
    const shareholderId = form.getFieldValue(`${userType}Id`);
    const key = shareholderId || -1;

    return (
      <Collapse key={key} defaultActiveKey={key} bordered={false} expandIconPosition="right">
        <Panel header={companyAccounts?.name} key={key} forceRender>
          <Table
            rowKey="accountNumber"
            dataSource={(() => {
              if (!companyAccounts?.accounts) return [];
              if (customName) return companyAccounts.accounts;
              return companyAccounts.accounts.map((x) => ({
                poolName: JSON.parse(x.options).OPERATION_NAME,
                ...x,
              }));
            })()}
            columns={columns}
            rowSelection={{
              type: 'radio',
              onChange: (array, [{ options }]) => {
                onChange(array[0] || null);
                if (setOptions) setOptions(options);
              },
              defaultSelectedRowKeys: [value],
            }}
            scroll={{ x: true }}
          />
        </Panel>
      </Collapse>
    );
  };

  return (
    <>
      <Tooltip title={t(`OPERATION_${operation.id}_${USER_TYPE}_ACCOUNTS_TOOLTIP`)} placement="right">
        <Form.Item
          name={option ? ['options', `${userType}_ACCOUNT_ID`.toUpperCase()] : `${userType}AccountId`}
          label={<div style={{ fontWeight: 'bold' }}>{t(`SHAREHOLDERS_SELECT_${customName || 'POOL'}`)}</div>}
          rules={[{ required: true, message: t(`OPERATION_${USER_TYPE}_ACCOUNTS_MESSAGE_REQUIRED`) }]}
          labelCol={{ span: 24 }}
          preserve={false}
        >
          <Accounts />
        </Form.Item>
      </Tooltip>
    </>
  );
}

export default OperationsAccounts;
