import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from '../../components/Form/context';

function OptionTypes() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const options = [
    { label: t('OPTION_TYPE_1'), value: 'OPTION_TYPE_1' },
    { label: t('OPTION_TYPE_2'), value: 'OPTION_TYPE_2' },
  ];

  return (
    <Tooltip title={t('OPTION_TYPE_TOOLTIP')}>
      <Form.Item
        label={t('OPTION_TYPE')}
        name={['options', 'OPTION_TYPE']}
        rules={[{ required: true, message: t(`OPTION_TYPE_REQUIRED`) }]}
        initialValue="OPTION_TYPE_1"
      >
        <Select options={options} />
      </Form.Item>
    </Tooltip>
  );
}

export default OptionTypes;
