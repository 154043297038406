import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getBlock } from '../../api/blockchain';
import SearchEntry from './SearchEntry';
import DataDisplay from './DataDisplay';
import Header from './Header';
import isValidId from './utils/isValidId';

const BlockExplorer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [block, setBlock] = useState({ block: {}, operation: [] });

  const id = location.pathname.split('/').pop();

  useEffect(() => {
    if (isValidId(id))
      getBlock(id)
        .then(setBlock)
        .catch((e) => notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') }));
  }, [t, id]);

  return (
    <div>
      {isValidId(id) ? (
        <div>
          <Header />
          <DataDisplay data={block} />
        </div>
      ) : (
        <SearchEntry />
      )}
    </div>
  );
};

export default BlockExplorer;
