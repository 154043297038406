import React from 'react';
import useShareholders from '../../../../../hooks/useShareholders';
import OperationsComment from '../../components/v2/Comment';
import OperationsAccounts6 from '../components/Accounts';
import OperationDate from '../../components/Date';
import Shares from '../components/Shares';

// Annulation d'options
function OperationsCreate6x4x1() {
  const holders = useShareholders('>', null, null, '4|5|6|7|8|9|10');

  return (
    <>
      <OperationsAccounts6 userType="holder" shareholders={holders} />

      <Shares />
      <OperationDate />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate6x4x1;
