import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setOperationChild } from '../../../../../redux/operation/actions';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';

function SelectShareType({ operations }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const operation = useOperationChild();

  return (
    <Tooltip title={t('OPERATION_CHILD_TYPE_TOOLTIP')}>
      <Form.Item label={t('OPERATION_CHILD_TYPE')}>
        <Select
          options={operations.map((m) => ({
            label: t(`OPERATION_SHARE_TYPE_${m.shareType}_LABEL`),
            value: m.shareType,
          }))}
          onChange={(v) => dispatch(setOperationChild({ ...operation, shareType: v }))}
          value={operation.shareType}
        />
      </Form.Item>
    </Tooltip>
  );
}

export default SelectShareType;
