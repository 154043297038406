/* eslint-disable no-underscore-dangle */
import { DeleteOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Form, Input, message, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPrimaryColor, setSecondColor } from '../../redux/settings/actions';
import { usePrimaryColor, useSecondColor } from '../../redux/settings/selectors';
import ColorPicker from '../../components/ColorPicker';

const { Paragraph } = Typography;
const activeLanguage = 'fr';

const ColorPick = () => {
  const dispatch = useDispatch();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();

  return (
    <div>
      <Row>
        <ColorPicker value={primaryColor} onChange={(color) => dispatch(setPrimaryColor(color))} />
        <ColorPicker value={secondColor} onChange={(color) => dispatch(setSecondColor(color))} />
      </Row>
      <Divider />
    </div>
  );
};

function Translations() {
  const history = useHistory();

  const [form] = Form.useForm();
  const [translations, setTranslations] = useState({});
  const [search, setSearch] = useState('');
  const translationsArray = useMemo(
    () =>
      Object.entries(translations)
        .filter(
          ([key, value]) =>
            value &&
            (value.toLowerCase().includes(search.toLowerCase()) || key.toLowerCase().includes(search.toLowerCase()))
        )
        .map(([key, value]) => ({ key, value })),
    [translations, search]
  );

  const getTranslations = async () => {
    const response = await fetch(
      `https://digistre-front-translation.s3.eu-west-3.amazonaws.com/${activeLanguage}/translations.json?date=${new Date().getTime()}`
    );
    const json = await response.json();
    setTranslations(json);
    return json;
  };

  const updateTranslations = async (values) => {
    const oldValues = await getTranslations();
    const newValues = { ...oldValues, ...values };
    await fetch(`https://digistre-front-translation.s3.eu-west-3.amazonaws.com/${activeLanguage}/translations.json`, {
      method: 'PUT',
      body: JSON.stringify(newValues),
    });
    setTranslations(newValues);
    message.success('Updated with success');
  };

  const deleteTranslation = (key) => {
    setTranslations((t) => {
      const removeKeyFromTranslations = { ...t, [key]: undefined };
      updateTranslations(removeKeyFromTranslations);
      return removeKeyFromTranslations;
    });
  };

  const updateTranslation = (key, value) => {
    setTranslations((t) => {
      const updateKeyFromTranslations = { ...t, [key]: value };
      updateTranslations(updateKeyFromTranslations);
      return updateKeyFromTranslations;
    });
  };

  const addTranslation = (key, value) => {
    const find = translations[key];
    if (find) return;
    updateTranslation(key, value);
    form.resetFields();
  };

  useEffect(() => {
    getTranslations();
  }, []);

  const columns = [
    { title: 'KEY', dataIndex: 'key' },
    {
      title: 'VALUE',
      render: ({ key, value }) => (
        <Paragraph editable={{ onChange: (newValue) => updateTranslation(key, newValue) }}>{value}</Paragraph>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'key',
      render: (key) => (
        <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => deleteTranslation(key)} danger />
      ),
    },
  ];

  return (
    <>
      <Button onClick={() => history.push('/admin')}>Retour</Button>
      <Divider />
      <Form.Item>
        <ColorPick />
        <Form form={form} layout="inline" onFinish={({ key, value }) => addTranslation(key, value)}>
          <Form.Item style={{ width: 400 }} label="KEY" name="key" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item style={{ width: 400 }} label="VALUE" name="value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Ajouter
            </Button>
          </Form.Item>
        </Form>
      </Form.Item>

      <Form.Item>
        <Input.Search placeholder="Search translation" onChange={(e) => setSearch(e.target.value)} />
      </Form.Item>

      <Table columns={columns} dataSource={translationsArray} />
    </>
  );
}

export default Translations;
