import { Form, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';

function Shares({ onChange }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();

  const name = shareType ? `OPERATIONS_${id}_${shareType}_SHARES` : `OPERATIONS_${id}_SHARES`;

  return (
    <Tooltip title={t(`${name}_TOOLTIP`)}>
      <Form.Item label={t(name)} name="shares" rules={[{ required: true, message: t('OPERATIONS_SHARES_REQUIRED') }]}>
        <InputNumber min={1} onChange={onChange} />
      </Form.Item>
    </Tooltip>
  );
}

export default Shares;
