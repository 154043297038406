import { DownOutlined, MailOutlined, MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Dropdown, Menu, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor, useLogo } from '../../../redux/settings/selectors';
import { signOut } from '../../../redux/user/actions';
import Notifications from './Notifications';
import messageApi from '../../../api/message';
import toBlockExplorer from '../../toBlockExplorer';
import companyApi from '../../../api/company';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: 0,
    padding: '0 10px',
  },
  left: { container: { display: 'flex', alignItems: 'center' }, search: { width: 250 } },
  right: { badge: { fontSize: 24, cursor: 'pointer' }, divider: { height: 20 }, collapse: { fontSize: 20 } },
};

const companyNameStyle = (color) => ({
  backgroundColor: color,
  fontWeight: 'bold',
  borderRadius: '40px',
  border: 'none',
  color: 'white',
  height: '55%',
  padding: '0 25px',
  lineHeight: '250%',
});

const formatPlan = (x, t) => (x.limit === null ? t('COMPANY_SUBSCRIBE_FORMULA_2') : `${x.anchorCount}/${x.limit}`);

function LayoutMainHeader({ lock, setLock, collapsed, setCollapsed }) {
  const history = useHistory();
  const { t } = useTranslation();
  const company = useCompany();
  const [spin, setSpin] = useState(false);
  const [messageCount, setMessageCount] = useState(null);
  const [planCount, setPlanCount] = useState(null);

  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const logo = useLogo();

  useEffect(() => {
    messageApi.getUnread().then((x) => setMessageCount(x.total || x.count));
    if (messageCount !== null) {
      // si messageCount null, alors l'API est inactive ; donc on ne refresh pas.
      const interval = setInterval(
        () => messageApi.getUnread().then((x) => setMessageCount(x.total || x.count)),
        30000
      );
      return () => {
        clearInterval(interval);
      };
    }
    return null;
  }, [messageCount]);

  useEffect(() => {
    if (!company?.id) return;
    companyApi.planStats(company.id).then((x) => setPlanCount(formatPlan(x, t)));
    const interval = setInterval(
      () => companyApi.planStats(company.id).then((x) => setPlanCount(formatPlan(x, t))),
      30000
    );
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [company?.id, t]);

  const toggleCollapsed = () => {
    if (!collapsed) setLock(!lock);
    else setCollapsed(false);
  };

  const nav = (s) => {
    if (lock) setCollapsed(true);
    history.push(s);
  };

  return (
    <Header style={styles.container}>
      <Space size="large">
        <Button type="text" onClick={toggleCollapsed}>
          {collapsed ? (
            <MenuUnfoldOutlined style={styles.right.collapse} />
          ) : (
            (!lock && <MenuUnfoldOutlined style={styles.right.collapse} />) ||
            (lock && <MenuFoldOutlined style={styles.right.collapse} />)
          )}
        </Button>
        <img src={logo} alt="digistre-logo" height="40" />
      </Space>

      <Space size={30} split={<Divider type="vertical" style={styles.right.divider} />}>
        <div style={companyNameStyle(secondColor)}>{company?.name}</div>
        <div style={{ fontWeight: 'bold' }}>
          {planCount} <img src="/ethereum.svg" alt="logo" width="14px" style={{ margin: '0 0 3px 2px' }} />
        </div>
        <Button style={{ color: primaryColor }} onClick={() => toBlockExplorer()}>
          {t('BLOCK_EXPLORER')}
        </Button>
        <Badge color={secondColor} count={messageCount}>
          <MailOutlined onClick={() => nav('/message')} style={styles.right.badge} />
        </Badge>

        <Notifications color={secondColor} />

        <Dropdown overlay={<MenuProfile />} placement="bottomRight">
          <Space size={10}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => nav('/profile')}
              role="button"
              tabIndex={0}
              aria-hidden="true"
            >
              <SettingOutlined
                onMouseEnter={() => {
                  setSpin(true);
                  setTimeout(() => setSpin(false), 350);
                }}
                style={{ fontSize: 26 }}
                spin={spin}
              />
            </div>
            <DownOutlined style={{ fontSize: 15 }} />
          </Space>
        </Dropdown>
      </Space>
    </Header>
  );
}

function MenuProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Menu style={{ minWidth: '250px' }}>
      <Menu.Item onClick={() => history.push('/profile')}>{t('PERSONAL_INFORMATIONS')}</Menu.Item>
      <Menu.Item onClick={() => history.push('/profile/invoices')}>{t('INVOICES')}</Menu.Item>
      {/* <Menu.Item onClick={() => history.push('/profile/subscriptions')}>{t('SUBSCRIPTIONS')}</Menu.Item> */}
      <Menu.Item onClick={() => dispatch(signOut())}>{t('SIGN_OUT')}</Menu.Item>
    </Menu>
  );
}

export default LayoutMainHeader;
