import { useEffect, useState } from 'react';
import userApi from '../api/users';

const useTutorialUser = () => {
  const [tutorialUser, setTutorialUser] = useState(null);

  useEffect(() => {
    userApi
      .getTutorialUser()
      .then(setTutorialUser)
      .catch(() => {
        /* Nothing */
      });
  }, []);

  return tutorialUser;
};

export default useTutorialUser;
