import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShareholdersV2 } from '../redux/operation/actions';
import { selectShareholdersV2 } from '../redux/operation/selectors';

const useShareholders = (balance, accountState, accountType, shareType, shareholder) => {
  const dispatch = useDispatch();

  const shareholders = useSelector(selectShareholdersV2);

  useEffect(() => {
    dispatch(fetchShareholdersV2({ balance, accountState, accountType, shareType, shareholder }));
  }, [balance, accountState, accountType, shareType, shareholder, dispatch]);

  return shareholders;
};

export default useShareholders;
