/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Charts from 'react-apexcharts';
import { useSecondColor } from '../../redux/settings/selectors';

function StatsPie({ title, data = [] }) {
  const { t } = useTranslation();
  const color = useSecondColor();

  const filteredData = data.filter((x) => x.value > 0);

  return (
    <Card title={title}>
      <Charts
        height={300}
        type="pie"
        options={{
          chart: {
            height: 300,
            type: 'pie',
          },
          labels: filteredData.map((x) => t(x.name)),
          dataLabels: {
            enabled: true,
          },
          theme: {
            monochrome: {
              enabled: true,
              color,
              shadeTo: 'light',
              shadeIntensity: 0.7,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 50,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
          },
        }}
        series={filteredData.map((x) => x.value)}
      />
    </Card>
  );
}

export default StatsPie;
