import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TitlePrimary from '../../components/TitlePrimary';

function Welcome({ next }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 200px - 64px - 48px)',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: 30 }}>
        <TitlePrimary title={t('WELCOME_ON_DIGISTRE_1')} />
      </div>
      <div style={{ marginBottom: 30 }}>{t('WELCOME_ON_DIGISTRE_2')}</div>
      <Button type="primary" onClick={next}>
        {t('COMMON_NEXT')}
      </Button>
    </div>
  );
}

export default Welcome;
