import { Alert, Button, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Countries from '../../../../../../../components/Countries';
import { closeModalCreateShareholder, createShareholder } from '../../../../../../../redux/operation/actions';
import ShareholderQuality from '../../Quality';
import CustomItems from '../../../../../../../components/CustomItems';

function UniqueIndivision() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    setError(null);

    try {
      await dispatch(createShareholder(values, 1));
      form.resetFields();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t('USER_LASTNAME')}
        name="lastname"
        rules={[{ required: true, message: t('USER_LASTNAME_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('USER_LASTNAME_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={t('USER_FIRSTNAME')}
        name="firstname"
        rules={[{ required: true, message: t('USER_FIRSTNAME_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('USER_FIRSTNAME_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <ShareholderQuality form={form} />

      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item label={t('USER_BIRTHDATE')} name="birthdate">
            <DatePicker placeholder={t('USER_BIRTHDATE_PLACEHOLDER')} style={{ width: '100%' }} format="DD/MM/YYYY" />
          </Form.Item>
        </Col>

        <Col sm={{ span: 14, offset: 2 }}>
          <Form.Item label={t('USER_BIRTHCITY')} name="birthcity">
            <Input placeholder={t('USER_BIRTHCITY_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t('USER_EMAIL')}
        name="email"
        rules={[{ type: 'email', message: t('COMMON_EMAIL_BAD_FORMAT') }]}
      >
        <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item label={t('USER_ADDRESS')} name="address">
        <Input placeholder={t('USER_ADDRESS_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item label={t('USER_ZIP')} name="zip">
            <Input placeholder={t('USER_ZIP_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col sm={{ span: 14, offset: 2 }}>
          <Form.Item label={t('USER_CITY')} name="city">
            <Input placeholder={t('USER_CITY_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Countries />

      <Form.Item label={t('USER_NATIONALITY')} name="nationality">
        <Input placeholder={t('USER_NATIONALITY_PLACEHOLDER')} />
      </Form.Item>

      {/* <CustomItems form={form} /> */}

      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon />
        </Form.Item>
      )}

      <Divider />

      <div className="dg-flex dg-justify-between">
        <Button onClick={() => dispatch(closeModalCreateShareholder())}>{t('COMMON_CANCEL')}</Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('SHAREHOLDER_CREATE')}
        </Button>
      </div>
    </Form>
  );
}

export default UniqueIndivision;
