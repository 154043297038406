import React, { useState, useContext } from 'react';
import { Form, Button, Drawer, List, Col, Row } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ControlledInput from '../../../../components/ControlledInput';
import OperationType from '../../../../components/OperationType';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import FormContext from '../components/Form/context';
// eslint-disable-next-line import/no-cycle
import OperationsList from '../../List';
import { useFourthColor } from '../../../../redux/settings/selectors';

// Inversion
function OperationsCreate7x1() {
  const { t } = useTranslation();
  const color = useFourthColor();
  const [isVisible, setIsVisible] = useState(false);
  const [invert, setInvert] = useState([]);
  const [keys, setKeys] = useState([]);

  const { Tooltip, form } = useContext(FormContext);

  return (
    <>
      <Tooltip title={t('OPERATIONS_EDIT_TOOLTIP')}>
        <Form.Item label=" " colon={false}>
          <Button
            style={{ width: '100%', background: color }}
            icon={<ReadOutlined />}
            onClick={() => setIsVisible(true)}
          >
            {t('OPERATIONS_EDIT')}
          </Button>
        </Form.Item>
      </Tooltip>
      <ControlledInput name="operationsKeys" value={keys.map((x) => x.ordernumber)} form={form} />
      {(keys.length || undefined) && (
        <Form.Item label={t('OPERATIONS_SELECTED')} colon={false}>
          <List
            size="small"
            dataSource={keys}
            renderItem={(item) => (
              <List.Item key={item}>
                <List.Item.Meta
                  title={
                    <Row gutter={[8, 8]}>
                      <Col flex="5%" style={{ fontWeight: 'bold' }}>
                        {`${t('OPERATION_ID')}${item.ordernumber}`}
                      </Col>
                      <Col flex="32%">{new Date(item.date).toLocaleDateString()}</Col>
                      <Col flex="auto">
                        <OperationType type={item.changenature} />
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </Form.Item>
      )}
      <OperationsDate />
      <OperationsComment />
      <Drawer
        title={t('OPERATIONS_EDIT')}
        placement="bottom"
        height="93%"
        onClose={() => setIsVisible(false)}
        visible={isVisible}
        closable
        footer={
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setKeys([...new Set(Object.values(invert).flat().map(JSON.stringify))].map(JSON.parse));
              setIsVisible(false);
            }}
          >
            {t('OPERATIONS_EDIT_VALIDATE')}
          </Button>
        }
      >
        <OperationsList invert={invert} setInvert={setInvert} />
      </Drawer>
    </>
  );
}

export default OperationsCreate7x1;
