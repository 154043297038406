import { Form, Input } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useShareholders from '../../../../hooks/useShareholders';
import OperationsAccounts from '../components/v2/Accounts';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';
import FormContext from '../components/Form/context';

// Saisie de droits d'associés
function OperationsCreate4x1() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const holders = useShareholders('>', 1);

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />

      <Tooltip title={t('SEIZING_CREDITOR_TOOLTIP')}>
        <Form.Item name={['options', 'SEIZING_CREDITOR']} label={t('SEIZING_CREDITOR')}>
          <Input />
        </Form.Item>
      </Tooltip>

      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate4x1;
