import { TeamOutlined, SwapOutlined, BarsOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import companyApi from '../../../api/company';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor, useThirdColor } from '../../../redux/settings/selectors';
import { numberWithCommas } from '../../../services/number';

function CompanyStats({ backgroundColor, icon: Icon, label, value }) {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        color: 'white',
        padding: 20,
        backgroundColor,
        borderRadius: 4,
      }}
    >
      <div style={{ fontSize: 25, height: 50, width: 50, position: 'relative', marginBottom: 10 }}>
        <div style={{ backgroundColor: 'white', width: 50, height: 50, opacity: 0.2, borderRadius: 3 }} />
        <Icon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      </div>
      <strong style={{ fontSize: 30, marginBottom: 10 }}>{numberWithCommas(value)}</strong>
      <div>{label}</div>
    </div>
  );
}

const statsCol = { xs: 24, sm: 24, md: 24, lg: 8, xl: 5 };

function CompanyDetailsStats() {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const thirdColor = useThirdColor();
  const company = useCompany();

  const [stats, setStats] = useState({});

  useEffect(() => {
    companyApi
      .getStatsByCompanyId(company.id)
      .then(setStats)
      .catch(() => setStats({}));
  }, [company.id]);

  return (
    <>
      <Col {...statsCol}>
        <CompanyStats
          backgroundColor={secondColor}
          label={t('SHAREHOLDERS_COUNT')}
          value={stats.shareholders || 0}
          icon={TeamOutlined}
        />
      </Col>
      <Col {...statsCol}>
        <CompanyStats
          backgroundColor={thirdColor}
          label={t('SHARES_MOVEMENT')}
          value={stats.shares?.nonFullyDiluted || 0}
          icon={SwapOutlined}
        />
      </Col>
      <Col {...statsCol}>
        <CompanyStats
          backgroundColor={primaryColor}
          label={t('OPERATIONS_COUNT')}
          value={stats.operations || 0}
          icon={BarsOutlined}
        />
      </Col>
    </>
  );
}

export default CompanyDetailsStats;
