import { Card, Col, Row, Table, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompanies } from '../../../api/company';
import { getPermissionUser } from '../../../api/permission';
import { setCompany } from '../../../redux/company/actions';
import { useUserId } from '../../../redux/user/selectors';
import UpsertCompany from './UpsertCompany';
import DeleteCompany from './DeleteCompany';
import CompaniesStats from './Stats';
import Subscribe from '../../../components/Payment/Subscribe';

function CompaniesList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const userId = useUserId();

  const [companies, setCompanies] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [subscribeVisible, setSubscribeVisible] = useState(false);

  // if the user is at least in one company as an admin or keeper,
  // or if there is no company ; sets "isAdmin" to true
  useEffect(() => {
    if (companies.length > 0)
      Promise.all(
        companies.map((x) => {
          if (x.id) return getPermissionUser(x.id).then((o) => o?.type);
          return null;
        })
      ).then((x) => {
        if (x.includes(1) || x.includes(2)) setIsAdmin(true);
        setPermissions(companies.map((o, i) => ({ ...o, type: x[i] })));
      });
  }, [companies]);

  const fetchCompanies = useCallback(() => {
    getCompanies(userId)
      .then(setCompanies)
      .catch(() => setCompanies([]));
  }, [userId]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const toCompany = async (x, e) => {
    e?.stopPropagation();
    if (!x.isSubscriptionValid) {
      setSubscribeVisible(x);
    } else {
      dispatch(setCompany(x));
      localStorage.setItem('company', x.id);
      history.push(`/company/${x.id}`);
    }
  };

  const columns = [
    {
      title: t('COMPANY'),
      dataIndex: 'name',
      render: (name, company) => <Typography.Link onClick={(e) => toCompany(company, e)}>{name}</Typography.Link>,
    },
    { title: t('SHAREHOLDERS_COUNT'), dataIndex: 'shareholders' },
    {
      title: t('COMPANY_USER_NAME'),
      render: (company) => company.othername || `${company.firstname} ${company.lastname}`,
    },
    {
      title: t('LAST_OPERATION'),
      dataIndex: 'lastOperationDate',
      render: (date) => date && new Date(date).toLocaleDateString(),
    },
    {
      width: '10%',
      render: (company) => {
        const p = permissions.find((x) => x.id === company.id) || {};
        return p.type === 1 || p.type === 2 ? <DeleteCompany company={company} refresh={fetchCompanies} /> : <div />;
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <CompaniesStats />
        </Col>

        <Col span={24}>
          <Card title={t('MY_COMPANIES')} extra={isAdmin && <UpsertCompany refresh={fetchCompanies} mode="create" />}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={companies}
              rowClassName="dg-cursor"
              onRow={(r) => ({
                onClick: (e) => toCompany(r, e),
              })}
            />
          </Card>
        </Col>
      </Row>
      <Subscribe refresh={fetchCompanies} company={subscribeVisible} resetCompany={setSubscribeVisible} />
    </>
  );
}

export default CompaniesList;
