import { Alert, Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authenticationApi from '../../api/authentication';
import Countries from '../../components/Countries';

const { Text } = Typography;

function RegisterForm() {
  const history = useHistory();
  const { t } = useTranslation();

  const [[loading, error], setState] = useState([false, null]);

  const onFinish = async (user) => {
    setState([true, null]);
    try {
      if (user.password !== user.password_confirmation)
        return setState([false, 'PASSWORD_AND_PASSWORD_CONFIRMATION_ARE_NOT_EQUAL']);
      await authenticationApi.signUp(user);
      return history.push('/register/confirmation');
    } catch (err) {
      return setState([false, err.message]);
    }
  };

  return (
    <Form size="large" layout="vertical" onFinish={onFinish}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Form.Item
            label={t('USER_LASTNAME')}
            name="lastname"
            rules={[{ required: true, message: t('USER_LASTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_LASTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}>
          <Form.Item
            label={t('USER_FIRSTNAME')}
            name="firstname"
            rules={[{ required: true, message: t('USER_FIRSTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_FIRSTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Form.Item
            label={t('USER_COMPANY')}
            name="companyName"
            rules={[{ required: true, message: t('USER_COMPANY_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_COMPANY_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}>
          <Form.Item
            label={t('USER_JOB')}
            name="profession"
            rules={[{ required: true, message: t('USER_JOB_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_JOB_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t('USER_ADDRESS')}
        name="address"
        rules={[{ required: true, message: t('USER_ADDRESS_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('USER_ADDRESS_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <Form.Item
            label={t('USER_ZIP')}
            name="postalCode"
            rules={[{ required: true, message: t('USER_ZIP_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_ZIP_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 14, offset: 2 }}>
          <Form.Item
            label={t('USER_CITY')}
            name="city"
            rules={[{ required: true, message: t('USER_CITY_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_CITY_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Countries required />

      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Form.Item
            label={t('USER_EMAIL')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('USER_EMAIL_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}>
          <Form.Item
            label={t('USER_EMAIL_CONFIRMATION')}
            name="email_confirmation"
            rules={[{ required: true, type: 'email', message: t('USER_EMAIL_CONFIRMATION_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_EMAIL_CONFIRMATION_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Form.Item
            label={t('USER_PASSWORD')}
            name="password"
            rules={[
              {
                required: true,
                message: t('USER_PASSWORD_MESSAGE_REQUIRED'),
              },
              {
                pattern: /^(?=\P{Ll}*\p{Ll})(?=\P{N}*\p{N})(?=[\p{L}\p{N}]*[^\p{L}\p{N}])[\s\S]{10,}$/u,
                message: t('USER_PASSWORD_RULES'),
              },
            ]}
          >
            <Input.Password placeholder={t('USER_PASSWORD_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}>
          <Form.Item
            label={t('USER_PASSWORD_CONFIRMATION')}
            name="password_confirmation"
            rules={[{ required: true, message: t('USER_PASSWORD_CONFIRMATION_MESSAGE_REQUIRED') }]}
          >
            <Input.Password placeholder={t('USER_PASSWORD_CONFIRMATION_INPUT_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="cgu"
        valuePropName="checked"
        rules={[{ required: true, message: t('REGISTER_CGU_MESSAGE_REQUIRED') }]}
      >
        <Checkbox>
          {t('REGISTER_READ_CGU')}
          <Button
            type="link"
            size="small"
            onClick={() =>
              window.open(
                'https://digistre.com/wp-content/uploads/2023/05/23-05-26-DIGISTRE-CGUV-.pdf',
                `_blank`,
                'noopener noreferrer'
              )
            }
          >
            {t('REGISTER_CGU')}
          </Button>
        </Checkbox>
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={t(error)} type="error" showIcon />
        </Form.Item>
      )}

      <Form.Item>
        <Row justify="center">
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('REGISTER_BUTTON')}
          </Button>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row justify="center" align="middle">
          <Text>{t('REGISTER_ALREADY_REGISTER')}</Text>
          <Button type="link" size="small" onClick={() => history.push('/login')}>
            {t('REGISTER_BACK_TO_LOGIN')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

export default RegisterForm;
