import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from '../../components/Form/context';
import ShareholderCreate from '../../ShareholderCreate';

function OperationsShareholders6({ userType, shareholders, canCreate = false, setOptionType = null }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const USER_TYPE = userType.toUpperCase();

  return (
    <>
      <Tooltip title={t(`OPERATION_${USER_TYPE}_TOOLTIP`)}>
        <Form.Item
          name={`${userType}Id`}
          label={t(`OPERATION_${USER_TYPE}`)}
          rules={[{ required: true, message: t(`OPERATION_${USER_TYPE}_MESSAGE_REQUIRED`) }]}
          preserve={false}
        >
          <Select
            options={shareholders?.map((s) => ({ label: s.name, value: s.id }))}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
            onChange={() => (setOptionType ? setOptionType(null) : null)}
          />
        </Form.Item>
      </Tooltip>

      {canCreate && <ShareholderCreate />}
    </>
  );
}

export default OperationsShareholders6;
