import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutAuthentication from '../../components/layout/Authentication';
import { usePrimaryColor } from '../../redux/settings/selectors';
import { clearToken } from '../../services/token';
import LoginForm from './Form';

const { Title } = Typography;

const styles = {
  right: {
    container: { height: '100%' },
  },
  title: { textAlign: 'center', marginBottom: '10%' },
};

function Login() {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  useEffect(() => {
    clearToken();
  }, []);

  return (
    <LayoutAuthentication
      leftImageUrl={`${process.env.PUBLIC_URL}/assets/images/login.svg`}
      leftImageCss={{ width: '90%' }}
    >
      <Row align="middle" style={styles.right.container}>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
          <div style={styles.title}>
            <Title level={1} style={{ color: primaryColor }}>
              {t('LOGIN_TITLE')}
            </Title>
            <Title level={5}>{t('LOGIN_DESCRIPTION')}</Title>
          </div>

          <LoginForm />
        </Col>
      </Row>
    </LayoutAuthentication>
  );
}

export default Login;
