import { getCompany } from '../../api/company';

export const SET_COMPANY = 'SET_COMPANY';

export const setCompany = (company) => ({ type: SET_COMPANY, company });

export const fetchSelectedCompany = (companyId) => async (dispatch) => {
  try {
    const company = await getCompany(companyId);
    dispatch(setCompany(company));
  } catch (err) {
    dispatch(setCompany(null));
  }
};
