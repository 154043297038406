import { Alert, Form } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';
import SelectShareType from '../components/SelectShareType';
import OperationsCreate6x3x1 from './1';
import OperationsCreate6x3x2 from './2';
import OperationsCreate6x3x3 from './3';
import OperationsCreate6x3x4 from './4';
import OperationsCreate6x3x5 from './5';
import OperationsCreate6x3x6 from './6';
import OperationsCreate6x3x7 from './7';
import computeRatio from '../computeRatio';
import disabledShareTypes from '../../disabledShareTypes';

const unavailable = disabledShareTypes();

const children = [
  { shareType: 4, component: <OperationsCreate6x3x1 computeRatio={computeRatio} /> },
  { shareType: 5, component: <OperationsCreate6x3x2 computeRatio={computeRatio} /> },
  { shareType: 7, component: <OperationsCreate6x3x3 computeRatio={computeRatio} /> },
  { shareType: 6, component: <OperationsCreate6x3x4 computeRatio={computeRatio} /> },
  { shareType: 8, component: <OperationsCreate6x3x5 computeRatio={computeRatio} /> },
  { shareType: 10, component: <OperationsCreate6x3x6 /> },
  { shareType: 9, component: <OperationsCreate6x3x7 /> },
];

// Exercice d'options
function OperationsCreate6x3() {
  const { t } = useTranslation();
  const { widthTooltip } = useContext(FormContext);
  const operation = useOperationChild();

  const child = children.find((f) => f.shareType === operation.shareType);

  return (
    <>
      <SelectShareType operations={children} />
      {unavailable.includes(child.shareType) ? (
        <Alert
          style={{ height: '50px', width: `calc(100% + ${widthTooltip}px)` }}
          message={t(`OPERATION_UNAVAILABLE`)}
          type="warning"
        />
      ) : (
        <div>
          <Form.Item hidden>
            <Alert
              style={{ width: `calc(100% + ${widthTooltip}px)` }}
              message={
                <div
                  dangerouslySetInnerHTML={{ __html: t(`OPERATION_${operation.id}_${child.shareType}_DESCRIPTION`) }}
                />
              }
              type="warning"
            />
          </Form.Item>
          {child.component}
        </div>
      )}
    </>
  );
}

export default OperationsCreate6x3;
