import { SafetyCertificateOutlined, BankOutlined, AuditOutlined, BookOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShareholderDetailsCardContent from './component/CardContent';

function CompanyIndivisionData({ companyData, indivisionData }) {
  const { t } = useTranslation();

  return (
    <>
      {companyData && (
        <>
          <ShareholderDetailsCardContent icon={BankOutlined} label={t('COMPANY_NAME')} value={`${companyData.name}`} />
          <ShareholderDetailsCardContent
            icon={SafetyCertificateOutlined}
            label={t('COMPANY_SIREN')}
            value={`${companyData.siren}`}
          />
        </>
      )}
      {indivisionData && (
        <>
          <ShareholderDetailsCardContent
            icon={AuditOutlined}
            label={t('INDIVISION_NAME')}
            value={`${indivisionData.name}`}
          />
          <ShareholderDetailsCardContent
            icon={BookOutlined}
            label={t('SHAREHOLDER_QUALITY')}
            value={`${indivisionData.quality || t('SHAREHOLDER_QUALITY_0')}`}
          />
        </>
      )}
    </>
  );
}

export default CompanyIndivisionData;
