import userApi from '../../api/users';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const setNotifications = (notifications) => ({ type: SET_NOTIFICATIONS, payload: notifications });

export const fetchNotifications = () => async (dispatch) => {
  try {
    const notifications = await userApi.getNotifications();
    dispatch(setNotifications(notifications));
  } catch (err) {
    dispatch(setNotifications([]));
  }
};
