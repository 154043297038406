import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import dateToString from './utils/dateToString';

const MessageHeader = ({ date, sender, recipient }) => {
  const { t } = useTranslation();
  const d = new Date(date);

  return (
    <div className="space-between">
      <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
        <div style={{ display: 'flex' }}>
          <div className="name-header">{t(`MESSAGE_FROM`)} : </div>
          <div>{sender}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="name-header">{t(`MESSAGE_TO`)} : </div>
          <div>{recipient}</div>
        </div>
      </div>
      <div style={{ color: 'black' }}>{dateToString(d)}</div>
    </div>
  );
};

export default MessageHeader;
