import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useSecondColor } from '../redux/settings/selectors';

const Fn = ({ children, title, placement }) => {
  const history = useHistory();
  const secondColor = useSecondColor();

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <Tooltip
      title={
        <div
          onClick={(e) => {
            const targetLink = e.target.closest('a');
            if (!targetLink) return;
            e.preventDefault();
            history.push(e.target.pathname);
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      }
      placement={placement || 'left'}
      color={secondColor}
      overlayStyle={{ width: '100%' }}
    >
      {children}
    </Tooltip>
  );
};

export default React.memo(Fn, (p, n) => p.children === n.children);
