import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormContext from './Form/context';
import { useOperationChild } from '../../../../redux/operation/selectors';

function ShareTypes({ index = null, onChange }) {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  return (
    <Tooltip title={t(`SHARE_TYPE_${useOperationChild().id}_TOOLTIP`)}>
      <Form.Item label={t('SHARE_TYPE')} name={index ? ['v', index, 'shareType'] : ['common', 'shareType']}>
        <Select onChange={onChange} options={[1, 2, 3].map((x) => ({ label: t(`SHARE_TYPE_${x}`), value: x }))} />
      </Form.Item>
    </Tooltip>
  );
}

export default ShareTypes;
