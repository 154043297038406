import { SET_NOTIFICATIONS } from './actions';

const initState = [];

const notifications = (state = initState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default notifications;
