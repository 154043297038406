import { Alert, Button, Form, Input, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authenticationApi from '../../../api/authentication';
import { clearToken, decodeToken } from '../../../services/token';

const { Text } = Typography;

function PasswordResetForm() {
  const history = useHistory();
  const { t } = useTranslation();

  const [[loading, error], setState] = useState([false, null]);

  const onFinish = async (values) => {
    try {
      if (values.password !== values.password_confirmation)
        return setState([false, 'PASSWORD_AND_PASSWORD_CONFIRMATION_ARE_NOT_EQUAL']);
      if (decodeToken().inactive) {
        await authenticationApi.inactiveSignUp(values);
      } else {
        await authenticationApi.passwordReset(values);
      }
      clearToken();
      return history.push('/login/confirmed/password');
    } catch (err) {
      return setState([false, err.message]);
    }
  };

  return (
    <Form initialValues={{ remember: true }} size="large" layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t('USER_PASSWORD')}
        name="password"
        rules={[{ required: true, message: t('USER_PASSWORD_MESSAGE_REQUIRED') }]}
      >
        <Input.Password placeholder={t('USER_PASSWORD_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={t('USER_PASSWORD_CONFIRMATION')}
        name="password_confirmation"
        rules={[{ required: true, message: t('USER_PASSWORD_CONFIRMATION_MESSAGE_REQUIRED') }]}
      >
        <Input.Password placeholder={t('USER_PASSWORD_CONFIRMATION_INPUT_PLACEHOLDER')} />
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={t(error)} type="error" showIcon />
        </Form.Item>
      )}

      <Form.Item>
        <Row justify="center">
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('COMMON_SEND')}
          </Button>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row justify="center" align="middle">
          <Text>{t('PASSWORD_RESET_MY_PASSWORD')}</Text>
          <Button type="link" size="small" onClick={() => history.push('/login')}>
            {t('REGISTER_BACK_TO_LOGIN')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

export default PasswordResetForm;
