import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

export default React.memo(
  ({ name, value, form }) => {
    useEffect(() => {
      form.setFieldsValue({ [name]: value });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <Form.Item hidden name={name} initialValue={value}>
        <Input />
      </Form.Item>
    );
  },
  (p, n) => p.value === n.value
);
