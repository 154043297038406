import {
  ADD_OPERATION_SHAREHOLDER,
  CLOSE_MODAL_CREATE_SHAREHOLDER,
  OPEN_MODAL_CREATE_SHAREHOLDER,
  SET_OPERATION_CHILD,
  SET_OPERATION_PARENT,
  SET_OPERATION_CANCEL,
  SET_OPERATION_SHAREHOLDERS,
  SET_OPERATION_NEXT_STEP,
  SET_OPERATION_DATA,
  ADD_SHAREHOLDER_SELECTED,
  REMOVE_SHAREHOLDER_SELECTED,
  SET_OPERATION_PREVIOUS_STEP,
  CREATE_AND_SELECT_SHAREHOLDER,
  SET_OPERATION_SHAREHOLDERS_V2,
  SET_OPERATIONS_LIST,
} from './actions';

const initState = {
  parent: null,
  child: null,
  showModalCreateShareholder: false,
  shareholders: [],
  shareholdersV2: [],
  queries: {},
  data: { v: [] },
  step: 1,
  operationsList: null,
};

const operation = (state = initState, action) => {
  switch (action.type) {
    case SET_OPERATION_PARENT:
      return {
        ...initState,
        parent: action.operationParent,
        child: action.operationParent.children[0],
      };
    case SET_OPERATION_CHILD:
      return { ...state, child: action.operationChild };
    case OPEN_MODAL_CREATE_SHAREHOLDER:
      return { ...state, showModalCreateShareholder: true };
    case CLOSE_MODAL_CREATE_SHAREHOLDER:
      return { ...state, showModalCreateShareholder: false };
    case SET_OPERATION_SHAREHOLDERS:
      return {
        ...state,
        shareholders: action.shareholders.map((shareholder) => ({
          ...shareholder,
          balance: shareholder.accounts.reduce((prev, curr) => prev + curr.balance, 0),
        })),
      };
    case SET_OPERATION_SHAREHOLDERS_V2:
      return { ...state, shareholdersV2: action.shareholders, queries: action.queries };
    case SET_OPERATIONS_LIST:
      return { ...state, operationsList: [...action.operationsList] };
    case ADD_OPERATION_SHAREHOLDER:
      return { ...state, shareholders: [...state.shareholders, action.shareholder], showModalCreateShareholder: false };
    case SET_OPERATION_CANCEL:
      return {
        ...state,
        step: 1,
        data: {},
        shareholders: state.shareholders.map((shareholder) => ({ ...shareholder, selected: undefined })),
      };
    case SET_OPERATION_NEXT_STEP:
      return { ...state, step: state.step + 1 };
    case SET_OPERATION_PREVIOUS_STEP:
      return { ...state, step: state.step - 1 };
    case SET_OPERATION_DATA: {
      // Remove after V2
      if (action.data.v) {
        const operations = action.data.v && Array.isArray(action.data.v) ? action.data.v : Object.values(action.data.v);
        return {
          ...state,
          data: {
            ...action.data,
            v: operations.filter((f) => f && !f.ignore).map((m) => ({ ...m, ...action.data.common })),
          },
          step: state.step + 1,
        };
      }

      return {
        ...state,
        data: action.data,
        step: state.step + 1,
        description: action.description,
      };
    }
    case ADD_SHAREHOLDER_SELECTED:
      return {
        ...state,
        shareholders: state.shareholders.map((shareholder) =>
          Number(shareholder.id) === action.shareholderId ? { ...shareholder, selected: true } : shareholder
        ),
      };
    case REMOVE_SHAREHOLDER_SELECTED:
      return {
        ...state,
        shareholders: state.shareholders.map((shareholder) =>
          Number(shareholder.id) === Number(action.shareholderId) ? { ...shareholder, selected: false } : shareholder
        ),
      };
    case CREATE_AND_SELECT_SHAREHOLDER:
      return {
        ...state,
        shareholders: [...state.shareholders, { ...action.shareholder, selected: true }],
        showModalCreateShareholder: false,
      };
    default:
      return state;
  }
};

export default operation;
