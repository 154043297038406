import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function ShareholderDetailsCardContent({ icon: Icon, label, value, last = false }) {
  return (
    <div style={{ marginBottom: last ? 0 : 20 }}>
      <Text style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 5 }} type="secondary">
        <Icon style={{ fontSize: 20, marginRight: 8 }} />
        <span style={{ fontSize: 15 }}>{label}</span>
      </Text>
      <div style={{ marginLeft: 28 }}>
        <Text style={{ whiteSpace: 'pre' }} strong>
          {value}
        </Text>
      </div>
    </div>
  );
}

export default ShareholderDetailsCardContent;
