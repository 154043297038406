import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchNotifications } from '../../../redux/notifications/actions';
import toBlockExplorer from '../../../components/toBlockExplorer';

function OperationsSuccess({ response }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const isDraft = response?.operation[0]?.ordernumber < 0;

  return (
    <div>
      <Result
        status="success"
        title={t(isDraft ? 'DRAFT_SUCCESS' : 'OPERATIONS_SUCCESS')}
        extra={
          !isDraft && // check if isDraft
          response?.operation.map((x) => (
            <Button style={{ margin: '8px 8px 0 8px' }} onClick={() => toBlockExplorer(x)}>
              {`${t('BLOCK_EXPLORER_OPERATION')} ${x.ordernumber}`}
            </Button>
          ))
        }
        subTitle={
          <Button
            size="large"
            style={{ margin: '40px 0 0 0' }}
            type="primary"
            key="register"
            onClick={() => history.push('/operations')}
          >
            {t('YOUR_REGISTER')}
          </Button>
        }
      />
    </div>
  );
}

export default OperationsSuccess;
