/* eslint-disable jsx-a11y/anchor-is-valid */
import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Form, Input, Typography, notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../../api/permission';
import TitlePrimary from '../../../../components/TitlePrimary';
import { useCompany } from '../../../../redux/company/selectors';
import RightsDescription from '../Description';

const { Title } = Typography;

function SendInvitationExternal() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const company = useCompany();

  const [visible, setVisible] = useState(false);
  const [{ loading, error }, setState] = useState({ loading: false, error: null });

  const hide = () => setVisible(false);
  const show = () => setVisible(true);
  const send = async (values) => {
    try {
      setState({ loading: true, error: null });
      await permissionApi.sendInvitationShareholder(company.id, 3, { ...values });
      notification.success({ description: t('RIGHTS_HOLDERS_ADD_SUCCESS') });
      hide();
      form.resetFields();
      setState({ loading: false, error: null });
    } catch (e) {
      notification.error({ description: t('RIGHTS_HOLDERS_ADD_ERROR') });
      setState({ loading: false, error: e?.data?.missing?.body });
    }
  };

  return (
    <>
      <Button type="text" icon={<MailOutlined />} onClick={() => show()}>
        {t('TO_INVITE')}
      </Button>
      <Drawer
        title={<TitlePrimary title={t('INVITE_EXTERNAL_TITLE')} />}
        placement="right"
        width={600}
        onClose={hide}
        visible={visible}
        footer={
          <div className="dg-flex dg-justify-between">
            <Button onClick={hide}>{t('COMMON_CANCEL')}</Button>
            <Button type="primary" onClick={() => form.submit()} loading={loading}>
              {t('COMMON_SEND')}
            </Button>
          </div>
        }
        closable
      >
        <Title level={5}>{t('INVITE_EXTERNAL_SUBTITLE')}</Title>

        <Form.Item>{t('INVITE_EXTERNAL_DESCRIPTION_1')}</Form.Item>

        <Form form={form} layout="vertical" onFinish={send}>
          <Form.Item
            label={t('USER_LASTNAME')}
            name="lastname"
            rules={[{ required: true, message: t('USER_LASTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_LASTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item
            label={t('USER_FIRSTNAME')}
            name="firstname"
            rules={[{ required: true, message: t('USER_FIRSTNAME_MESSAGE_REQUIRED') }]}
          >
            <Input placeholder={t('USER_FIRSTNAME_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item
            label={t('USER_EMAIL')}
            name="email"
            rules={[
              { required: true, message: t('USER_EMAIL_MESSAGE_REQUIRED') },
              { type: 'email', message: t('COMMON_EMAIL_BAD_FORMAT') },
            ]}
          >
            <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
          </Form.Item>

          <Form.Item>
            {`${t('INVITE_EXTERNAL_DESCRIPTION_2')} `}
            <RightsDescription>
              <a>{t('NEXT_LINK')}</a>
            </RightsDescription>
            .{` ${t('INVITE_EXTERNAL_DESCRIPTION_3')}`}
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert message={error} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </>
  );
}

export default SendInvitationExternal;
