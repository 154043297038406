import { NotificationOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Badge } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../../../redux/notifications/actions';
import { selectNotifications } from '../../../redux/notifications/selectors';

function Notifications({ color }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    dispatch(fetchNotifications());
    const interval = setInterval(() => dispatch(fetchNotifications()), 300000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const menu = (
    <Menu style={{ minWidth: '250px' }}>
      {notifications.map((notification) => (
        <Menu.Item>
          <strong style={{ fontSize: 15 }}>{t(notification.name)}</strong>
          <div style={{ fontSize: 13 }}>{notification.description}</div>
          <div style={{ color: 'grey', fontSize: 12 }}>
            {t('DATE_AT')} {new Date(notification.date).toLocaleDateString()}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Badge color={color} count={notifications.length} style={{ cursor: 'pointer' }}>
        <NotificationOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
      </Badge>
    </Dropdown>
  );
}

export default Notifications;
