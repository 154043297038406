import { Divider, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../redux/operation/selectors';

const { Title } = Typography;

function OperationsCreateHeader() {
  const { t } = useTranslation();
  const child = useOperationChild();

  return (
    <>
      <div className="dg-operation-description-container">
        <div className="dg-operation-description-content">
          <Title level={5}>{t(`OPERATIONS_${child.id}_LABEL`)}</Title>
          <div dangerouslySetInnerHTML={{ __html: t(`OPERATIONS_${child.id}_DESCRIPTION`) }} />
        </div>
      </div>
      <Divider />
    </>
  );
}

export default OperationsCreateHeader;
