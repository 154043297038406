import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import TitlePrimary from '../../components/TitlePrimary';

function Tutorial({ previous }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsOpen } = useTour();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 200px - 64px - 48px)',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: 30 }}>
        <TitlePrimary title={t('TUTORIAL_TITLE')} />
      </div>
      <div style={{ marginBottom: 30, textAlign: 'center' }}>{t('TUTORIAL_DESCRIPTION_1')}</div>
      <div style={{ marginBottom: 30, textAlign: 'center' }}>{t('TUTORIAL_DESCRIPTION_2')}</div>
      <strong style={{ marginBottom: 30, textAlign: 'center' }}>{t('TUTORIAL_DESCRIPTION_3')}</strong>

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
        <Button onClick={previous}>{t('COMMON_PREVIOUS')}</Button>
        <Button
          type="primary"
          onClick={async () => {
            history.push('/');
            setIsOpen(true);
          }}
        >
          {t('TERMINATE')}
        </Button>
      </div>
    </div>
  );
}

export default Tutorial;
