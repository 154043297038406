import { Button, DatePicker, Form, Input, InputNumber, Radio, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useShareholdersLocal from '../../../../../hooks/useShareholdersLocal';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import OperationsComment from '../../components/v2/Comment';
import FormContext from '../../components/Form/context';
import OperationsShareholders from '../../components/v2/Shareholders';
import CompanyAccounts from '../../components/v2/CompanyAccounts';
import OperationDate from '../../components/Date';
import MaxShares from '../components/MaxShares';
import Name from '../components/Name';
import OptionTypes from '../components/OptionTypes';
import Shares from '../components/Shares';
import ShareTypes from '../components/ShareTypes';

// Emission BSPCE, BSA, SO
function Generic() {
  const { t } = useTranslation();
  const { Tooltip, form } = useContext(FormContext);

  const { id, shareType } = useOperationChild();
  const beneficiaries = useShareholdersLocal('>=');
  const holders = useShareholdersLocal('>', null, null, '1|2|3', 'company');

  const [options1Length, setOptions1Length] = useState(0);

  return (
    <>
      <Name />
      <ShareTypes />
      <OperationDate />
      <OptionTypes />
      {(() => form?.getFieldsValue()?.options?.OPTION_TYPE === 'OPTION_TYPE_2')() && (
        <CompanyAccounts userType="holder" companyAccounts={holders[0]} customName="COMPANY_ACCOUNT" option />
      )}
      <Shares />
      <MaxShares required />

      <Title level={5}>{t('OPERATION_ATTRIBUTION_RADIO_TOOLTIP_TITLE')}</Title>

      <Tooltip title={t(`OPERATION_ATTRIBUTION_RADIO_${shareType}_TOOLTIP`)}>
        <Form.Item
          label={t('OPERATION_ATTRIBUTION_RADIO')}
          name="attribution"
          rules={[{ required: true, message: t('OPERATION_ATTRIBUTION_RADIO_REQUIRED') }]}
        >
          <Radio.Group
            options={[
              { label: t('COMMON_YES'), value: true },
              { label: t('COMMON_NO'), value: false },
            ]}
          />
        </Form.Item>
      </Tooltip>

      <Form.Item shouldUpdate={(prev, curr) => prev.attribution !== curr.attribution} noStyle>
        {({ getFieldValue }) =>
          getFieldValue('attribution') ? (
            <>
              <Tooltip title={t(`OPERATIONS_${id}_${shareType}_ATTRIBUTION_DATE`)}>
                <Form.Item
                  name="date"
                  label={t('OPERATION_ATTRIBUTION_DATE')}
                  rules={[{ required: true, message: t('OPERATION_ATTRIBUTION_DATE_REQUIRED') }]}
                  initialValue={moment()}
                  preserve={false}
                >
                  <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                </Form.Item>
              </Tooltip>

              <OperationsShareholders userType="beneficiary" shareholders={beneficiaries} canCreate />

              <Tooltip title={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE_TOOLTIP`)}>
                <Form.Item
                  label={t(`OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`)}
                  name={['options', `OPERATIONS_${id}_${shareType}_PRICE_EXERCICE`]}
                  preserve={false}
                >
                  <InputNumber />
                </Form.Item>
              </Tooltip>
            </>
          ) : null
        }
      </Form.Item>

      <Title level={5}>{t('OPERATION_CONDITION_VESTING_TITLE')}</Title>
      <Form.Item
        name={['options', 'OPERATION_CONDITION_VESTING']}
        label={t('OPERATION_CONDITION_VESTING')}
        preserve={false}
        initialValue={false}
      >
        <Radio.Group
          options={[
            { label: t('COMMON_YES'), value: true },
            { label: t('COMMON_NO'), value: false },
          ]}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, curr) =>
          prev.options.OPERATION_CONDITION_VESTING !== curr.options.OPERATION_CONDITION_VESTING
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue(['options', 'OPERATION_CONDITION_VESTING']) ? (
            <>
              <Form.Item
                name={['options', 'OPERATION_VESTING_EXIST']}
                label={t('OPERATION_VESTING_EXIST')}
                preserve={false}
                initialValue={false}
              >
                <Radio.Group
                  options={[
                    { label: t('COMMON_YES'), value: true },
                    { label: t('COMMON_NO'), value: false },
                  ]}
                />
              </Form.Item>

              <Form.Item
                shouldUpdate={(prev, curr) =>
                  prev.options.OPERATION_VESTING_EXIST !== curr.options.OPERATION_VESTING_EXIST
                }
                noStyle
              >
                {({ getFieldValue: getFieldValue2 }) =>
                  getFieldValue2(['options', 'OPERATION_VESTING_EXIST']) ? (
                    <>
                      <Form.Item
                        label={t('OPERATION_VESTING_EXIST_YES_1')}
                        name={['options', 'OPERATION_VESTING_EXIST_YES', 0, 'OPERATION_VESTING_EXIST_YES_1']}
                        preserve={false}
                      >
                        <Input />
                      </Form.Item>

                      <Tooltip title={t('OPERATION_VESTING_EXIST_VESTING_1_TOOLTIP')}>
                        <Form.Item
                          label={t('OPERATION_VESTING_EXIST_VESTING_1')}
                          name={['options', 'OPERATION_VESTING_EXIST_VESTING_1']}
                          preserve={false}
                        >
                          <InputNumber />
                        </Form.Item>
                      </Tooltip>

                      <Tooltip title={t('OPERATION_VESTING_EXIST_VESTING_2_TOOLTIP')}>
                        <Form.Item
                          label={t('OPERATION_VESTING_EXIST_VESTING_2')}
                          name={['options', 'OPERATION_VESTING_EXIST_VESTING_2']}
                          preserve={false}
                        >
                          <InputNumber />
                        </Form.Item>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        label={`${t('OPERATION_VESTING_EXIST_NO_2')} 1`}
                        name={['options', 'OPERATION_VESTING_EXIST_NO', 0, 'OPERATION_VESTING_EXIST_NO_2']}
                        initialValue={moment()}
                        preserve={false}
                      >
                        <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                      </Form.Item>
                      <Form.Item
                        label={t('OPERATION_VESTING_EXIST_NO_3')}
                        name={['options', 'OPERATION_VESTING_EXIST_NO', 0, 'OPERATION_VESTING_EXIST_NO_3']}
                        preserve={false}
                      >
                        <InputNumber />
                      </Form.Item>

                      {[...Array(options1Length)].map((_, i) => (
                        <>
                          <Form.Item
                            label={t('OPERATION_VESTING_EXIST_NO_1')}
                            name={['options', 'OPERATION_VESTING_EXIST_NO', i + 1, 'OPERATION_VESTING_EXIST_NO_1']}
                            preserve={false}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={`${t('OPERATION_VESTING_EXIST_NO_2')} ${i + 2}`}
                            name={['options', 'OPERATION_VESTING_EXIST_NO', i + 1, 'OPERATION_VESTING_EXIST_NO_2']}
                            initialValue={moment()}
                            preserve={false}
                          >
                            <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                          </Form.Item>
                          <Form.Item
                            label={t('OPERATION_VESTING_EXIST_NO_3')}
                            name={['options', 'OPERATION_VESTING_EXIST_NO', i + 1, 'OPERATION_VESTING_EXIST_NO_3']}
                            preserve={false}
                          >
                            <InputNumber />
                          </Form.Item>
                        </>
                      ))}

                      <Form.Item>
                        <Space>
                          <Button
                            onClick={() => setOptions1Length((l) => l - 1)}
                            disabled={options1Length === 0}
                            danger
                          >
                            {t('OPERATION_VESTING_EXIST_NO_REMOVE')}
                          </Button>
                          <Button type="primary" onClick={() => setOptions1Length((l) => l + 1)}>
                            {t('OPERATION_VESTING_EXIST_NO_ADD')}
                          </Button>
                        </Space>
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>

              <Form.Item
                name={['options', 'OPERATION_VESTING_EXIST_CLIFF']}
                label={t('OPERATION_VESTING_EXIST_CLIFF')}
                preserve={false}
                initialValue={false}
              >
                <Radio.Group
                  options={[
                    { label: t('COMMON_YES'), value: true },
                    { label: t('COMMON_NO'), value: false },
                  ]}
                />
              </Form.Item>

              <Form.Item
                shouldUpdate={(prev, curr) =>
                  prev.options.OPERATION_VESTING_EXIST_CLIFF !== curr.options.OPERATION_VESTING_EXIST_CLIFF
                }
                noStyle
              >
                {({ getFieldValue: getFieldValue3 }) =>
                  getFieldValue3(['options', 'OPERATION_VESTING_EXIST_CLIFF']) ? (
                    <>
                      <Tooltip title={t('OPERATION_VESTING_EXIST_CLIFF_YES_TOOLTIP')}>
                        <Form.Item
                          label={t('OPERATION_VESTING_EXIST_CLIFF_YES')}
                          name={['options', 'OPERATION_VESTING_EXIST_CLIFF_YES']}
                          preserve={false}
                        >
                          <InputNumber />
                        </Form.Item>
                      </Tooltip>
                    </>
                  ) : (
                    <div />
                  )
                }
              </Form.Item>
            </>
          ) : null
        }
      </Form.Item>

      <Title level={5}>{t('OPERATION_EXERCICE_EVENT_TITLE')}</Title>

      <Form.Item
        name={['options', 'OPERATION_EXERCICE_EVENT']}
        label={t('OPERATION_EXERCICE_EVENT')}
        initialValue={false}
      >
        <Radio.Group
          options={[
            { label: t('COMMON_YES'), value: true },
            { label: t('COMMON_NO'), value: false },
          ]}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, curr) => prev.options.OPERATION_EXERCICE_EVENT !== curr.options.OPERATION_EXERCICE_EVENT}
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue(['options', 'OPERATION_EXERCICE_EVENT']) ? (
            <Tooltip title={t('OPERATION_EXERCICE_EVENT_DESCRIPTION_TOOLTIP')}>
              <Form.Item
                label={t('OPERATION_EXERCICE_EVENT_DESCRIPTION')}
                name={['options', 'OPERATION_EXERCICE_EVENT_DESCRIPTION']}
                preserve={false}
              >
                <Input.TextArea />
              </Form.Item>
            </Tooltip>
          ) : null
        }
      </Form.Item>

      <OperationsComment />
    </>
  );
}

export default Generic;
