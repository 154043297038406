import { Divider, Button, Form, Alert, Result } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import operationsApi from '../../../../api/operation';
import { useCompany } from '../../../../redux/company/selectors';
import { setOperationNextStep, setOperationPreviousStep } from '../../../../redux/operation/actions';
import { useOperationChild, useOperationData, useOperationDescription } from '../../../../redux/operation/selectors';
import ResumeDescription from './Description';
import ResumeDescriptionIndivision from './DescriptionIndivision';
import ResumeDescriptionV2 from './DescriptionV2';
import PreSuccess from '../PreSuccess';
import DraftModal from './DraftModal';

const styles = {
  divider: {
    borderTopWidth: 2,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

function OperationsCreateResume({ file, setResponse }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentOperation = useOperationChild();
  const data = useOperationData();
  const description = useOperationDescription();
  const company = useCompany();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [animation, setAnimation] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const createOperation = async ({ isDraft }) => {
    setTimeout(() => {
      setAnimation(true);
    }, 1000);
    setLoading(true);
    setError(null);
    let op;
    try {
      if (data.v) {
        op = await operationsApi.create(
          currentOperation.id,
          {
            ...data,
            comments: data.comments || '',
            companyId: Number(company.id),
            date: data.date.format('YYYY-MM-DD'),
            v: data.v.map((m) => (m.options ? { ...m, options: JSON.stringify(m.options) } : m)),
          },
          isDraft
        );
      } else
        op = await operationsApi.createV2(
          company.id,
          currentOperation.id,
          {
            ...data,
            comment: data.comment || '',
            date: data.date.format('YYYY-MM-DD'),
            options: JSON.stringify(data.options),
            shareType: currentOperation.shareType || data.shareType,
          },
          isDraft
        );
      setResponse(op);
      try {
        if (file) await operationsApi.uploadFile(company.id, file, op.operationId);
      } catch (e) {
        setError(e.message);
      }

      dispatch(setOperationNextStep());
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setAnimation(false);
    }
  };

  if (loading && animation)
    return (
      <div>
        <PreSuccess />
        <Result icon={<div />} title={t('BLOCKCHAIN_LOADING')} />
      </div>
    );

  return (
    <>
      {data.v?.map((v) =>
        v.shareholderId !== undefined ? (
          <ResumeDescription key={`resume-${Math.random()}`} operation={v} data={data} />
        ) : (
          <ResumeDescriptionIndivision key={`resume-${Math.random()}`} operation={v} data={data} />
        )
      )}

      {Array.isArray(description) && description.map((d) => <ResumeDescriptionV2 description={d} />)}

      {error && (
        <Form.Item>
          <Alert message={t(error)} type="error" showIcon />
        </Form.Item>
      )}

      <Divider style={styles.divider} />
      <div style={styles.actions}>
        <Button onClick={() => dispatch(setOperationPreviousStep())}>{t('COMMON_PREVIOUS')}</Button>
        <Button type="primary" onClick={() => setModalVisible(true)} loading={loading}>
          {t('COMMON_NEXT')}
        </Button>
      </div>
      <DraftModal createOperation={createOperation} modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </>
  );
}

export default OperationsCreateResume;
