import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Modal, Radio, Col, Row, Table, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../../redux/company/selectors';
import { useOperationChild } from '../../../../redux/operation/selectors';
import permissionApi from '../../../../api/permission';
import companyApi from '../../../../api/company';
import Subscribe from '../../../../components/Payment/Subscribe';

const unavailableDraftOperations = [
  18, // tranformation
];

const RowMiddle = ({ children }) => (
  <Row align="middle" justify="center">
    {children}
  </Row>
);

const ChoiceButton = ({ action, choices, defaultValue }) => (
  <RowMiddle>
    <Radio.Group defaultValue={defaultValue} style={{ margin: 12 }} onChange={(x) => action(x.target.value)}>
      {choices.map((x) => (
        <Radio.Button value={x.value}>{x.label}</Radio.Button>
      ))}
    </Radio.Group>
  </RowMiddle>
);

export default ({ createOperation, modalVisible, setModalVisible }) => {
  const { t } = useTranslation();
  const company = useCompany();
  const isDraftAvailable = !unavailableDraftOperations.includes(useOperationChild().id);

  const [isDraft, setIsDraft] = useState(isDraftAvailable);
  const [users, setUsers] = useState([]);
  const [isNotified, setIsNotified] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [subscribeCompany, setSubscribeCompany] = useState(false);

  const fetchUsers = useCallback(() => {
    permissionApi
      .getPermissionHolders(company.id)
      .then((x) => x.filter((o) => o.accountactive === true))
      .then(setUsers)
      .catch((e) => {
        setUsers([]);
        notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') });
      });
  }, [company, t]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (isNotified === false) setSelectedUsers([]);
  }, [isNotified]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      okText={isDraft ? t('VALIDATE_DRAFT') : t('VALIDATE_BLOCK')}
      // eslint-disable-next-line consistent-return
      onOk={async () => {
        if (isDraft && selectedUsers.length)
          companyApi.notify(
            company.id,
            selectedUsers.map((id) => ({ id, email: users.find((x) => x.userid === id).email }))
          );
        if (!isDraft) {
          const plan = await companyApi.planStats(company.id);
          if (!plan.isAnchorAllowed) return setSubscribeCompany(company);
        }
        createOperation({ isDraft });
      }}
      closable
      width="50vw"
    >
      <Col>
        {isDraftAvailable && <RowMiddle>{t('OPERATIONS_MODAL_TEXT')}</RowMiddle>}
        <ChoiceButton
          defaultValue={isDraft}
          action={setIsDraft}
          choices={[
            isDraftAvailable && { value: true, label: t('OPERATIONS_DRAFT') },
            { value: false, label: t('OPERATIONS_BLOCK') },
          ].filter((x) => x)}
        />
        {isDraft ? (
          <div>
            <RowMiddle>{t('OPERATIONS_DRAFT_TEXT')}</RowMiddle>
            <Divider />
            <RowMiddle>{t('OPERATIONS_DRAFT_TEXT_NOTIFICATION')}</RowMiddle>
            <ChoiceButton
              defaultValue={isNotified}
              action={setIsNotified}
              choices={[
                { value: true, label: t('YES') },
                { value: false, label: t('NO') },
              ]}
            />
            {isNotified ? (
              <Table
                dataSource={users.map((x) => ({ ...x, key: x.userid }))}
                columns={[
                  {
                    title: t('FIRSTNAME_AND_NAME'),
                    render: (user) => `${user.firstname || ''} ${user.lastname || ''}`,
                  },
                  { title: t('USER_EMAIL'), dataIndex: 'email' },
                ]}
                rowSelection={{
                  selectedRowKeys: selectedUsers,
                  onChange: setSelectedUsers,
                }}
              />
            ) : (
              <div />
            )}
          </div>
        ) : (
          <RowMiddle>{t('OPERATIONS_BLOCK_TEXT')}</RowMiddle>
        )}
      </Col>
      <Subscribe refresh={() => {}} company={subscribeCompany} resetCompany={setSubscribeCompany} singleTimeFormula />
    </Modal>
  );
};
