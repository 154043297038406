import request, { requestBlob } from '../services/request';

const uploadFile = async (companyId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return request(`/company/${companyId}/news/file`, 'POST', formData, true);
};

const create = async (companyId, news) => request(`/company/${companyId}/news`, 'POST', news);
const remove = async (companyId, id) => request(`/company/${companyId}/news?id=${id}`, 'DELETE');
const findAll = async (companyId) => request(`/company/${companyId}/news`);
const findOne = async (companyId, newsId) => request(`/company/${companyId}/news?id=${newsId}`);
const getFile = async (companyId, fileId) => requestBlob(`/company/${companyId}/news/${fileId}`);

const newsApi = {
  uploadFile,
  create,
  findAll,
  findOne,
  getFile,
  remove,
};

export default newsApi;
