/* eslint-disable react/no-array-index-key */
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Collapse, Button, InputNumber, Input } from 'antd';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ShareholderType from './Type';
import OperationsShares from '../Shares';
import ShareholdersSelect from './Select';
import useShareholders from '../../../../../hooks/useShareholders';
import FormContext from '../Form/context';
import ShareholderCreate from '../../ShareholderCreate';

const { Panel } = Collapse;

function OperationsCreateShareholdersCreation() {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);

  const shareholders = useShareholders();
  const [selected, setSelected] = useState([]);

  return (
    <>
      {selected.length > 0 && (
        <Form.Item>
          <Collapse
            key={selected.length}
            bordered={false}
            expandIconPosition="right"
            activeKey={selected.map((x) => `${x.id}`)}
          >
            {selected.map((shareholder) => {
              const { id } = shareholder;

              return (
                <Panel
                  key={`${id}`}
                  showArrow={false}
                  header={
                    <div>
                      {shareholder.name}
                      <div style={{ float: 'right', width: '%' }}>
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setSelected(selected.filter((x) => x.id !== id));
                            form.setFieldsValue({
                              v: form.getFieldValue().v.filter((x, i) => i !== id),
                            });
                          }}
                          danger
                        />
                      </div>
                    </div>
                  }
                  forceRender
                >
                  <div>
                    <ShareholderType index={id} />
                    <OperationsShares index={id} />
                    <Form.Item name={['v', id, 'indivisionId']} initialValue={id} hidden>
                      <InputNumber />
                    </Form.Item>
                    <Form.Item name={['v', id, 'symbol']} initialValue="+" hidden>
                      <Input />
                    </Form.Item>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        </Form.Item>
      )}

      <ShareholdersSelect
        label={t('SHAREHOLDERS_SELECT')}
        tooltip="SHAREHOLDERS_SELECT_TOOLTIP"
        shareholders={shareholders.filter((x) => !selected.includes(x))}
        addShareholder={(x) => setSelected([...selected, x])}
      />

      <ShareholderCreate />
    </>
  );
}

export default OperationsCreateShareholdersCreation;
