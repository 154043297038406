import { Row, Col, Typography, Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationShareholders } from '../../../../redux/operation/selectors';

const { Text } = Typography;

const styles = {
  container: {
    padding: 16,
    marginBottom: 16,
    borderRadius: 5,
    background: '#F4F5F7',
  },
};

function ResumeDescription({ operation, data }) {
  const { t } = useTranslation();

  const shareholders = useOperationShareholders();
  const shareholder = shareholders.find((s) => Number(s.id) === Number(operation.shareholderId));
  if (!shareholder) return <div>Error</div>;
  const account = shareholder.accounts.find((a) => a.accountNumber === operation.accountNumber);

  return (
    <div style={styles.container}>
      <Row gutter={[16, 16]}>
        <Col span={6}>{t('SHARE_TYPE')}</Col>
        <Col span={6}>
          <Text strong>{t(`SHARE_TYPE_${account.shareType}`)}</Text>
        </Col>

        <Col span={6}>{t('OPERATIONS_DATE')}</Col>
        <Col span={6}>
          <Text strong>{data.date.format('DD/MM/YYYY')}</Text>
        </Col>

        <Col span={6}>{t('SHAREHOLDER')}</Col>
        <Col span={6}>
          <Text strong>{shareholder.name}</Text>
        </Col>

        <Col span={6}>{t('OPERATION_SHARES')}</Col>
        <Col span={6}>
          <Text strong>{`${operation.symbol === '-' ? `-${operation.shares}` : operation.shares}`}</Text>
        </Col>

        <Col span={6}>{t('ACCOUNT_TYPE')}</Col>
        <Col span={6}>
          <Text strong>{t(`ACCOUNT_TYPE_${account.accountType}`)}</Text>
        </Col>

        <Col span={6}>{t('OPERATION_NOMINAL_VALUE')}</Col>
        <Col span={6}>
          <Text strong>{data.nominalValue || t('COMMON_NOT_SPECIFIED')}</Text>
        </Col>

        <Divider style={{ margin: 0 }} />

        <Col span={24}>
          {`${t('OPERATIONS_COMMENT')} : `}
          <span style={{ fontStyle: 'italic' }}>{data.comments}</span>
        </Col>
      </Row>
    </div>
  );
}

export default ResumeDescription;
