import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getPermissionUser } from '../api/permission';

const usePermission = (companyId) => {
  const { t } = useTranslation();
  const [type, setType] = useState(null);

  useEffect(() => {
    if (companyId)
      getPermissionUser(companyId)
        .then((x) => setType(x?.type))
        .catch(() => notification.error({ description: t('PERMISSION_FETCH_ERROR') }));
  }, [companyId, t]);

  return type;
};

export default usePermission;
