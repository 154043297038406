import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import operationsApi from '../../../api/operation';
import { useCompany } from '../../../redux/company/selectors';
import { useUserId } from '../../../redux/user/selectors';
import usePermission from '../../../hooks/usePermission';
// eslint-disable-next-line import/no-cycle
import OperationsListTable from './Table';
import Loading from '../../../components/Loading';
import { useOperationsList } from '../../../redux/operation/selectors';
import { setOperationsList } from '../../../redux/operation/actions';

function OperationsList({ invert, setInvert }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const userId = useUserId();
  const company = useCompany();
  const type = usePermission(company?.id);

  const operations = useOperationsList();

  const [refresh, setRefresh] = useState(false);
  const [fileSelectorVisible, setFileSelectorVisible] = useState(null);

  useEffect(() => {
    (async () => {
      let draftOperations;
      try {
        draftOperations =
          !setInvert &&
          (await operationsApi.getDraftOperations(company.id).then((x) => ({
            SHARE_TYPE: 'DRAFT',
            extended: x,
            restricted: [],
          })));
      } catch (e) {
        // case if user permission is 3 or 4 : do not shows drafts.
        if (e.code === 403) draftOperations = null;
      }
      operationsApi
        .getAll(userId)
        .then((x) => dispatch(setOperationsList(draftOperations ? [draftOperations, ...x] : x)));
    })();
  }, [dispatch, userId, company.id, refresh, setInvert]);

  if (!operations) return <Loading />;

  return (
    <Row gutter={[16, 16]}>
      {operations &&
        ((op) => {
          const filtered = op.filter(
            (o) =>
              o.extended.some((x) => x.companyid === company.id) || o.restricted.some((x) => x.companyid === company.id)
          );
          if (filtered.length === 0)
            return (
              <Row justify="center" align="middle" style={{ width: '100%', marginTop: '50px' }}>
                <Col>
                  <Alert message={t('NO_REGISTER')} description={t('NO_REGISTER_DESC')} type="warning" showIcon />
                </Col>
              </Row>
            );
          return filtered.map((o, i) => (
            <Col span={24}>
              <Card
                title={t(`OPERATIONS_LIST_${o.SHARE_TYPE}`)}
                extra={
                  <Button
                    className="react-tour-5"
                    onClick={() => {
                      if (type === 1 || type === 2) setFileSelectorVisible(i);
                      else
                        history.push({
                          pathname: '/message',
                          state: { isFileRequest: true },
                        });
                    }}
                    icon={<DownloadOutlined />}
                    type="text"
                    size="large"
                  >
                    {t(type === 1 || type === 2 ? 'EXPORT' : 'EXPORT_REQUEST')}
                  </Button>
                }
              >
                <OperationsListTable
                  refresh={() => setRefresh(!refresh)}
                  operation={o}
                  setInvert={
                    setInvert
                      ? (n) => {
                          setInvert({
                            ...invert,
                            [i]: n,
                          });
                        }
                      : undefined
                  }
                  isFileSelectorVisible={fileSelectorVisible === i}
                  setIsFileSelectorVisible={setFileSelectorVisible}
                />
              </Card>
            </Col>
          ));
        })(operations)}
    </Row>
  );
}

export default OperationsList;
