import React from 'react';
import OperationsCreate1x1 from '../../containers/Operations/Create/1/1';
import OperationsCreate1x2 from '../../containers/Operations/Create/1/2';
import OperationsCreate1x3 from '../../containers/Operations/Create/1/3';
import OperationsCreate1x4 from '../../containers/Operations/Create/1/4';
import OperationsCreate1x5 from '../../containers/Operations/Create/1/5';
import OperationsCreate1x6 from '../../containers/Operations/Create/1/6';
import OperationsCreate1x7 from '../../containers/Operations/Create/1/7';
import OperationsCreate2x1 from '../../containers/Operations/Create/2/1';
import OperationsCreate3x1 from '../../containers/Operations/Create/3/1';
import OperationsCreate3x2 from '../../containers/Operations/Create/3/2';
import OperationsCreate3x3 from '../../containers/Operations/Create/3/3';
import OperationsCreate4x1 from '../../containers/Operations/Create/4/1';
import OperationsCreate4x2 from '../../containers/Operations/Create/4/2';
import OperationsCreate5x1 from '../../containers/Operations/Create/5/1';
import OperationsCreate5x2 from '../../containers/Operations/Create/5/2';
import OperationsCreate6x1 from '../../containers/Operations/Create/6/1';
import OperationsCreate6x2x1 from '../../containers/Operations/Create/6/2';
import OperationsCreate6x3 from '../../containers/Operations/Create/6/3';
import OperationsCreate6x4x1 from '../../containers/Operations/Create/6/4';
// eslint-disable-next-line import/no-cycle
import OperationsCreate7x1 from '../../containers/Operations/Create/7/1';

const operations = [
  // Opération sur la capital
  {
    id: 1,
    children: [
      // Constitution de la société (souscription initiale)
      { id: 1, component: <OperationsCreate1x1 /> },
      // Augmentation de capital
      { id: 2, component: <OperationsCreate1x2 /> },
      // Réduction de capital
      { id: 3, component: <OperationsCreate1x3 /> },
      // Cession
      { id: 4, component: <OperationsCreate1x4 /> },
      // Mutation à titres gratuit
      { id: 5, component: <OperationsCreate1x5 /> },
      // Multiplication du nombre de titres
      { id: 6, component: <OperationsCreate1x6 /> },
      // Division du nombre de titres
      { id: 7, component: <OperationsCreate1x7 /> },
    ],
  },

  // Transformation
  {
    id: 2,
    children: [
      // Transformation de la société
      { id: 18, component: <OperationsCreate2x1 /> },
    ],
  },

  // Sûreté
  {
    id: 3,
    children: [
      // Nantissement
      { id: 9, component: <OperationsCreate3x1 /> },
      // Extinction du nantissement
      { id: 10, component: <OperationsCreate3x2 /> },
      // Réalisation du nantissement
      { id: 8, component: <OperationsCreate3x3 /> },
    ],
  },

  // Saisie sur valeur mobilière
  {
    id: 4,
    children: [
      // Saisie de droits d'associés
      { id: 12, component: <OperationsCreate4x1 /> },
      // Main levé saisie
      { id: 13, component: <OperationsCreate4x2 /> },
    ],
  },

  // Démembrement
  {
    id: 5,
    children: [
      // Démembrement de propriété
      { id: 11, component: <OperationsCreate5x1 /> },
      // Reconstitution de pleine propriété
      { id: 17, component: <OperationsCreate5x2 /> },
    ],
  },

  // Accès différé au capital
  {
    id: 6,
    children: [
      // Emission d'options
      { id: 15, component: <OperationsCreate6x1 />, shareType: 4 },
      // Attribution d'options existantes
      { id: 14, component: <OperationsCreate6x2x1 />, shareType: 1 },
      // Exercice d'options
      { id: 19, component: <OperationsCreate6x3 />, shareType: 4 },
      // Annulation d'options
      { id: 20, component: <OperationsCreate6x4x1 /> },
    ],
  },
  {
    id: 7,
    children: [
      // Inversion d'opération
      { id: 21, component: <OperationsCreate7x1 /> },
    ],
  },
];

export default operations;
