import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutAuthentication from '../../../components/layout/Authentication';
import { usePrimaryColor } from '../../../redux/settings/selectors';
import PasswordForgotForm from './Form';

const { Title } = Typography;

const styles = {
  right: {
    container: { height: '100%' },
  },
  title: { textAlign: 'center', marginBottom: '10%' },
};

function PasswordForgot() {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  return (
    <LayoutAuthentication
      leftImageUrl={`${process.env.PUBLIC_URL}/assets/images/password_forgot.svg`}
      leftImageCss={{ width: '70%' }}
    >
      <Row align="middle" style={styles.right.container}>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
          <div style={styles.title}>
            <Title level={1} style={{ color: primaryColor }}>
              {t('PASSWORD_FORGOT_TITLE')}
            </Title>
            <Title level={5}>{t('PASSWORD_FORGOT_DESCRIPTION')}</Title>
          </div>
          <PasswordForgotForm />
        </Col>
      </Row>
    </LayoutAuthentication>
  );
}

export default PasswordForgot;
