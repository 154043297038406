import { CheckCircleFilled, CloseCircleFilled, AuditOutlined, UserOutlined, BankOutlined } from '@ant-design/icons';
import { Card, Table, Tag, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../../api/permission';
import { useCompany } from '../../../../redux/company/selectors';
import { useSecondColor, useGreenColor, useRedColor } from '../../../../redux/settings/selectors';
import RightsDescription from '../Description';
import SendInvitation from './SendInvitation';

const selectType = (x, company, indivision, user) => {
  if (x.isCompany) return company;
  if (x.isIndivision) return indivision;
  return user;
};

function RightsShareholders() {
  const { t } = useTranslation();

  const company = useCompany();
  const green = useGreenColor();
  const red = useRedColor();
  const secondColor = useSecondColor();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (company)
      permissionApi
        .getPermissionShareholders(company.id)
        .then((x) =>
          setUsers(
            x
              .map((o) => ({
                isIndivision: `${o.lastname} ${o.firstname}` !== o.shareholder,
                ...o,
              }))
              .sort((a, b) => (a.shareholder > b.shareholder ? 1 : -1))
          )
        )
        .catch(() => setUsers([]));
  }, [company]);

  return (
    <Card title={t('COMPANY_SHAREHOLDERS')} extra={<RightsDescription />}>
      <Table
        columns={[
          {
            title: t('SHAREHOLDER'),
            render: (user) => (
              <div>
                <Popover content={selectType(user, t('COMPANY'), t('INDIVISION'), t('SHAREHOLDER'))}>
                  <Tag color={secondColor}>
                    {selectType(user, <BankOutlined />, <AuditOutlined />, <UserOutlined />)}
                  </Tag>
                </Popover>
                {user.shareholder}
              </div>
            ),
          },
          { title: t('USERS_ROLE'), dataIndex: 'role', render: (role) => t(role) },
          { title: t('CAN_READ'), dataIndex: 'readRight', render: (read) => t(read) },
          {
            title: t('ACCOUNT_STATE'),
            dataIndex: 'account',
            render: (state) =>
              state ? (
                <CheckCircleFilled style={{ color: green, fontSize: 20 }} />
              ) : (
                <CloseCircleFilled style={{ color: red, fontSize: 20 }} />
              ),
          },
          // { title: t('LAST_SEND'), dataIndex: 'lastsent', render: (date) => new Date(date).toLocaleDateString() },
          {
            title: t('ACCOUNT_EMAIL_ACTIVATION'),
            render: (user) => (user.isCompany ? <div /> : <SendInvitation user={user} />),
          },
        ]}
        dataSource={users}
      />
    </Card>
  );
}

export default RightsShareholders;
