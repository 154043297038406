import React from 'react';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsAccounts from '../components/v2/Accounts';
import OperationsShares from '../components/v2/Shares';
import OperationsShareholders from '../components/v2/Shareholders';
import useShareholdersLocal from '../../../../hooks/useShareholdersLocal';

// Mutation à titres gratuit
function OperationsCreate1x5() {
  const holders = useShareholdersLocal('>');
  const beneficiaries = useShareholdersLocal('>=');

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />
      <OperationsShareholders userType="beneficiary" shareholders={beneficiaries} canCreate />
      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate1x5;
