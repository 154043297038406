import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useLogo } from '../../redux/settings/selectors';
import isValidId from './utils/isValidId';

const { Search } = Input;

const SearchEntry = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const logo = useLogo();

  return (
    <div>
      <div style={{ margin: '37px 0 0 37px' }}>
        <img src={logo} alt="logo" width="210px" />
      </div>
      <div
        style={{
          height: '6vh',
          width: '50%',
          margin: 'calc(49vh - 13%) auto',
        }}
      >
        <Typography.Title
          style={{ textAlign: 'center', width: '100%', height: '100%', fontSize: 'calc(1.2vw + 15px)' }}
        >
          <img src="/ethereum.svg" alt="logo" height="80%" style={{ margin: '0 15px 0 0' }} />
          {t('BLOCK_EXPLORER_TITLE')}
        </Typography.Title>
      </div>
      <div className="center" style={{ width: '35%' }}>
        <Search
          size="large"
          placeholder={t('BLOCK_EXPLORER_PLACEHOLDER')}
          onSearch={(s) => {
            if (isValidId(s)) history.push(`/blockexplorer/${s}`);
            else notification.warning({ description: t('BLOCK_EXPLORER_INVALID_ARG'), duration: 8 });
          }}
        />
      </div>
    </div>
  );
};

export default SearchEntry;
