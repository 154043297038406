import { Collapse, Form, Table } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../Form/context';
import OperationsShareholders from './Shareholders';

const { Panel } = Collapse;

function OperationsAccounts({ userType, canCreate = false, shareholders }) {
  const { t } = useTranslation();
  const { Tooltip, form } = useContext(FormContext);

  const operation = useOperationChild();

  const USER_TYPE = userType.toUpperCase();

  const columns = [
    { title: t('SHARE_TYPE'), dataIndex: 'shareType', render: (shareType) => t(`SHARE_TYPE_${shareType}`) },
    { title: t('ACCOUNT_TYPE'), dataIndex: 'accountType', render: (accountType) => t(`ACCOUNT_TYPE_${accountType}`) },
    {
      title: t('ACCOUNT_STATE'),
      dataIndex: 'accountState',
      render: (accountType) => t(`ACCOUNT_STATE_${accountType}`),
    },
    { title: t('OPERATION_ACCOUNT_NUMBER'), dataIndex: 'accountNumber' },
    { title: t('ACCOUNT_BALANCE'), dataIndex: 'balance' },
  ];

  const Accounts = ({ onChange, value }) => {
    const shareholderId = form.getFieldValue(`${userType}Id`);
    const shareholder = shareholders.find((s) => s.id === shareholderId);
    const accounts = shareholder ? shareholder.accounts : [];
    const key = shareholderId || -1;

    return (
      <Collapse key={key} defaultActiveKey={key} bordered={false} expandIconPosition="right">
        <Panel header={shareholder ? shareholder.name : null} key={key} forceRender>
          <Table
            rowKey="accountNumber"
            dataSource={accounts}
            columns={columns}
            rowSelection={{
              type: 'radio',
              onChange: (array) => onChange(array[0] || null),
              defaultSelectedRowKeys: [value],
            }}
            scroll={{ x: true }}
          />
        </Panel>
      </Collapse>
    );
  };

  return (
    <>
      <OperationsShareholders userType={userType} shareholders={shareholders} canCreate={canCreate} />

      <Tooltip title={t(`OPERATION_${operation.id}_${USER_TYPE}_ACCOUNTS_TOOLTIP`)} placement="right">
        <Form.Item
          name={`${userType}AccountId`}
          label={t('SHAREHOLDERS_SELECT_ACCOUNT')}
          rules={[{ required: true, message: t(`OPERATION_${USER_TYPE}_ACCOUNTS_MESSAGE_REQUIRED`) }]}
          labelCol={{ span: 24 }}
          preserve={false}
        >
          <Accounts />
        </Form.Item>
      </Tooltip>
    </>
  );
}

export default OperationsAccounts;
