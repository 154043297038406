import { Form, Input } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';
import OperationsAccounts from '../components/v2/Accounts';
import useShareholders from '../../../../hooks/useShareholders';
import FormContext from '../components/Form/context';

// Nantissement
function OperationsCreate3x1() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const holders = useShareholders('>', 1);

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />

      <Tooltip title={t('OPERATION_BENEFICIARY_NANT_TOOLTIP')}>
        <Form.Item name={['options', 'OPERATION_BENEFICIARY']} label={t('OPERATION_BENEFICIARY_NANT')}>
          <Input />
        </Form.Item>
      </Tooltip>

      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate3x1;
