import React from 'react';
import { useLocation } from 'react-router-dom';
import { Descriptions, Divider, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import OperationType from '../../components/OperationType';

const { Item } = Descriptions;

const style = { fontWeight: 'bold', fontStyle: 'italic' };

const DataDisplay = ({ data }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const isDecision = new URLSearchParams(location.search).get('type') === 'decision';

  if (!data.operation[0] && !data.block) return <Result status="warning" title={t('BLOCKCHAIN_NO_DATA')} />;

  return (
    <div>
      {data.operation[0] &&
        data.operation.map((x) => (
          <Descriptions
            title={<div style={{ margin: '30px' }}>{t('BLOCK_EXPLORER_OPERATION_TITLE')}</div>}
            column={1}
            bordered
          >
            <Item label={isDecision ? 'Numéro de PV' : "Numéro d'ordre"}>{x.values.orderNumber}</Item>
            <Item label={isDecision ? 'Type de PV' : 'Nature du mouvement'}>
              <div style={{ width: '30%' }}>
                {isDecision ? t(`REGISTER_TYPE_${x.values.orderType}`) : <OperationType type={x.values.orderType} />}
              </div>
            </Item>
            <Item label="Signature Digistre" labelStyle={style}>
              {x.values.signature}
            </Item>
            <Item label="Time">
              {((time) => {
                const d = new Date(time);
                return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
              })(x.values.time)}
            </Item>
            <Divider />
            <Item label="Event">{x.data.event}</Item>
            <Item label="Address">{x.data.address}</Item>
            <Item label="Transaction hash">{x.data.transactionHash}</Item>
            <Item label="Id">{x.data.id}</Item>
            <Item label="Signature">{x.data.signature}</Item>
          </Descriptions>
        ))}
      {data.block && (
        <Descriptions
          title={<div style={{ margin: '30px' }}>{t('BLOCK_EXPLORER_BLOCK_TITLE')}</div>}
          column={1}
          bordered
        >
          <Item label="Number" labelStyle={style}>
            {data.block.number}
          </Item>
          <Item label="Hash" labelStyle={style}>
            {data.block.hash}
          </Item>
          <Item label="Parent hash">{data.block.parentHash}</Item>
          <Item label="Receipts root">{data.block.receiptsRoot}</Item>
          <Item label="Size">{data.block.parentHash}</Item>
          <Item label="Timestamp">{data.block.timestamp}</Item>
          <Item label="Transactions root">{data.block.transactionsRoot}</Item>
          <Item label="Transactions">{JSON.stringify(data.block.transactions)}</Item>
        </Descriptions>
      )}
      <div style={{ margin: '30px' }} />
    </div>
  );
};

export default DataDisplay;
