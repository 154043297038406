function mapRec(x, fn) {
  if (Array.isArray(x)) return x.map((v) => mapRec(v, fn));
  if (x && typeof x === 'object') return Object.fromEntries(Object.entries(x).map(([k, v]) => [k, mapRec(v, fn)]));
  return fn(x);
}

export default (data) => {
  const companyName = localStorage.getItem('company_name');
  return mapRec(data, (d) => (d === 'COMPANY_NAME_CURRENT' ? companyName || d : d));
};
