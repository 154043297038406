import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Col, Row, Table, Button } from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DecisionAddModal from './DecisionAddModal';
import DecisionAnchorModal from './DecisionAnchorModal';
import { getDecisions } from '../../api/decision';
import { useCompany } from '../../redux/company/selectors';
import { requestFile } from '../../services/request';
import toBlockExplorer from '../../components/toBlockExplorer';

const DecisionRegister = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const company = useCompany();

  const [isVisible, setIsVisible] = useState(false);
  const [anchorValues, setAnchorValues] = useState(false);
  const [decisions, setDecisions] = useState([]);

  useEffect(() => {
    getDecisions(company.id, id).then(setDecisions);
  }, [company, id, isVisible, anchorValues]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          title={t(`REGISTER_TYPE_${state.type}`)}
          extra={
            <Button type="primary" onClick={() => setIsVisible(true)} icon={<PlusOutlined />}>
              {t('ADD_DECISION')}
            </Button>
          }
        >
          <Table
            columns={[
              { title: t('DECISION_ID'), dataIndex: 'id', render: (_, __, i) => i + 1 },
              { title: t('DECISION_DATE'), dataIndex: 'date', render: (date) => new Date(date).toLocaleDateString() },
              { title: t('DECISION_DESC'), dataIndex: 'description' },
              {
                title: t('DECISION_USER'),
                dataIndex: 'user',
                render: (user) => `${user.firstname || ''} ${user.lastname || ''}`,
              },
              {
                title: t('DECISION_IS_ANCHOR'),
                dataIndex: 'isdraft',
                render: (isDraft) =>
                  !isDraft ? (
                    <CheckCircleFilled style={{ color: 'green', fontSize: 20 }} />
                  ) : (
                    <CloseCircleFilled style={{ color: 'red', fontSize: 20 }} />
                  ),
              },
              {
                title: t('DECISION_BLOCKCHAIN'),
                dataIndex: 'blockchain',
                render: (_, record) =>
                  record.isdraft ? (
                    <ClockCircleOutlined />
                  ) : (
                    <button
                      className="zoom ethereum"
                      style={{ marginLeft: '0px' }}
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toBlockExplorer(
                          {
                            id: record.id,
                            type: state.type,
                            description: record.description,
                            fileId: record.fileid,
                            fileName: record.filename,
                            date: record.date,
                          },
                          'decision'
                        );
                      }}
                    >
                      <img width="20px" src="/ethereum.svg" alt="ethereum" />
                    </button>
                  ),
              },
              {
                title: t('DECISION_FILE'),
                dataIndex: 'file',
                render: (file) => (
                  <Button
                    onClick={() => requestFile(`/company/${company.id}/act/${file.uuid}`, file.name)}
                    icon={<DownloadOutlined />}
                    type="text"
                  >
                    {t('DOWNLOAD')}
                  </Button>
                ),
              },
            ]}
            dataSource={decisions}
            rowClassName="dg-cursor"
            onRow={(r) => ({
              onClick: () => r.isdraft && setAnchorValues(r),
            })}
          />
        </Card>
        <DecisionAddModal registerId={id} isVisible={isVisible} hide={() => setIsVisible(false)} />
        <DecisionAnchorModal registerId={id} anchorValues={anchorValues} hide={() => setAnchorValues(null)} />
      </Col>
    </Row>
  );
};

export default DecisionRegister;
