import React from 'react';
import { useTranslation } from 'react-i18next';

const colors = {
  1: '#FF7F78',
  2: '#FF7F78',
  3: '#FF7F78',
  4: '#FF7F78',
  5: '#FF7F78',
  6: '#FF7F78',
  7: '#FF7F78',
  8: '#FFC978',
  9: '#FFC978',
  10: '#FFC978',
  11: '#A467E2',
  17: '#A467E2',
  12: '#5FCE75',
  13: '#5FCE75',
  14: '#242351',
  15: '#242351',
  16: '#242351',
  19: '#242351',
  20: '#242351',
  18: '#78AEFF',
  21: '#00B395',
};

function OperationType({ type }) {
  const { t } = useTranslation();
  if (!type) return null;
  return (
    <div style={{ padding: '3px 5px', border: `1px solid ${colors[type]}`, textAlign: 'center', color: colors[type] }}>
      {t(`OPERATIONS_${type}_SHORT_LABEL`)}
    </div>
  );
}

export default OperationType;
