import { combineReducers } from 'redux';
import company from './company/reducer';
import operation from './operation/reducer';
import settings from './settings/reducer';
import user from './user/reducer';
import notifications from './notifications/reducer';

const rootReducer = combineReducers({ settings, user, operation, company, notifications });

export default rootReducer;
