// @ts-nocheck
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Table, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import companyApi from '../../../api/company';
import { updateIndivision } from '../../../api/indivision';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor } from '../../../redux/settings/selectors';
import { numberWithCommas } from '../../../services/number';
import usePermission from '../../../hooks/usePermission';
import FileSelector from '../../../components/FileSelector';

const { Text } = Typography;

function CompanyCapitalTable() {
  const history = useHistory();

  const { t } = useTranslation();
  const company = useCompany();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const type = usePermission(company?.id);

  const [stats, setStats] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [qualities, setQualities] = useState({});
  const [isFileSelectorVisible, setIsFileSelectorVisible] = useState(false);

  useEffect(() => {
    companyApi
      .getShareholdersStatsByCompanyId(company.id)
      .then(setStats)
      .catch(() => setStats(null));
  }, [company.id, company.name]);

  if (!stats) return null;

  const [shareholders, total] = stats?.shareholders.reduce(
    ([s, { nonFullyDiluted, fullyDiluted }], curr) => [
      [curr, ...curr.accounts.map((a) => ({ ...a, isAccount: true })), ...s],
      {
        nonFullyDiluted: nonFullyDiluted + curr.shares.nonFullyDiluted.total,
        fullyDiluted: fullyDiluted + curr.shares.fullyDiluted.total,
        isTotal: true,
      },
    ],
    [[], { nonFullyDiluted: 0, fullyDiluted: 0, isTotal: true }]
  );

  const computeWidths = (n, k) => {
    const widths = [25, 25, 10, 10, 10, 10, 10];
    return `${k ? widths.slice(n, k).reduce((a, b) => a + b, 0) + 0.5 : widths[n]}%`;
  };

  const columns = [
    {
      title: t('OPERATION_HOLDER'),
      width: computeWidths(0),
      render: ({ isAccount, name, shareType, id }) =>
        isAccount ? (
          t(`SHARE_TYPE_${shareType}`)
        ) : (
          <Text
            style={{ cursor: 'pointer', color: primaryColor }}
            onClick={() => history.push(`/shareholder/${id}`)}
            underline
          >
            {name}
          </Text>
        ),
    },
    {
      title: t('QUALITY'),
      width: computeWidths(1),
      render: ({ isAccount, quality }, { id }) =>
        isAccount ? (
          ''
        ) : (
          <Typography.Text
            type="button"
            style={{ cursor: 'text', maxWidth: '95%', marginBottom: '-1.5%' }}
            editable={
              (type === 1 || type === 2) && {
                maxLength: 31,
                onStart: () => setIsEditing(true),
                onChange: (s) => {
                  updateIndivision({ quality: s, id, companyId: company.id })
                    .then(() => setQualities({ ...qualities, [id]: s }))
                    .catch((e) => notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') }));
                  setIsEditing(false);
                },
                onCancel: () => setIsEditing(false),
                onEnd: () => setIsEditing(false),
              }
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {qualities[id] || t(quality)}
          </Typography.Text>
        ),
    },
    {
      title: t('TOTAL_TITLES_NOT_FULLY_DILUTED'),
      width: computeWidths(2),
      render: ({ isAccount, nonFullyDiluted, shares }) =>
        numberWithCommas(isAccount ? nonFullyDiluted.total : shares.nonFullyDiluted.total),
    },
    {
      title: t('PERCENTAGE_NOT_FULLY_DILUTED'),
      width: computeWidths(3),
      render: ({ isAccount, nonFullyDiluted, shares }) => {
        const value = isAccount ? nonFullyDiluted?.percentage : shares?.nonFullyDiluted?.percentage;
        if (value) return `${value}%`;
        return null;
      },
    },
    { title: t('TITLES_GIVE_ACCESS_TO_CAPITAL'), dataIndex: 'capital', width: computeWidths(4) },
    {
      title: t('TOTAL_TITLES_FULLY_DILUTED'),
      width: computeWidths(5),
      render: ({ isAccount, fullyDiluted, shares }) =>
        numberWithCommas(isAccount ? fullyDiluted.total : shares.fullyDiluted.total),
    },
    {
      title: t('PERCENTAGE_FULLY_DILUTED'),
      width: computeWidths(6),
      render: ({ isAccount, fullyDiluted, shares }) => {
        const value = isAccount ? fullyDiluted?.percentage : shares?.fullyDiluted?.percentage;
        if (value) return `${value}%`;
        return null;
      },
    },
  ];

  return (
    <>
      <Card
        title={t('COMPANY_TABLE_CAPITALISATION')}
        extra={
          <Button
            className="react-tour-8"
            onClick={() => {
              if (type === 1 || type === 2) {
                setIsFileSelectorVisible(true);
              } else
                history.push({
                  pathname: '/message',
                  state: { isFileRequest: true },
                });
            }}
            icon={<DownloadOutlined />}
            type="text"
            size="large"
          >
            {t(type === 1 || type === 2 ? 'EXPORT' : 'EXPORT_REQUEST')}
          </Button>
        }
      >
        <Table
          size="small"
          rowKey="id"
          columns={columns}
          rowClassName={({ isAccount }) => (isAccount ? '' : 'dg-capital-table-is-shareholder dg-cursor')}
          onRow={(r) => ({
            onClick: () => !isEditing && Number.isInteger(r.id) && history.push(`/shareholder/${r.id}`),
          })}
          dataSource={shareholders}
          pagination={false}
          summary={() => (
            <>
              <Table.Summary.Row style={{ color: 'white', backgroundColor: primaryColor }}>
                <Table.Summary.Cell>{t('TOTAL')}</Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell>{total.nonFullyDiluted}</Table.Summary.Cell>
                <Table.Summary.Cell>100%</Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell>{total.fullyDiluted}</Table.Summary.Cell>
                <Table.Summary.Cell>100%</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />

        <div
          style={{
            backgroundColor: secondColor,
            padding: 10,
            color: 'white',
            borderRadius: '5px 5px 0px 0px',
            marginTop: 16,
            display: 'flex',
          }}
        >
          <div style={{ width: computeWidths(0, 4) }}>{t('TOTAL_TABLE_CAPI_HEADER')}</div>
          <div style={{ width: computeWidths(4, 6) }}>{t('TITLES_GIVE_ACCESS_TO_CAPITAL')}</div>
          <div>{t('PERCENTAGE_FULLY_DILUTED')}</div>
        </div>
        <div style={{ padding: 5 }}>
          {stats?.capital?.details.map((s) => (
            <div style={{ display: 'flex', padding: 2 }}>
              <div style={{ width: computeWidths(0, 4) }}>{s?.name || null}</div>
              <div style={{ width: computeWidths(4, 6) }}>{s?.total || null}</div>
              <div>{s?.percentage ? `${s.percentage}%` : null}</div>
            </div>
          ))}
        </div>
        <div style={{ backgroundColor: secondColor, padding: 10, color: 'white', borderRadius: '0px 0px 5px 5px' }}>
          <div style={{ display: 'flex', padding: 2 }}>
            <div style={{ width: computeWidths(0, 4) }}>{t('TOTAL')}</div>
            <div style={{ width: computeWidths(4, 6) }}>{stats?.capital?.total || null}</div>
            <div>{stats?.capital?.percentage ? `${stats?.capital?.percentage}%` : null}</div>
          </div>
        </div>
      </Card>
      <FileSelector
        isVisible={isFileSelectorVisible}
        setIsVisible={setIsFileSelectorVisible}
        route={`/company/${company.id}/extract/capital`}
        name="table-de-capitalisation"
        data={((s) => {
          const output = [];
          s.forEach((item) => {
            if (!item.isAccount) {
              output.push([
                item.name,
                item.quality,
                item.shares.nonFullyDiluted.total || 0,
                item.shares.nonFullyDiluted.percentage || '0%',
                item.shares.capital || 0,
                item.shares.fullyDiluted.total || 0,
                item.shares.fullyDiluted.percentage || '0%',
              ]);
              item.accounts.forEach((account) => {
                output.push([
                  t(`SHARE_TYPE_${account.shareType}`),
                  '',
                  account.nonFullyDiluted.total || 0,
                  '',
                  account.capital || 0,
                  account.fullyDiluted.total || 0,
                  '',
                ]);
              });
            }
          });
          return output;
        })(shareholders)}
        columns={columns}
      />
    </>
  );
}

export default CompanyCapitalTable;
