/* eslint-disable react/no-array-index-key */
import {
  PieChartOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  LockOutlined,
  PlusCircleOutlined,
  ReadOutlined,
  MailOutlined,
  QuestionOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, Affix } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import operations from '../../../redux/operation/operations';
import { useCompany } from '../../../redux/company/selectors';
import usePermission from '../../../hooks/usePermission';

const { Sider } = Layout;
const { SubMenu } = Menu;

const styles = {
  container: { height: '100%', padding: 10 },
};

function LayoutMainSider({ lock, collapsed, setCollapsed }) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const company = useCompany();
  const type = usePermission(company?.id);

  const [chat, setChat] = useState(false);

  const getDefaultOpenKeys = () => {
    const array = [];

    if (['/operations', '/accounts'].includes(location.pathname) || location.pathname.includes('/shareholder/')) {
      array.push('3');
    } else if (location.pathname.includes('/operations/')) {
      array.push('4');
    } else if (location.pathname.includes('/rights/')) {
      array.push('5');
    } else if (location.pathname.includes('/documentation/')) {
      array.push('documentation');
    }

    return array;
  };

  const getSelectedKey = () => {
    if (location.pathname.includes('/shareholder/')) return '/accounts';
    return location.pathname;
  };

  const nav = (s) => {
    if (lock) setCollapsed(true);
    history.push(s);
  };

  return (
    <Sider width={250} collapsed={collapsed}>
      <Menu
        style={styles.container}
        mode="inline"
        selectedKeys={[getSelectedKey()]}
        defaultOpenKeys={getDefaultOpenKeys()}
        theme="dark"
        className="dg-sider"
      >
        {/* <Menu.Item icon={<TranslationOutlined />} key="/translations" onClick={() => nav('/translations')}>
          Translations
        </Menu.Item> */}

        <Menu.Item icon={<MailOutlined />} key="/message" onClick={() => nav('/message')}>
          {t('SIDEBAR_MESSAGE')}
        </Menu.Item>

        <Menu.Item icon={<HomeOutlined />} key="/companies" onClick={() => nav('/companies')}>
          {t('SIDEBAR_COMPANIES')}
        </Menu.Item>

        {company && (
          <>
            <Menu.Item
              key={`/company/${company.id}`}
              icon={<PieChartOutlined />}
              onClick={() => nav(`/company/${company.id}`)}
            >
              {company.name}
            </Menu.Item>

            {(type === 1 || type === 2) && (
              <SubMenu key="4" icon={<PlusCircleOutlined />} title="Nouvelle opération" className="dg-new-operation">
                {operations.map((operation, index) => (
                  <Menu.Item
                    key={`/operations/${index + 1}/create`}
                    onClick={() => nav(`/operations/${index + 1}/create`)}
                  >
                    {t(`SIDEBAR_OPERATION_${index + 1}`)}
                  </Menu.Item>
                ))}
              </SubMenu>
            )}

            <SubMenu key="3" icon={<ReadOutlined />} title="Digistre Titres">
              <Menu.Item key="/operations" onClick={() => nav('/operations')}>
                {t('SIDEBAR_OPERATIONS')}
              </Menu.Item>
              <Menu.Item key="/accounts" onClick={() => nav('/accounts')}>
                {t('SIDEBAR_ACCOUNTS')}
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="/documentation/decision-register"
              icon={<ReadOutlined />}
              onClick={() => nav('/documentation/decision-register')}
            >
              {t('DIGISTRE_DECISION')}
            </Menu.Item>

            <SubMenu
              key="documentation"
              icon={<FolderOpenOutlined />}
              title={t('SIDEBAR_DOCUMENTATION')}
              className="react-tour-9"
            >
              <Menu.Item key="/documentation/legal-acts" onClick={() => nav('/documentation/legal-acts')}>
                {t('LEGAL_ACTS')}
              </Menu.Item>
              <Menu.Item key="/documentation/news" onClick={() => nav('/documentation/news')}>
                {t('NEWS')}
              </Menu.Item>
            </SubMenu>
            {(type === 1 || type === 2) && (
              <SubMenu key="5" icon={<LockOutlined />} title={t('SIDEBAR_RIGHTS')}>
                <Menu.Item key="/rights/users" onClick={() => nav('/rights/users')}>
                  {t('SIDEBAR_RIGHTS_1')}
                </Menu.Item>
                <Menu.Item key="/rights/holders" onClick={() => nav('/rights/holders')}>
                  {t('SIDEBAR_RIGHTS_2')}
                </Menu.Item>
              </SubMenu>
            )}
          </>
        )}
        <Affix
          style={{
            position: 'absolute',
            bottom: 0,
            left: '18%',
            zIndex: 1,
            transition: 'all 0.2s',
          }}
          offsetBottom={45}
        >
          <Button
            type={chat ? 'primary' : 'default'}
            size="large"
            shape="circle"
            icon={chat ? <CloseOutlined /> : <QuestionOutlined />}
            onFocus={(ev) => ev.currentTarget.blur()}
            onClick={() => {
              if (chat) window.$crisp?.push(['do', 'chat:hide']);
              else window.$crisp?.push(['do', 'chat:show']);
              setChat(!chat);
            }}
          />
        </Affix>
      </Menu>
    </Sider>
  );
}

export default LayoutMainSider;
