import { Button, Card, Modal, Skeleton, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import newsApi from '../../api/news';
import { getPermissionUser } from '../../api/permission';
import { useCompany } from '../../redux/company/selectors';
import NewsCreate from './Create';
import imageDefault from './news.png';

function News() {
  const { t } = useTranslation();

  const company = useCompany();

  const [news, setNews] = useState([]);
  const [selected, setSelected] = useState(null);
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (company?.id)
      getPermissionUser(company.id)
        .then((x) => setIsAdmin(x?.type === 1 || x?.type === 2))
        .catch(() => {});
  }, [company]);

  const fetchNews = useCallback(() => {
    newsApi
      .findAll(company.id)
      .then((x) => setNews(x.sort((a, b) => new Date(b.date) - new Date(a.date))))
      .catch(() => setNews([]));
  }, [company]);

  const fetchNewsDetail = useCallback(async () => {
    try {
      setLoading(true);
      const [n, image] = await Promise.all([
        newsApi.findOne(company.id, selected.id),
        ...(selected.fileid ? [newsApi.getFile(company.id, selected.fileid)] : []),
      ]);
      setNewsDetail({ ...n, image });
    } catch (e) {
      setNewsDetail(null);
    } finally {
      setLoading(false);
    }
  }, [company, selected]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  useEffect(() => {
    if (selected) fetchNewsDetail();
  }, [fetchNewsDetail, selected]);

  return (
    <Card title={t('NEWS')} extra={isAdmin && <NewsCreate refresh={fetchNews} />}>
      <Modal title={selected?.title} visible={selected} footer={null} onCancel={() => setSelected(null)} width={800}>
        {loading ? (
          <Skeleton />
        ) : (
          <div style={{ display: 'flex' }}>
            {newsDetail?.image ? (
              <img alt="news" src={newsDetail?.image} style={{ maxWidth: 200, marginRight: 20 }} />
            ) : (
              <img alt="news" src={imageDefault} style={{ maxWidth: 200, marginRight: 20 }} />
            )}
            <div>{newsDetail?.description}</div>
          </div>
        )}
      </Modal>

      <Table
        rowClassName="dg-cursor"
        onRow={(record) => ({ onClick: () => setSelected(record) })}
        columns={[
          { title: t('NEWS_NAME'), dataIndex: 'title' },
          { title: t('NEWS_DATE'), dataIndex: 'date', render: (date) => new Date(date).toLocaleDateString() },
          {
            title: t('ACTIONS'),
            dataIndex: 'id',
            render: (id) =>
              isAdmin && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    newsApi.remove(company.id, id).then(() => fetchNews());
                  }}
                >
                  {t('DELETE')}
                </Button>
              ),
          },
        ]}
        dataSource={news}
      />
    </Card>
  );
}

export default News;
