import { Button, Form, Input, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authenticationApi from '../../../api/authentication';

const { Text } = Typography;

function PasswordForgotForm() {
  const history = useHistory();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    await authenticationApi.passwordForgot(values);
    history.push('/password/confirmation');
  };

  return (
    <Form initialValues={{ remember: true }} size="large" layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t('USER_EMAIL')}
        name="email"
        rules={[{ required: true, type: 'email', message: t('USER_EMAIL_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('USER_EMAIL_INPUT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item>
        <Row justify="center">
          <Button type="primary" htmlType="submit">
            {t('COMMON_SEND')}
          </Button>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row justify="center" align="middle">
          <Text>{t('PASSWORD_FORGOT_FIND_MY_PASSWORD')}</Text>
          <Button type="link" size="small" onClick={() => history.push('/login')}>
            {t('REGISTER_BACK_TO_LOGIN')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

export default PasswordForgotForm;
