import { Card, Divider, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchShareholders, setOperationParent } from '../../../redux/operation/actions';
import { useOperationChild, useOperationParent, useOperationStep } from '../../../redux/operation/selectors';
import operations from '../../../redux/operation/operations';
import CreateShareHolder from './components/Shareholders/Create';
import OperationsCreateSelect from './Select';
import OperationsCreateResume from './Resume';
import OperationsCreateHeader from './components/Header';
import OperationsCreateForm from './components/Form';
import OperationsSuccess from './Success';

const { Step } = Steps;

const styles = {
  steps: { marginBottom: 20 },
  divider: { borderTopWidth: 2 },
  actions: { display: 'flex', justifyContent: 'space-between' },
};

function OperationsCreate() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const parent = useOperationParent();
  const child = useOperationChild();
  const step = useOperationStep();
  const [response, setResponse] = useState(null);

  const [file, setFile] = useState(null);

  const operationId = params ? params.operationId : null;

  useEffect(() => {
    dispatch(setOperationParent(operations[operationId - 1]));
    dispatch(fetchShareholders());
  }, [dispatch, operationId]);

  if (!operationId || !parent) return null;

  return (
    <Card title={t(`SIDEBAR_OPERATION_${parent.id}`)}>
      <Steps current={step - 1} style={styles.steps}>
        <Step title={t('OPERATIONS_STEP_1_LABEL')} />
        <Step title={t('OPERATIONS_STEP_2_LABEL')} />
        <Step title={t('OPERATIONS_STEP_3_LABEL')} />
      </Steps>

      <Divider />

      {step !== 1 && step !== 4 && <OperationsCreateHeader />}

      {step === 1 && <OperationsCreateSelect />}
      {step === 2 && <OperationsCreateForm setFile={setFile}>{child.component}</OperationsCreateForm>}
      {step === 3 && <OperationsCreateResume file={file} setResponse={setResponse} />}
      {step === 4 && <OperationsSuccess response={response} />}

      <CreateShareHolder />
    </Card>
  );
}

export default OperationsCreate;
