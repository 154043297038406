import { Row, Col, Collapse, Table, Tag, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import shareholderApi from '../../../api/shareholder';
import { useCompany } from '../../../redux/company/selectors';
import { usePrimaryColor, useSecondColor } from '../../../redux/settings/selectors';
import { requestFile } from '../../../services/request';
import usePermission from '../../../hooks/usePermission';

const width = {
  number: 95,
};

function ShareholderDetailsAccounts() {
  const history = useHistory();
  const { shareholderId } = useParams();

  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const company = useCompany();
  const permissionType = usePermission(company?.id);

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    shareholderApi
      .getAccounts(company.id, shareholderId)
      .then((data) =>
        setAccounts(
          data[0].accounts
            .sort((a, b) => {
              // sort by sharetype
              const aa = [1, 2, 3].includes(a.shareType);
              const bb = [1, 2, 3].includes(b.shareType);
              if (aa && !bb) return -1;
              if (!aa && bb) return 1;
              return 0;
            })
            .sort((a) => (a.balance === 0 ? 1 : 0)) // empty accounts to the end
            .map((a) => ({ ...a, ...a.operations[0], totalBalance: a.balance }))
        )
      )
      .catch(() => setAccounts([]));
  }, [company.id, shareholderId]);

  return (
    <Row gutter={[16, 16]}>
      {accounts.map((account) => (
        <Col span={24}>
          <Collapse
            defaultActiveKey={account.totalBalance === 0 ? ['0'] : ['1']}
            expandIconPosition="right"
            style={{ backgroundColor: 'white', borderWidth: 0 }}
          >
            <Collapse.Panel
              header={
                <div style={{ fontSize: 16, color: primaryColor, fontWeight: 500 }}>
                  {`${t('OPERATION_ACCOUNT_NUMBER')} ${account.accountNumber}`}
                  <div style={{ display: 'inline-block', width: '20px' }} />
                  <Tag color={primaryColor}>{t(`SHARE_TYPE_${account.shareType}`)}</Tag>
                  <div style={{ float: 'right' }}>
                    <Tag color={secondColor}>{t(`ACCOUNT_TYPE_${account.accountType}`)}</Tag>
                    <Tag color={secondColor}>{t(`ACCOUNT_STATE_${account.accountState}`)}</Tag>
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (permissionType === 1 || permissionType === 2)
                          requestFile(
                            `/company/${company.id}/extract/account?shareholder=${shareholderId}&operations=all`,
                            'comptes.pdf'
                          );
                        else
                          history.push({
                            pathname: '/message',
                            state: { isFileRequest: true },
                          });
                      }}
                    >
                      {t(permissionType === 1 || permissionType === 2 ? 'EXPORT' : 'EXPORT_REQUEST')}
                    </Button>
                  </div>
                </div>
              }
              key="1"
            >
              <Table
                columns={[
                  { title: t('OPERATION_ID'), dataIndex: 'orderNumber', width: 60, fixed: true },
                  {
                    title: t('OPERATION'),
                    dataIndex: 'operationType',
                    width: 750,
                    render: (type) => t(`OPERATIONS_${type}_LABEL`),
                  },
                  { title: t('SHARES_CREDIT'), dataIndex: 'credit', width: width.number },
                  { title: t('SHARES_DEBIT'), dataIndex: 'debit', width: width.number },
                  { title: t('ACCOUNT_BALANCE'), dataIndex: 'balance', width: width.number },
                  { title: t('OBSERVATIONS'), dataIndex: 'comments' },
                ]}
                dataSource={account.operations}
              />
            </Collapse.Panel>
          </Collapse>
        </Col>
      ))}
    </Row>
  );
}

export default ShareholderDetailsAccounts;
