import React, { useState, useContext } from 'react';
import { Form, Select, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import { useCompany } from '../../../../redux/company/selectors';
import companyTypes from '../../../../services/companyTypes';
import FormContext from '../components/Form/context';

// Transformation de la société
function OperationsCreate2x1() {
  const { t } = useTranslation();
  const company = useCompany();

  const { Tooltip } = useContext(FormContext);

  const [shareIsUpdate, setShareIsUpdate] = useState(false);

  const shareTypes = [
    { label: t('SHARE_TYPE_1'), value: 1 },
    { label: t('SHARE_TYPE_2'), value: 2 },
    { label: t('SHARE_TYPE_3'), value: 3 },
  ];

  return (
    <>
      <Form.Item label={t('COMPANY_NAME_LABEL')}>
        <Input value={company.name} disabled />
      </Form.Item>
      <Form.Item label={t('COMPANY_TYPE_LABEL')}>
        <Input value={company.companytypedetail} disabled />
      </Form.Item>

      <Tooltip title={t('NEW_COMPANY_TYPE_TOOLTIP')}>
        <Form.Item
          label={t('NEW_COMPANY_TYPE_LABEL')}
          name="companyTypeDetail"
          rules={[{ required: true, message: t('COMPANY_TYPE_REQUIRED') }]}
        >
          <Select options={companyTypes.map((m) => ({ label: m, value: m }))} />
        </Form.Item>
      </Tooltip>

      <Form.Item label={t('UPDATE_SHARE_TYPE_LABEL')}>
        <Radio.Group onChange={(e) => setShareIsUpdate(e.target.value)} value={shareIsUpdate}>
          <Radio value>{t('YES')}</Radio>
          <Radio value={false}>{t('NO')}</Radio>
        </Radio.Group>
      </Form.Item>

      {shareIsUpdate && (
        <Tooltip title={t('NEW_SHARE_TYPE_TOOLTIP')}>
          <Form.Item label={t('NEW_SHARE_TYPE_LABEL')} name="shareType" preserve={false}>
            <Select options={shareTypes} />
          </Form.Item>
        </Tooltip>
      )}

      <OperationsDate />

      <OperationsComment />
    </>
  );
}

export default OperationsCreate2x1;
