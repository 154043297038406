import React from 'react';
import { Button, Divider } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import UserProfile from '../Profile/UserProfile';
import EditCustomItems from './Details/EditCustomItems';
import CompanyIndivisionData from './Details/CompanyIndivisionData';
import UpsertCompany from '../../components/UpsertCompany';

const ShareholderProfile = () => {
  const { state } = useLocation();
  const history = useHistory();
  if (!state) history.goBack();

  return (
    <>
      <Button icon={<ArrowLeftOutlined />} onClick={() => history.goBack()} />
      <Divider />
      {state.indivision && <CompanyIndivisionData companyData={state.company} indivisionData={state.indivision} />}
      {state.user && <UserProfile />}
      {state.company && (
        <>
          <UpsertCompany
            mode="update"
            otherCompany={{ ...state.company, id: state.id }}
            refresh={() => history.goBack()}
          />
          <Divider />
        </>
      )}
      {(state.indivision || state.company) && <EditCustomItems />}
    </>
  );
};

export default ShareholderProfile;
