import { SET_COMPANY } from './actions';

const initState = null;

const company = (state = initState, action) => {
  switch (action.type) {
    case SET_COMPANY: {
      localStorage.setItem('company_name', action.company?.name);
      return action.company ? { ...action.company } : null;
    }
    default:
      return state;
  }
};

export default company;
