import { Table } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import operationsApi from '../../../api/operation';
import OperationType from '../../../components/OperationType';
import { useCompany } from '../../../redux/company/selectors';
import toBlockExplorer from '../../../components/toBlockExplorer';
// eslint-disable-next-line import/no-cycle
import OperationModal from './OperationModal';
import FileSelector from '../../../components/FileSelector';
import './style.css';

function OperationsListTable({ isFileSelectorVisible, setIsFileSelectorVisible, operation, setInvert, refresh }) {
  const { t } = useTranslation();

  const company = useCompany();

  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const isDraft = operation.SHARE_TYPE === 'DRAFT';

  const operationFinal = (operation.extended.length ? operation.extended : operation.restricted)
    .filter((f) => Number(f.companyid) === Number(company.id))
    .sort((a, b) => a.ordernumber - b.ordernumber);

  const keys = setInvert && operationFinal.map((x) => x.ordernumber);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const width = {
    name: 190,
    number: 95,
  };

  const columns = [
    { title: t('OPERATION_ID'), dataIndex: 'ordernumber', width: 50, align: 'center', fixed: true },
    {
      title: t('OPERATION_DATE'),
      dataIndex: 'date',
      width: 90,
      align: 'center',
      render: (date) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: t('OPERATION_HOLDER'),
      children: [
        {
          title: t('OPERATION_SOCIAL_NAME'),
          dataIndex: 'holdername',
          width: width.name,
        },
        { title: t('OPERATION_ACCOUNT_NUMBER'), dataIndex: 'holdernumber', width: width.number, align: 'center' },
      ],
    },
    { title: t('OPERATION_SHARES'), dataIndex: 'changedsharenumber', width: 140 },
    {
      title: t('OPERATION_TYPE'),
      dataIndex: 'changenature',
      width: 150,
      render: (type) => <OperationType type={type} />,
    },
    {
      title: t('OPERATION_BENEFICIARY'),
      children: [
        { title: t('OPERATION_SOCIAL_NAME'), dataIndex: 'beneficiaryname', width: width.name },
        { title: t('OPERATION_ACCOUNT_NUMBER'), dataIndex: 'beneficiarynumber', width: width.number, align: 'center' },
      ],
    },
    {
      title: t('OPERATIONS_COMMENT'),
      dataIndex: 'comments',
      width: 350,
      render: (c) => (c.length > 42 ? `${c.slice(0, 42)}...` : c),
    },
    {
      title: <div />,
      dataIndex: 'test',
      width: 50, // record.companyid, record.ordernumber, record.signature
      render: (_, record) =>
        isDraft ? (
          <ClockCircleOutlined />
        ) : (
          <button
            className="zoom ethereum"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              toBlockExplorer(record);
            }}
          >
            <img width="60%" src="/ethereum.svg" alt="ethereum" />
          </button>
        ),
    },
  ];

  return (
    <>
      {selected && (
        <OperationModal
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          isDraft={isDraft}
          refresh={refresh}
          isLowest={isDraft && selected.ordernumber === Math.min(...operation.extended.map((x) => x.ordernumber))}
        />
      )}

      <Table
        rowKey="ordernumber"
        rowClassName="dg-cursor"
        rowSelection={
          setInvert && {
            selectedRowKeys,
            onSelect: (r, s, k, e) => {
              e.stopPropagation();
              const v = keys.filter((x) => x >= (s ? 0 : 1) + r.ordernumber);
              setSelectedRowKeys(v);
              setInvert(
                operationFinal
                  .filter((x) => v.includes(x.ordernumber))
                  .map((x) => ({
                    ordernumber: x.ordernumber,
                    changenature: x.changenature,
                    date: x.date,
                  }))
              );
            },
          }
        }
        scroll={{ x: true }}
        columns={columns}
        dataSource={operationFinal}
        size="small"
        onRow={(record) => ({
          onClick: () => {
            setSelected(record);
            setLoading(true);
            operationsApi
              .getResume(company.id, record.ordernumber, isDraft)
              .then((resume) => setSelected({ ...record, resume }))
              .finally(() => setLoading(false));
          },
        })}
        bordered
      />
      <FileSelector
        isVisible={isFileSelectorVisible}
        setIsVisible={setIsFileSelectorVisible}
        route={`/company/${company.id}/extract/register?sharetype=${operation.SHARE_TYPE.split('_')[2]}`}
        name="registre"
        data={operationFinal
          .map((x) => [
            x.ordernumber,
            x.date,
            x.holdername,
            x.holdernumber,
            x.changedsharenumber,
            x.changenature,
            x.beneficiaryname,
            x.beneficiarynumber,
            x.comments,
          ])
          .sort((a, b) => a[0] - b[0])}
        columns={[
          'OPERATION_ID',
          'OPERATION_DATE',
          'OPERATION_SOCIAL_NAME',
          'OPERATION_ACCOUNT_NUMBER',
          'OPERATION_SHARES',
          'OPERATION_TYPE',
          'OPERATION_SOCIAL_NAME',
          'OPERATION_ACCOUNT_NUMBER',
          'OPERATIONS_COMMENT',
        ]}
      />
    </>
  );
}

export default OperationsListTable;
