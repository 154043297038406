import React, { useState } from 'react';
import OperationsComment from '../components/Comment';
import OperationsDate from '../components/Date';
import ShareTypes from '../components/ShareTypes';
import ShareholdersCompute from '../components/Shareholders/Compute';

// Division du nombre de titres
function OperationsCreate1x7() {
  const [shareType, setShareType] = useState(null);

  return (
    <>
      <OperationsDate />
      <ShareTypes onChange={setShareType} />
      {shareType && <ShareholdersCompute shareType={shareType} mode="DIVISION" />}
      <OperationsComment />
    </>
  );
}

export default OperationsCreate1x7;
