import { useSelector } from 'react-redux';

export const useOperationParent = () => useSelector((state) => state.operation.parent);
export const useOperationChild = () => useSelector((state) => state.operation.child);
export const useOperationShareType = () => useSelector((state) => state.operation.child.shareType);
export const useShowModalCreateShareholder = () => useSelector((state) => state.operation.showModalCreateShareholder);
export const useOperationShareholders = () => useSelector((state) => state.operation.shareholders);
export const useOperationShareholdersWithBalance = () =>
  useSelector((state) => [
    ...state.operation.shareholders.filter((shareholder) => !!shareholder.balance || shareholder.selected),
  ]);
export const useOperationShareholdersWithoutBalance = () =>
  useSelector((state) => [
    ...state.operation.shareholders.filter(
      (shareholder) =>
        (!shareholder.balance && !shareholder.selected) || (shareholder.balance && shareholder.selected === true)
    ),
  ]);
export const useOperationShareholdersSelected = () =>
  useSelector((state) => [...state.operation.shareholders.filter((shareholder) => shareholder.selected)]);
export const useOperationShareholdersNotSelected = () =>
  useSelector((state) => [...state.operation.shareholders.filter((shareholder) => !shareholder.selected)]);
export const useOperationStep = () => useSelector((state) => state.operation.step);
export const useOperationData = () => useSelector((state) => state.operation.data);
export const useOperationDescription = () => useSelector((state) => state.operation.description);
export const useOperationsList = () => useSelector((state) => state.operation.operationsList);
export const selectShareholdersV2 = (state) => state.operation.shareholdersV2;
