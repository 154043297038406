import { Table, Typography, Button, Divider, Select, Row, Col, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchShareholders } from '../../../redux/operation/actions';
import { useOperationShareholders } from '../../../redux/operation/selectors';
import { useSecondColor } from '../../../redux/settings/selectors';
import FileSelector from '../../../components/FileSelector';
import { useCompany } from '../../../redux/company/selectors';

const labels = {
  usercity: 'USER_CITY',
  userpostalcode: 'USER_POSTALCODE',
  useraddress: 'USER_ADDRESS',
  usercountry: 'USER_COUNTRY',
  userfirstname: 'USER_FIRSTNAME',
  userlastname: 'USER_LASTNAME',
  birthdate: 'USER_BIRTHDATE',
  birthcity: 'USER_BIRTHCITY',
  companyname: 'USER_COMPANYNAME',
  profession: 'USER_PROFESSION',
  accountactive: 'USER_ACTIVE',
  name: 'COMPANY_NAME',
  siren: 'COMPANY_SIREN_OR_IMMATRICULATION',
  rcs: 'COMPANY_RCS',
  ape: 'COMPANY_APE',
  companyaddress: 'COMPANY_ADDRESS',
  companypostalcode: 'COMPANY_ZIP',
  companycity: 'COMPANY_CITY',
  companycountry: 'COUNTRY',
  username: 'COMPANY_USER_NAME',
  companytypedetail: 'COMPANY_TYPE_LABEL',
};

const { Text } = Typography;
const { Option } = Select;

function AccountsListTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useCompany();

  const [hidden, setHidden] = useState(true);
  const [columns, setColumns] = useState([]);
  const [isFileSelectorVisible, setIsFileSelectorVisible] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const shareholders = useOperationShareholders();
  const secondColor = useSecondColor();

  const acc = useMemo(
    () =>
      shareholders
        .reduce((prev, curr) => [...prev, ...curr.accounts.map((m) => ({ ...m, ...curr }))], [])
        .map((x) => ({
          ...x,
          ...(x.customFields ? Object.fromEntries(x.customFields.map((v) => [v.title, v.value])) : {}),
          ...(x.sideData ? x.sideData[0].userData || x.sideData[0].companyData : {}),
        }))
        .map((x) => ({ ...x, balance: x.accounts.find((a) => a.accountNumber === x.accountNumber).balance }))
        .sort((a, b) => a.accountNumber - b.accountNumber),
    [shareholders]
  );

  useEffect(() => {
    setAccounts(hidden ? acc.filter((x) => x.balance > 0) : acc);
  }, [hidden, acc]);

  useEffect(() => {
    dispatch(fetchShareholders());
  }, [dispatch]);

  const customColumns = [
    ...new Set([
      ...accounts
        .filter((x) => x.customFields?.length)
        .map((x) => x.customFields.map((o) => o.title))
        .flat(),
      ...[
        ...new Set([
          ...accounts
            .filter((x) => x.sideData?.[0].userData || x.sideData?.[0].companyData)
            .map((x) => Object.keys(x.sideData[0].userData || x.sideData?.[0].companyData))
            .flat(),
        ]),
      ],
    ]),
  ].map((x) => ({
    title: t(labels[x]) || x.charAt(0).toUpperCase() + x.slice(1),
    dataIndex: x,
  }));

  const defaultColumns = [
    {
      title: t('OPERATION_ACCOUNT_NUMBER'),
      dataIndex: 'accountNumber',
      sorter: (a, b) => a.accountNumber - b.accountNumber,
    },
    {
      title: t('SHAREHOLDER'),
      dataIndex: 'name',
      render: (name, shareholder) => (
        <Text style={{ cursor: 'pointer' }} onClick={() => history.push(`/shareholder/${shareholder.id}`)} underline>
          {name}
        </Text>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    },
    {
      title: t('USERS_ROLE'),
      dataIndex: 'quality',
      onFilter: (value, record) => record.accountType === value,
    },
    {
      title: t('ACCOUNT_TYPE'),
      dataIndex: 'accountType',
      render: (accountType) => t(`ACCOUNT_TYPE_${accountType}`),
      filters: [
        { text: t('ACCOUNT_TYPE_1'), value: 1 },
        { text: t('ACCOUNT_TYPE_2'), value: 2 },
        { text: t('ACCOUNT_TYPE_3'), value: 3 },
      ],
      onFilter: (value, record) => record.accountType === value,
    },
    {
      title: t('ACCOUNT_STATE'),
      dataIndex: 'accountState',
      render: (accountState) => t(`ACCOUNT_STATE_${accountState}`),
      filters: [
        { text: t('ACCOUNT_STATE_1'), value: 1 },
        { text: t('ACCOUNT_STATE_2'), value: 2 },
        { text: t('ACCOUNT_STATE_3'), value: 3 },
        { text: t('ACCOUNT_STATE_4'), value: 4 },
      ],
      onFilter: (value, record) => record.accountState === value,
    },
    {
      title: t('ACCOUNT_BALANCE'),
      dataIndex: 'balance',
      // render: (_, row) => console.log(row),
    },
  ];

  const format = (x) => {
    if (x[0] === 'birthdate') return [x[0], new Date(x[1]).toLocaleDateString()];
    return x;
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('SELECT_CUSTOM_COLUMNS')}
            // value={selectedColumns}
            onChange={(x) =>
              setColumns(x.map((o) => [...customColumns, ...defaultColumns].find((v) => v.dataIndex === o)))
            }
          >
            {[...defaultColumns, ...customColumns].map((x) => (
              <Option key={x.dataIndex} value={x.dataIndex}>
                {x.title}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Tooltip
            title={hidden ? t('SHOW_EMPTY_ACCOUNTS') : t('HIDE_EMPTY_ACCOUNTS')}
            placement="left"
            color={secondColor}
            overlayStyle={{ width: '100%' }}
          >
            <Button onClick={() => setHidden(!hidden)} icon={hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
          </Tooltip>
        </Col>
        <Col span={3}>
          <Button onClick={() => setIsFileSelectorVisible(true)}>{t('EXPORT')}</Button>
        </Col>
      </Row>
      <Divider />
      <Table
        rowClassName="dg-cursor"
        onRow={(r) => ({
          onClick: () => history.push(`/shareholder/${r.id}`),
        })}
        rowKey="accountNumber"
        scroll={{ x: true }}
        columns={columns.length ? columns : defaultColumns}
        dataSource={(columns.length
          ? accounts.map((x) => (columns.some((v) => x[v.dataIndex] !== undefined) ? x : null)).filter((x) => x)
          : accounts
        ).map((x) =>
          Object.fromEntries(
            Object.entries(x)
              .filter((o) => typeof o[1] === 'string' || typeof o[1] === 'number')
              .map((o) => format(o))
          )
        )}
      />
      <FileSelector
        isVisible={isFileSelectorVisible}
        setIsVisible={setIsFileSelectorVisible}
        route={`/company/${company.id}/extract/accounts`}
        name="table-des-actionnaires"
        data={accounts.map((x) =>
          (columns.length ? columns : defaultColumns).map((o) =>
            o.filters?.length ? o.filters.find((v) => v.value === x[o.dataIndex]).text : x[o.dataIndex] || ''
          )
        )}
        columns={columns}
        isPDFUnavailable
      />
    </div>
  );
}

export default AccountsListTable;
