import crypto from 'crypto';

const computeSignature = (item) => {
  const concat = Object.values(item).slice(0, 8).join(',');
  const hash = crypto.createHash('sha256').update(concat).digest('hex');
  return `0x${hash}`;
};

export default (item, type) => {
  window.open(`/blockexplorer${item ? `/${computeSignature(item)}${type ? `?type=${type}` : ''}` : ''}`, '_blank');
};
