import { Form, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';

const qualityLength = 7;
const name = 'type';

function ShareholderQuality({ form }) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [open, setOpen] = useState(false);

  return (
    <Tooltip title={t('SHAREHOLDER_QUALITY_TOOLTIP')}>
      <Form.Item
        label={t('SHAREHOLDER_QUALITY')}
        name={name}
        rules={[{ required: true, message: t('SHAREHOLDER_QUALITY_MESSAGE_REQUIRED') }]}
      >
        <Select
          optionFilterProp="label"
          mode="tags"
          placeholder={t('SHAREHOLDER_QUALITY_PLACEHOLDER')}
          autoClearSearchValue
          allowClear
          open={open}
          onInputKeyDown={() => setOpen(true)}
          onClick={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onSelect={(x) => {
            // replace item, instead of add it
            form.setFieldsValue({
              [name]: t(x),
            });
            setTimeout(() => setOpen(false), 10);
          }}
        >
          {Array.from({ length: qualityLength }, (v, i) => `QUALITY_${i}`).map((x) => (
            <Option key={x} label={t(x)}>
              {t(x)}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  );
}

export default ShareholderQuality;
