import React from 'react';
import StatsBlockLine from './stats/BlockLine';
import { usePrimaryColor, useSecondColor, useThirdColor } from '../redux/settings/selectors';

function Stats({ stats }) {
  const primaryColor = usePrimaryColor();
  const secondColor = useSecondColor();
  const thirdColor = useThirdColor();

  if (JSON.stringify(stats) === '{}' || !stats.length) return <div />;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -8px 8px' }}>
      <StatsBlockLine backgroundColor={primaryColor} {...stats[0]} />

      <StatsBlockLine backgroundColor={secondColor} {...stats[1]} />

      <StatsBlockLine backgroundColor={thirdColor} {...stats[2]} />
    </div>
  );
}

export default Stats;
