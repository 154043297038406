import { Steps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Subscribe from './Subscribe';
import Tutorial from './Tutorial';
import Welcome from './Welcome';

const { Step } = Steps;

function FirstConnection() {
  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);

  const next = () => setCurrent((c) => c + 1);
  const previous = () => setCurrent((c) => c - 1);

  return (
    <>
      <Steps current={current} style={{ height: 100 }}>
        <Step title={t('SUBSCRIBE_STEP_1')} />
        <Step title={t('SUBSCRIBE_STEP_2')} />
        <Step title={t('SUBSCRIBE_STEP_3')} />
      </Steps>

      {current === 0 && <Welcome next={next} />}
      {current === 1 && <Subscribe next={next} previous={previous} />}
      {current === 2 && <Tutorial previous={previous} />}
    </>
  );
}

export default FirstConnection;
