import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Card, Button, notification } from 'antd';
import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './index.css';
import messageApi from '../../api/message';
import { useUserId } from '../../redux/user/selectors';
import recipientOrSender from './utils/recipientOrSender';
import Response from './Response';
import MessageHeader from './MessageHeader';
import SingleMessage from './SingleMessage';
import Conv from './Conv';

export const sendMessage = ({ data, id, onSuccess, onFailure }) => {
  for (let i = 0; i < data.recipients.length; i += 1) {
    if (data.recipients[i])
      messageApi
        .post(
          {
            title: data.title,
            content: data.content,
            recipientId: data.recipients[i],
            fileId: data.fileId,
            filename: data.filename,
          },
          id
        )
        .then(onSuccess)
        .catch(onFailure);
  }
};

const Message = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const userId = useUserId();

  const [response, setResponse] = useState(false);
  const [messages, setMessages] = useState(null);
  const [conv, setConv] = useState([]);
  const [convId, setConvId] = useState(null);
  const [defaultRecipient, setDefaultRecipient] = useState(null);

  useEffect(() => {
    messageApi
      .get()
      .then(setMessages)
      .catch(() => setMessages([]));
  }, []);

  useEffect(() => {
    if (messages?.length) messageApi.get(convId || messages[0].conversationid).then(setConv);
  }, [messages, convId]);

  useEffect(() => {
    if (messages && messages.length === 0) setResponse(true);
    else setResponse(location?.state?.isFileRequest || false);
  }, [messages, location]);

  return (
    <div>
      <Row gutter={[0, 0]}>
        <Col span={5}>
          <Card
            type="inner"
            className="header"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setResponse(true)}>
              {t('MESSAGE_NEW_BUTTON')}
            </Button>
          </Card>
          <Card size="small" className="scrollable">
            {messages?.map((x) => (
              <SingleMessage
                onClick={() => {
                  setResponse(false);
                  messageApi.setRead(x.id);
                }}
                userId={userId}
                data={x}
                convId={convId}
                setConvId={setConvId}
              />
            ))}
          </Card>
        </Col>
        <Col span={19}>
          {response ? (
            <Response
              defaultContent={location?.state?.isFileRequest && t(`MESSAGE_DEFAULT_CONTENT`)}
              defaultTitle={location?.state?.isFileRequest && t(`MESSAGE_DEFAULT_TITLE`)}
              defaultRecipient={defaultRecipient}
              validate={(x) =>
                sendMessage({
                  data: x,
                  id: defaultRecipient !== null && x.recipients.includes(defaultRecipient[0]) && convId,
                  onSuccess: () => {
                    messageApi.get().then(setMessages);
                    x.onSuccess();
                  },
                  onFailure: (e) => notification.error({ description: t(e?.message || 'UNKNOWN_ERROR') }),
                })
              }
              back={() => {
                setResponse(false);
                setDefaultRecipient(null);
              }}
            />
          ) : (
            <div>
              <Card type="inner" className="header">
                {conv.length && (
                  <MessageHeader sender={conv[0].sendername} recipient={conv[0].recipientname} date={conv[0].date} />
                )}
              </Card>
              <Card className="scrollable">
                {conv.map((x) => (
                  <Conv data={x} />
                ))}
                <Button
                  icon={<RollbackOutlined />}
                  onClick={() => {
                    setResponse(true);
                    setDefaultRecipient([recipientOrSender(userId, conv[0], true), recipientOrSender(userId, conv[0])]);
                  }}
                >
                  {t('MESSAGE_ANSWER_BUTTON')}
                </Button>
              </Card>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Message;
