import React, { useState, useEffect } from 'react';
import { Divider, Alert, notification, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardElement, AddressElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSecondColor } from '../../redux/settings/selectors';
import Spinner from '../Spinner';
import './payment.css';
import companyApi from '../../api/company';
import interpreter from '../interpreter';

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const Payment = ({ clientSecret, companyId, paymentIntentId, reload, amount, subscriptionType }) => {
  const { t } = useTranslation();
  const secondColor = useSecondColor();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [address, setAddress] = useState(null);
  const [company, setCompany] = useState(null);
  const [card, setCard] = useState(null);
  const [tax, setTax] = useState({ amount: Number((amount * 0.2).toFixed(2)), taxable_amount: amount });

  const isSubscription = subscriptionType === 'custody';

  const taxIdentifierRegex =
    /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/g;

  useEffect(() => {
    if (address?.name?.length && company?.name && card) setDisabled(false);
    else setDisabled(true);
  }, [address, company, card]);

  const showError = ({ description }) => notification.error({ description, duration: 21 });

  const handleChange = async (e) => {
    setCard(!e.empty);
    setError(e.error ? e.error.message : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const taxIdentifier = company.taxIdentifier?.length ? company.taxIdentifier : null;
      // 1. Invoice
      if (taxIdentifier && !taxIdentifier.match(taxIdentifierRegex)) {
        showError({ description: t('TAX_ID_INVALID') });
        setError(t('TAX_ID_INVALID'));
        setProcessing(false);
        return;
      }
      const invoice = await companyApi.paymentInvoice(companyId, subscriptionType, {
        address: address.address,
        name: address.name,
        customerCompany: {
          name: company.name,
          taxIdentifier,
        },
        paymentIntentId,
      });

      setTax(invoice.tax); // if it changes compare to previous value, should starts a popup to say "hey, that changed"

      // 2. Payment
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        showError({ description: payload.error.message });
        setError(payload.error.message);
      } else {
        await companyApi.paymentConfirm(companyId, { ...payload, invoice });
        notification.success({
          message: t('PAYMENT_SUCCESS'),
          duration: null,
          description: (
            <>
              <div>{t('PAYMENT_SUCCESS_DESC')}</div>
              <a target="_blank" rel="noopener noreferrer" href={`${invoice.pdf}`}>
                {t('YOUR_INVOICE_LINK')}
              </a>
            </>
          ),
        });
        setSucceeded(true);
        setError(null);
        setDisabled(false);
        reload();
      }
    } catch (err) {
      let desc;
      if (typeof err.data === 'object') {
        desc = Object.values(err.data)[0] || err;
      } else {
        desc = err;
      }
      if (typeof desc !== 'string') desc = 'UNKOWN_ERROR';
      desc = desc.toUpperCase();
      showError({ description: t(desc) });
      setError(t(desc));
    }
    setProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <AddressElement options={{ mode: 'billing' }} onChange={(e) => setAddress(e.value)} />
      <Divider />
      <Typography>{t('CUSTOMER_COMPANY_NAME')}</Typography>
      <Input
        size="large"
        placeholder={t('CUSTOMER_COMPANY_NAME')}
        onChange={(e) => setCompany({ ...company, name: e.target.value })}
      />
      <Typography style={{ margin: '10px 0 0 0' }}>{t('CUSTOMER_COMPANY_TAX')}</Typography>
      <Input
        size="large"
        placeholder={t('CUSTOMER_COMPANY_TAX_PLACEHOLDER')}
        onChange={(e) => setCompany({ ...company, taxIdentifier: e.target.value })}
      />
      <Divider />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        style={{
          background: secondColor,
          color: 'white',
          width: '100%',
          display: 'block',
          border: '1px solid black',
          cursor: processing || disabled || succeeded ? 'not-allowed' : 'pointer',
          height: '40px',
          borderRadius: '0 0 20px 20px',
          margin: '12px 0 0 0',
        }}
        type="submit"
      >
        <span id="button-text">{processing ? <Spinner /> : t('PAY_NOW')}</span>
      </button>
      <div style={{ textAlign: 'center', fontSize: 'smaller', margin: '10px' }}>
        {t('COMPANY_VAT_TEXT').replace('$n1', tax?.taxable_amount).replace('$n2', tax?.amount)}
      </div>
      <div style={{ textAlign: 'center', fontSize: 'smaller', margin: '10px' }}>
        {t('COMPANY_TOTAL_TEXT').replace('$n1', tax?.taxable_amount + tax?.amount)}
      </div>
      {isSubscription && (
        <div style={{ textAlign: 'center', color: 'grey', fontStyle: 'italic', fontSize: 'smaller', margin: '10px' }}>
          {interpreter(t('COMPANY_PAYMENT_TEXT'))}
        </div>
      )}
      {error && <Alert message={error} type="warning" showIcon />}
      <p className={succeeded ? 'result-message' : 'result-message hidden'} />
      <Divider />
    </form>
  );
};

export default Payment;
