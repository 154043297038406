import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import companyApi from '../../../api/company';
import { useSecondColor } from '../../../redux/settings/selectors';
import GenericModal from '../../../components/GenericModal';

function DeleteCompany({ company, refresh }) {
  const { t } = useTranslation();
  const secondColor = useSecondColor();

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');

  const remove = async (e) => {
    e.stopPropagation();
    if (name !== company.name.toUpperCase()) {
      setName('');
      return;
    }
    await companyApi.removeCompany(company.id);
    await refresh();
    setVisible(false);
  };

  const show = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      <Button onClick={show} danger>
        {t('DELETE')}
      </Button>
      <GenericModal
        onFooterClick={remove}
        actionTitle={t('DELETE')}
        mainTitle={`${t('DELETE_COMPANY')} "${company.name}"`}
        visible={visible}
        setVisible={setVisible}
      >
        <div style={{ textAlign: 'center' }}>{t('DELETE_COMPANY_DESCRIPTION')}</div>
        <div style={{ textAlign: 'center', color: secondColor, fontWeight: 'bold', margin: '15px' }}>
          {t('DELETE_COMPANY_INPUT')}
        </div>
        <Input
          value={name}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setName(e.target.value)}
          placeholder={company.name.toUpperCase()}
        />
      </GenericModal>
    </>
  );
}

export default DeleteCompany;
