import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ShareholderType({ index }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('SHAREHOLDER_TYPE')}
      name={['v', index, 'accountType']}
      rules={[{ required: true, message: t('SHAREHOLDER_TYPE_MESSAGE_REQUIRED') }]}
      initialValue={1}
    >
      <Select
        placeholder={t('SHAREHOLDER_TYPE_PLACEHOLDER')}
        options={[
          { label: t('SHAREHOLDER_TYPE_1'), value: 1 },
          { label: t('SHAREHOLDER_TYPE_2'), value: 2 },
          { label: t('SHAREHOLDER_TYPE_3'), value: 3 },
        ]}
      />
    </Form.Item>
  );
}

export default ShareholderType;
