import { Form, Input, InputNumber } from 'antd';
import React from 'react';

function ShareholderCommon({ account, shareholder, symbol = '+' }) {
  const index = account.accountNumber;

  return (
    <>
      <Form.Item name={['v', index, 'shareholderId']} initialValue={shareholder.id} hidden>
        <Input />
      </Form.Item>

      <Form.Item name={['v', index, 'accountNumber']} initialValue={account.accountNumber} hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item name={['v', index, 'symbol']} initialValue={symbol} hidden>
        <Input />
      </Form.Item>
    </>
  );
}

export default ShareholderCommon;
