/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { EyeOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitlePrimary from '../../../components/TitlePrimary';
import { useSecondColor, useThirdColor } from '../../../redux/settings/selectors';

const styles = {
  box: {
    container: (thirdColor, last) => ({
      borderRadius: 5,
      border: `1px solid ${thirdColor}`,
      marginBottom: last ? 0 : 16,
    }),
    title: (secondColor) => ({
      backgroundColor: secondColor,
      color: 'white',
      textAlign: 'center',
      fontSize: 18,
      padding: '10px 0',
      borderRadius: '5px 5px 0 0',
      margin: '0 -1px',
    }),
    list: { padding: '20px 10px 0 10px', display: 'flex', justifyContent: 'center' },
    li: { marginBottom: 10 },
  },
};

function RightsDescription({ children = null }) {
  const { t } = useTranslation();

  const secondColor = useSecondColor();
  const thirdColor = useThirdColor();

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <>
      <span onClick={show}>
        {children || (
          <Button type="text" icon={<EyeOutlined />}>
            {t('SHOW_RIGHTS_DESCRIPTION')}
          </Button>
        )}
      </span>
      <Drawer
        title={<TitlePrimary title={t('RIGHTS_TYPE')} />}
        placement="right"
        width={500}
        onClose={hide}
        visible={visible}
        closable
      >
        <div style={styles.box.container(thirdColor)}>
          <div style={styles.box.title(secondColor)}>{t('RIGHTS_TYPE_1')}</div>
          <div style={styles.box.list}>
            <ul>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_1_1')}</li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_1_2')}</li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_1_3')}</li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_1_4')}</li>
              <li>{t('RIGHTS_TYPE_1_5')}</li>
            </ul>
          </div>
        </div>

        <div style={styles.box.container(thirdColor)}>
          <div style={styles.box.title(secondColor)}>{t('RIGHTS_TYPE_2')}</div>
          <div style={styles.box.list}>
            <ul>
              <li style={styles.box.li}>
                <div>{t('RIGHTS_TYPE_2_1')}</div>
                <ul>
                  <li>{t('RIGHTS_TYPE_2_1_1')}</li>
                  <li>{t('RIGHTS_TYPE_2_1_2')}</li>
                  <li>{t('RIGHTS_TYPE_2_1_3')}</li>
                </ul>
              </li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_2_2')}</li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_2_3')}</li>
              <li style={styles.box.li}>{t('RIGHTS_TYPE_2_4')}</li>
              <li>{t('RIGHTS_TYPE_2_5')}</li>
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default RightsDescription;
