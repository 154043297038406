import React, { useEffect, useState } from 'react';
import { TourProvider } from '@reactour/tour';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';
import Loading from '../../components/Loading';
import interpreter from '../../components/interpreter';
import { getToken, setToken, decodeToken, clearToken } from '../../services/token';
import { getCompanies } from '../../api/company';
import { signIn } from '../../redux/user/actions';
import { setCompany } from '../../redux/company/actions';
import userApi from '../../api/users';
import useTutorialUser from '../../hooks/useTutorialUser';

const userToken = 'userToken';

export default ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const tutorialUser = useTutorialUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tutorialUser) setLoading(false);
    else setLoading(true);
  }, [tutorialUser]);

  useEffect(() => {
    (async () => {
      try {
        if (getToken() && Number(decodeToken().id) === Number(tutorialUser?.id)) clearToken();
      } catch (e) {
        clearToken();
      }
    })();
  });

  if (loading) return <Loading />;

  const steps = [
    {
      selector: '.ant-layout', // Bienvenue
      content: interpreter(t('REACT_TOUR_1')),
      position: 'center',
    },
    {
      selector: '.react-tour-2', // Creer societe
      content: interpreter(t('REACT_TOUR_2')),
    },
    {
      selector: '.dg-new-operation', // Enregistrer mouvements de capitaux
      content: interpreter(t('REACT_TOUR_3')),
    },
    {
      selector: '.ant-layout', // consultez vos registres
      content: interpreter(t('REACT_TOUR_4')),
      action: () => history.push('/operations'),
    },
    {
      selector: '.react-tour-5', // bouton export
      content: interpreter(t('REACT_TOUR_5')),
      action: () => {},
    },
    {
      selector: '.ethereum',
      content: interpreter(t('REACT_TOUR_6')),
      action: () => {},
    },
    {
      selector: '.ant-layout', // table de capi
      content: interpreter(t('REACT_TOUR_7')),
      action: () => history.push('/company'),
    },
    {
      selector: '.react-tour-8', // export
      content: interpreter(t('REACT_TOUR_8')),
      action: () => {},
    },
    {
      selector: '.react-tour-9', // documentation
      content: interpreter(t('REACT_TOUR_9')),
      action: () => history.push('/documentation/legal-acts'),
    },
    {
      selector: '.ant-layout', // gestion des droits
      content: interpreter(t('REACT_TOUR_10')),
      action: () => history.push('/rights/users'),
    },
    {
      selector: '.ant-layout', // message
      content: interpreter(t('REACT_TOUR_11')),
      action: () => history.push('/message'),
    },
    {
      selector: '.ant-layout', // end
      content: interpreter(t('REACT_TOUR_12')),
      action: () => {
        setToken(localStorage.getItem(userToken));
        history.push('/companies');
      },
    },
    {
      selector: '.react-tour-2', // creer societe
      content: interpreter(t('REACT_TOUR_13')),
      action: () => {},
    },
    {
      selector: '.ant-layout', // redirect
      content: interpreter(t('REACT_TOUR_14')),
      action: async () => {
        await userApi.setPayment(1);
        window.location = '/';
      },
    },
  ];

  const toCompany = (x) => {
    dispatch(setCompany(x));
    localStorage.setItem('company', x.id);
  };

  return (
    <TourProvider
      steps={steps}
      disableInteraction
      onClickHighlighted={(e) => e.stopPropagation && e.stopPropagation()}
      onClickMask={(e) => e.stopPropagation && e.stopPropagation()}
      showCloseButton={false}
      showBadge={false}
      disableDotsNavigation
      disableKeyboardNavigation={['esc', 'left']}
      nextButton={(e) => (
        <Button
          type="primary"
          size="large"
          icon={<RightCircleFilled />}
          onFocus={(ev) => ev.currentTarget.blur()}
          onClick={() => e.setCurrentStep(e.currentStep + 1)}
        />
      )}
      prevButton={() => null}
      afterOpen={async () => {
        localStorage.setItem(userToken, getToken());
        if (tutorialUser?.email && tutorialUser?.password) await dispatch(signIn(tutorialUser));
        await getCompanies(decodeToken().id).then((x) => toCompany(x[0] || {}));
        history.push('/companies');
      }}
    >
      {children}
    </TourProvider>
  );
};
