import { EnvironmentOutlined, SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Col, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import companyApi from '../../../api/company';
import StatsPie from '../../../components/stats/Pie';
import { useCompany } from '../../../redux/company/selectors';
import { fetchSelectedCompany } from '../../../redux/company/actions';
import CompanyCapitalTable from './CapitalTable';
import CompanyDetailsStats from './Stats';
import UpsertCompany from '../../Companies/List/UpsertCompany';

const { Title, Text } = Typography;

function CompanyCard({ icon, label, value, last = false }) {
  return (
    <div style={{ display: 'flex', marginBottom: last ? 0 : 8 }}>
      <div style={{ width: 190 }}>
        <Text type="secondary">
          <Space>
            {icon}
            {label}
          </Space>
        </Text>
      </div>
      <Text strong>{value}</Text>
    </div>
  );
}

function CompanyDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const company = useCompany();
  if (!company) history.push('/');

  const [stats, setStats] = useState();

  useEffect(() => {
    companyApi
      .getStatsPie(company.id)
      .then((data) => setStats(data.circularDiagram))
      .catch(() => setStats(null));
  }, [company.id]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} xl={9}>
            <Card
              title={t('COMPANY_CARD_TITLE')}
              extra={<UpsertCompany mode="update" refresh={() => dispatch(fetchSelectedCompany(company.id))} />}
            >
              <Title level={5}>{company.name}</Title>

              <CompanyCard
                label={t('COMPANY_ADDRESS')}
                value={[company.address, company.postalcode, company.city].filter((x) => x?.length).join(', ')}
                icon={<EnvironmentOutlined />}
              />
              <CompanyCard label={t('COMPANY_SIREN')} value={company.siren} icon={<SafetyCertificateOutlined />} />
              <CompanyCard
                label={t('COMPANY_USER_NAME')}
                value={company.othername || `${company.firstname} ${company.lastname}`}
                icon={<UserOutlined />}
              />
            </Card>
          </Col>

          <CompanyDetailsStats />
        </Row>
      </Col>

      <Col span={24}>
        <CompanyCapitalTable />
      </Col>

      {stats && (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} lg={12}>
              <StatsPie title={t('COMPANY_PIE_NON_FULLY_DILUTED')} data={stats.nonFullyDiluted} />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <StatsPie title={t('COMPANY_PIE_FULLY_DILUTED')} data={stats.fullyDiluted} />
            </Col>
            {/*
            <Col xs={24} sm={24} lg={24}>
              <StatsPie title={t('COMPANY_PIE_ACTIVES_DIVISION')} data={stats.assetsAllocation} />
            </Col>
            */}
          </Row>
        </Col>
      )}
    </Row>
  );
}

export default CompanyDetails;
