import { Space } from 'antd';
import React from 'react';

function StatsBlockLine({ backgroundColor, icon: Icon, label, value }) {
  return (
    <div
      style={{
        color: 'white',
        padding: '10px 15px',
        backgroundColor,
        borderRadius: 4,
        flex: 1,
        margin: '0 8px',
        marginBottom: 8,
      }}
    >
      <Space size="large">
        <div style={{ fontSize: 25, height: 50, width: 50, position: 'relative' }}>
          <div style={{ backgroundColor: 'white', width: 50, height: 50, opacity: 0.2, borderRadius: 3 }} />
          <Icon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </div>
        <strong style={{ fontSize: 40 }}>{value}</strong>
        <div>{label}</div>
      </Space>
    </div>
  );
}

export default StatsBlockLine;
