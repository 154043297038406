import { Card, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import permissionApi from '../../../../api/permission';
import { useCompany } from '../../../../redux/company/selectors';
import SendInvitation from './SendInvitation';

function RightsHolders() {
  const { t } = useTranslation();
  const company = useCompany();

  const [users, setUsers] = useState([]);

  const fetchUsers = useCallback(() => {
    permissionApi
      .getPermissionExternal(company.id)
      .then((x) => x.filter((o) => o.accountactive === true))
      .then(setUsers)
      .catch(() => setUsers([]));
  }, [company]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Card title={t('AUDITORS_EXTERNAL_LIST')} extra={<SendInvitation refresh={fetchUsers} />}>
      <Table
        dataSource={users}
        columns={[
          { title: t('FIRSTNAME_AND_NAME'), render: (user) => `${user.firstname || ''} ${user.lastname || ''}` },
          { title: t('COMPANY_AND_JOB'), render: (user) => `${user.companyname || ''} - ${user.profession || ''}` },
          { title: t('USER_EMAIL'), dataIndex: 'email' },
          { title: t('ACTIONS'), render: () => <div /> },
        ]}
      />
    </Card>
  );
}

export default RightsHolders;
