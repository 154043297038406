import request from '../services/request';

export const getPermissionUser = (companyId) =>
  request('/permission/user')
    .then((x) => x.filter((o) => (companyId ? Number(o.companyid) === Number(companyId) : o)))
    .then((x) => (companyId ? x[0] : x));
const getPermissionHolders = (companyId) => request(`/permission/company/${companyId}/users/1|2?display=basic`);
const getPermissionExternal = (companyId) => request(`/permission/company/${companyId}/users/3?display=basic`);
const getPermissionShareholders = (companyId) => request(`/permission/company/${companyId}/users/4?display=complex`);
const sendInvitationShareholder = (companyId, type, shareholder, form) =>
  request(`/permission/company/${companyId}/invitation/${type}${form ? `?form=${form}` : ''}`, 'POST', shareholder);
const getStats = (companyId) => request(`/permission/company/${companyId}/stats`);
const deleteUser = (companyId, userId) => request(`/permission/company/${companyId}/user/${userId}`, 'DELETE');

const permissionApi = {
  getPermissionHolders,
  getPermissionExternal,
  getPermissionShareholders,
  sendInvitationShareholder,
  deleteUser,
  getStats,
};

export default permissionApi;
