import { Form, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../Form/context';

function OperationsShares() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const operation = useOperationChild();

  return (
    <Tooltip title={t(`OPERATIONS_${operation.id}_SHARES_TOOLTIP`)}>
      <Form.Item
        label={t(`OPERATIONS_${operation.id}_SHARES`)}
        name="shares"
        rules={[{ required: true, message: t('OPERATIONS_SHARES_REQUIRED') }]}
      >
        <InputNumber min={1} />
      </Form.Item>
    </Tooltip>
  );
}

export default OperationsShares;
