import { Alert, Button, Col, Divider, Form, Input, Row, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Countries from '../../../../../../../components/Countries';
import { closeModalCreateShareholder, createShareholder } from '../../../../../../../redux/operation/actions';
import ShareholderQuality from '../../Quality';

function LegalForm2() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    setError(null);

    try {
      await dispatch(createShareholder(values, 2));
      form.resetFields();
    } catch (e) {
      setError(t(e.message));
      if (e.message === 'MISMATCH_SIREN_NAME')
        notification.error({
          message: t('MISMATCH_SIREN_NAME'),
          description: t('MISMATCH_SIREN_NAME_TEXT'),
          duration: 20,
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item
        label={t('COMPANY_NAME')}
        name="name"
        rules={[{ required: true, message: t('COMPANY_NAME_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('COMPANY_NAME_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={t('COMPANY_SIREN')}
        name="siren"
        rules={[{ required: true, message: t('COMPANY_SIREN_MESSAGE_REQUIRED') }]}
      >
        <Input placeholder={t('COMPANY_SIREN_PLACEHOLDER')} />
      </Form.Item>

      <ShareholderQuality form={form} />

      <Form.Item
        label={t('COMPANY_EMAIL')}
        name="email"
        rules={[{ type: 'email', message: t('COMMON_EMAIL_BAD_FORMAT') }]}
      >
        <Input placeholder={t('COMPANY_EMAIL_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item label={t('COMPANY_ADDRESS')} name="address">
        <Input placeholder={t('COMPANY_ADDRESS_PLACEHOLDER')} />
      </Form.Item>

      <Row>
        <Col sm={{ span: 8 }}>
          <Form.Item label={t('COMPANY_ZIP')} name="postalCode">
            <Input placeholder={t('COMPANY_ZIP_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col sm={{ span: 14, offset: 2 }}>
          <Form.Item label={t('COMPANY_CITY')} name="city">
            <Input placeholder={t('COMPANY_CITY_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>

      <Countries />

      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon />
        </Form.Item>
      )}

      <Divider />

      <div className="dg-flex dg-justify-between">
        <Button onClick={() => dispatch(closeModalCreateShareholder())}>{t('COMMON_CANCEL')}</Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('SHAREHOLDER_CREATE')}
        </Button>
      </div>
    </Form>
  );
}

export default LegalForm2;
