const searchForItem = (search, options) => {
  const o = Object.entries(options);
  if (!options) return null;
  for (let i = 0; i < o.length; i += 1)
    if (o[i][0].includes(search)) {
      return o[i][1];
    }
  return null;
};

const computeRatio = (options) => {
  try {
    const o = JSON.parse(options);
    return o.SHARES > 0 ? searchForItem('MAX_SHARES', o) / o.SHARES || 1 : 1;
  } catch (e) {
    return 1;
  }
};

export default computeRatio;
