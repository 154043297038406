import React from 'react';
import useShareholders from '../../../../hooks/useShareholders';
import OperationsAccounts from '../components/v2/Accounts';
import OperationsComment from '../components/v2/Comment';
import OperationsDate from '../components/Date';
import OperationsShares from '../components/v2/Shares';

// Main levé saisie
function OperationsCreate4x2() {
  const holders = useShareholders('>', 3);

  return (
    <>
      <OperationsAccounts userType="holder" shareholders={holders} />

      <OperationsDate />
      <OperationsShares />
      <OperationsComment />
    </>
  );
}

export default OperationsCreate4x2;
