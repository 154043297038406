import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addShareholderSelected } from '../../../../../redux/operation/actions';
import FormContext from '../Form/context';

const { Option } = Select;

function ShareholdersSelect({ label, tooltip, shareholders, addShareholder }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  return (
    <Tooltip title={t(tooltip)}>
      <Form.Item label={t(label)}>
        <Select
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSelect={(shareholderId) => {
            if (addShareholder) addShareholder(shareholders.find((x) => x.id === shareholderId));
            dispatch(addShareholderSelected(Number(shareholderId)));
          }}
          value={null}
          showSearch
        >
          {shareholders.map((shareholder) => (
            <Option key={shareholder.id} value={shareholder.id}>
              {shareholder.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  );
}

export default ShareholdersSelect;
