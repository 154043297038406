import { Form, Input } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOperationChild } from '../../../../../redux/operation/selectors';
import FormContext from '../../components/Form/context';

function Name() {
  const { t } = useTranslation();
  const { Tooltip } = useContext(FormContext);

  const { id, shareType } = useOperationChild();

  return (
    <Tooltip title={t(`OPERATION_${id}_${shareType}_NAME_TOOLTIP`)}>
      <Form.Item
        label={t(`OPERATION_${id}_${shareType}_NAME`)}
        name={['options', `OPERATION_NAME`]}
        rules={[{ required: true, message: t(`OPERATION_NAME_REQUIRED`) }]}
      >
        <Input />
      </Form.Item>
    </Tooltip>
  );
}

export default Name;
